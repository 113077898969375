import React from 'react';
import './styles.css'

const ModalLucky = ({ openModal, children = "" }) => {
    return (
        <div className={!openModal ? `${'modal-container lycky'}` : "modal-container lycky enable"}>
            {children}
        </div>
    )
}

export default ModalLucky;