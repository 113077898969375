import clsx from 'clsx'
import React from 'react';
import './styles.css'

const Button = ({ title,
    href,
    onClick,
    buttonDetail,
    target,
    btnBuidTour,
    BTsearchbk,
    btnOverview,
    btnGetcode,
    btnPrimaryBaydidau,
    classNameCustom = '',
    btnPay,
    btnComfirm,
    btnPayment }) => {
    const classes = clsx(`btn ${classNameCustom}`, {
        'buttonDetail': buttonDetail,
        'btnBuidTour': btnBuidTour,
        'BTsearchbk': BTsearchbk,
        'btn-overview': btnOverview,
        'btn-pay': btnPay,
        'btn-getcode': btnGetcode,
        'btn-primary-baydidau': btnPrimaryBaydidau,
        'btn-confirm': btnComfirm,
        'btn-payment': btnPayment
    })
    let Component = 'button'
    const props = {}
    if (href) {
        Component = 'a'
        props.href = href
        props.target = target
    }
    if (onClick) {
        props.onClick = onClick
    }
    return (
        <Component className={classes} {...props}>
            {title}
        </Component>
    )
}

export default Button
