import React, { } from 'react';
import './styles.css';
import steendy from '../../../wwwroot/images/steendy.jpg';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const InfoSecurityPolicy = () => {
    return (
        <div className="container" style={{ marginTop: "20px" }}>
            <div className="row">
                <div className="col-lg-4 mb-3">
                    <img src={steendy} style={{ width: "90%" }} alt="steendy" />
                </div>
                <div className="col-lg-8">
                    <div className="row">
                        <div className="panel panel-info">
                            <div className="panel-heading">
                                Chính sách bảo mật baydidau.vn
                            </div>
                            <div className="panel-body">
                                <p className="policy-security"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>I. Chính sách bảo mật thông tin khách hàng</strong></span></span></span></p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Cám ơn quý khách đã truy cập vào website Baydidau.vn được phát triển bởi Công ty TNHH GardenSoft. Chúng tôi tôn trọng và cam kết sẽ bảo mật những thông tin mang tính riêng tư của quý khách. Xin vui lòng đọc bản Chính sách bảo mật dưới đây để hiểu hơn những cam kết mà Baydidau.vn  thực hiện nhằm tôn trọng và bảo vệ quyền lợi của người truy cập.</span></span>
                                </p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Chính sách bảo mật sẽ giải thích cách chúng tôi tiếp nhận, sử dụng và (trong trường hợp nào đó) tiết lộ thông tin cá nhân của bạn. Chính sách cũng sẽ giải thích các bước chúng tôi thực hiện để bảo mật thông tin cá nhân của khách hàng. Cuối cùng, Chính sách bảo mật sẽ giải thích quyền lựa chọn của quý khách về việc thu thập, sử dụng và tiết lộ thông tin cá nhân của mình.</span></span>
                                </p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Bảo vệ dữ liệu cá nhân và gây dựng được niềm tin cho quý khách là vấn đề rất quan trọng với chúng tôi. Vì vậy, chúng tôi sẽ dùng tên và các thông tin khác liên quan đến quý khách tuân thủ theo nội dung của Chính sách bảo mật. Chúng tôi chỉ thu thập những thông tin cần thiết liên quan đến giao dịch mua bán.</span></span>
                                </p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Chúng tôi sẽ giữ thông tin của khách hàng trong thời gian luật pháp quy định hoặc cho mục đích nào đó. Quý khách có thể truy cập vào website và trình duyệt mà không cần phải cung cấp chi tiết cá nhân. Lúc đó, bạn đang ẩn danh và chúng tôi không thể biết bạn là ai nếu bạn không đăng nhập vào tài khoản của mình.</span></span>
                                </p>
                                <p className="policy-security"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>1. Thu thập thông tin cá nhân</strong></span></span></span></p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Chúng tôi thu thập thông tin theo một số cách từ các mục khác nhau trên website của mình. Một số thông tin cá nhân được tập hợp lại khi bạn đăng ký. Trong khi đăng ký, chúng tôi hỏi tên và địa chỉ email của bạn. Hệ thống cũng có thể hỏi địa chỉ đường phố, thành phố, quốc gia, số điện thoại, thông tin thêm của bạn…, mặc dù chỉ những trường được đánh dấu sao * trên phần đăng ký mới là thông tin bắt buộc.</span></span>
                                </p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Ngoài thông tin đăng ký, đôi khi chúng tôi có thể hỏi bạn thông tin cá nhân bao gồm (nhưng không hạn chế) khi bạn đặt mua vé máy bay hoặc khai thác các tính năng khác của website Baydidau.vn. Nếu bạn liên lạc với chúng tôi, chúng tôi có thể giữ một bản ghi nhớ về sự liên lạc này. Mỗi trang trong phạm vi website Baydidau.vn  đều có đường dẫn tới Chính sách Bảo mật này.</span></span>
                                </p>
                                <p className="policy-security"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>- Điều lệ khác về thông tin cá nhân</strong></span></span></span></p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Chúng tôi có thể dùng thông tin cá nhân của bạn để nghiên cứu thị trường. Chi tiết sẽ được ẩn và chỉ được dùng để thống kê. Quý khách có thể từ chối không tham gia bất cứ lúc nào. Bất kỳ câu trả lời cho khảo sát hoặc thăm dò dư luận mà chúng tôi cần bạn làm sẽ không được chuyển cho bên thứ ba. Việc cần thiết duy nhất là tiết lộ email của bạn nếu bạn muốn tham gia. Câu trả lời sẽ được lưu tách riêng với email của bạn.</span></span>
                                </p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Bạn sẽ nhận được thông tin về chúng tôi, về website, các website khác (nếu có), sản phẩm, bản tin…. Nếu quý khách không muốn nhận những thông tin này, vui lòng nhấn vào link từ chối trong bất kỳ email chúng tôi gửi cho bạn. Trong vòng 7 ngày nhận chỉ dẫn của bạn, chúng tôi sẽ ngừng gửi thông tin. Nếu thấy không rõ, chúng tôi sẽ liên lạc với bạn.</span></span>
                                </p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Chúng tôi sẽ tiếp tục giấu tên dữ liệu người dùng trên website và sử dụng cho nhiều mục đích khác nhau, bao gồm việc xác định vị trí của người dùng và cách sử dụng các khía cạnh nhất định của website hoặc đường link chứa trong email tới người dùng và cung cấp dữ liệu ẩn danh đó cho bên thứ 3 là nhà xuất bản. Tuy nhiên, dữ liệu này không có khả năng xác định cá nhân.</span></span>
                                </p>
                                <p className="policy-security"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>2. Cuộc thi – Chương trình</strong></span></span></span></p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Trong bất kì cuộc thi hoặc chương trình nào, chúng tôi sẽ sử dụng dữ liệu để thông báo người chiến thắng và quảng cáo chào hàng. Bạn có thể đăng ký tham gia để biết thêm chi tiết về cuộc thi hoặc chương trình của Baydidau.vn.</span></span>
                                </p>
                                <p className="policy-security"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>3. Đối tác thứ ba và Liên kết </strong></span></span></span></p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Chúng tôi có thể chuyển thông tin của quý khách cho các công ty khác trong nhóm. Chúng tôi có thể chuyển thông tin của quý khách cho các đại lý cấp 2 trong khuôn khổ quy định của Chính sách bảo mật. Ví dụ: chúng tôi sẽ nhờ bên thứ ba giao hàng, nhận tiền thanh toán, phân tích dữ liệu, tiếp thị và hỗ trợ dịch vụ khách hàng. Chúng tôi có thể trao đổi thông tin với bên thứ ba với mục đích chống gian lận và giảm rủi ro tín dụng.</span></span>
                                </p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Chúng tôi có thể chuyển cơ sở dữ liệu gồm thông tin cá nhân của bạn nếu chúng tôi chuyển nhượng cả công ty hoặc chỉ một phần. Trong khuôn khổ Chính sách bảo mật, chúng tôi không bán hay tiết lộ dữ liệu cá nhân của bạn cho bên thứ ba mà không được đồng ý trước trừ khi điều này là cần thiết cho các điều khoản trong Chính sách bảo mật hoặc chúng tôi được yêu cầu phải làm như vậy theo quy định của Pháp luật. Website có thể bao gồm quảng cáo của bên thứ ba và các liên kết đến các trang web khác hoặc khung của các trang web khác. Xin lưu ý rằng chúng tôi không có nhiệm vụ bảo mật thông tin hay nội dung của bên thứ ba hay các website khác, hay bất kỳ bên thứ ba nào mà chúng tôi chuyển giao dữ liệu cho phù hợp với Chính sách bảo mật.</span></span>
                                </p>
                                <p className="policy-security"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>4. Sử dụng Cookie </strong></span></span></span></p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Cookie là tập tin văn bản  nhỏ có thể nhận dạng tên truy cập duy nhất từ máy tính của bạn đến máy chủ của chúng tôi khi bạn truy cập vào các trang nhất định trên website và sẽ được lưu bởi trình duyệt internet lên ổ cứng máy tính của bạn. Cookie được dùng để nhận dạng địa chỉ IP, lưu lại thời gian. Chúng tôi dùng cookie để tiện cho quý khách vào web (ví dụ: ghi nhớ tên truy cập khi bạn muốn vào thay đổi giao dịch mà không cần phải nhập lại địa chỉ email của mình) và không đòi hỏi bất kỳ thông tin nào về bạn (ví dụ: mục tiêu quảng cáo). Trình duyệt của bạn có thể được thiết lập không sử dụng cookie nhưng điều này sẽ hạn chế quyền sử dụng của bạn trên web. Xin vui lòng chấp nhận cam kết của chúng tôi là cookie không bao gồm bất cứ chi tiết cá nhân riêng tư nào và an toàn với virus.</span></span>
                                </p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Trình duyệt này sử dụng Google Analytics, một dịch vụ phân tích web được cung cấp bởi Google, Inc. (“Google”). Google Analytics dùng cookie, là những tập tin văn bản đặt trong máy tính để giúp website phân tích người dùng vào web như thế nào. Thông tin được tổng hợp từ cookie sẽ được truyền tới và lưu bởi Google trên các máy chủ tại Hoa Kỳ. Google sẽ dùng thông tin này để đánh giá cách dùng web của bạn, lập báo cáo về các hoạt động trên web cho các nhà khai thác web và cung cấp các dịch vụ khác liên quan đến các hoạt động internet và cách dùng internet. Google cũng có thể chuyển giao thông tin này cho bên thứ ba theo yêu cầu của pháp luật hoặc các bên thứ ba xử lý thông tin trên danh nghĩa của Google. Google sẽ không kết hợp địa chỉ IP của bạn với bất kỳ dữ liệu nào khác mà Google đang giữ. Quý khách có thể từ chối dùng cookie bằng cách chọn các thiết lập thích hợp trên trình duyệt của mình, tuy nhiên lưu ý rằng điều này sẽ ngăn bạn sử dụng triệt để chức năng của website. Bằng cách sử dụng trang web này, bạn đã đồng ý cho Google xử lý dữ liệu về bạn theo cách thức và các mục đích nêu trên.</span></span>
                                </p>
                                <p className="policy-security"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>5. Bảo mật </strong></span></span></span></p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Chúng tôi có biện pháp thích hợp về kỹ thuật và an ninh để ngăn chặn truy cập trái phép hoặc trái pháp luật hoặc mất mát hoặc tiêu hủy hoặc thiệt hại cho thông tin của bạn. Khi thu thập dữ liệu trên web, chúng tôi thu thập chi tiết cá nhân của bạn trên máy chủ an toàn. Chúng tôi dùng tường lửa cho máy chủ. Khi thu thập chi tiết các thẻ thanh toán điện tử, chúng tôi dùng mã hóa bằng Secure Socket Layer (SSL). Khi chúng tôi không thể bảo đảm an ninh 100%, SSL sẽ gây khó khăn cho hacker muốn giải mã thông tin của quý khách. Bạn không nên gửi đầy đủ chi tiết của thẻ tín dụng hay thẻ ghi nợ khi chưa được mã hóa cho chúng tôi. Chúng tôi duy trì các biện pháp bảo vệ vật lý và điện tử trong mối liên kết với thu thập, lưu trữ và tiết lộ thông tin của bạn. Các thủ tục an toàn của chúng tôi nghĩa là chúng tôi có thể đôi khi yêu cầu giấy tờ chứng minh trước khi tiết lộ thông tin cá nhân cho bạn.</span></span>
                                </p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Chúng tôi khuyên quý khách rằng quý khách không nên đưa thông tin chi tiết về việc thanh toán với bất kỳ ai bằng e-mail, chúng tôi không chịu trách nhiệm về những mất mát quý khách có thể gánh chịu trong việc trao đổi thông tin của quý khách qua internet hoặc email</span></span>
                                </p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Quý khách tuyệt đối không sử dụng bất kỳ chương trình, công cụ hay hình thức nào khác để can thiệp vào hệ thống hay làm thay đổi cấu trúc dữ liệu. Nghiêm cấm việc phát tán, truyền bá hay cổ vũ cho bất kỳ hoạt động nào nhằm can thiệp, phá hoại hay xâm nhập vào dữ liệu của hệ thống website. Mọi vi phạm sẽ bị tước bỏ mọi quyền lợi cũng như sẽ bị truy tố trước pháp luật nếu cần thiết.</span></span>
                                </p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Mọi thông tin giao dịch sẽ được bảo mật nhưng trong trường hợp cơ quan pháp luật yêu cầu, chúng tôi sẽ buộc phải cung cấp những thông tin này cho các cơ quan pháp luật.</span></span>
                                </p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Các điều kiện, điều khoản và nội dung của trang web này được điều chỉnh bởi luật pháp Việt Nam và tòa án Việt Nam có thẩm quyền xem xét.</span></span>
                                </p>
                                <p className="policy-security"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>6. Quyền lợi khách hàng </strong></span></span></span></p>
                                <p className="policy-security">
                                    <span style={{ fontSize: "16px" }}><span>Quý khách có quyền yêu cầu truy cập vào dữ liệu cá nhân của mình, có quyền yêu cầu chúng tôi sửa lại những sai sót trong dữ liệu của bạn mà không mất phí. Bất cứ lúc nào bạn cũng có quyền yêu cầu chúng tôi ngưng sử dụng dữ liệu cá nhân của bạn cho mục đích tiếp thị.</span></span>
                                </p>
                                <div className="text-center">
                                    <NavLink tag={Link} className="footer-csdkbm btn btn-danger" to="/dang-ky">
                                        Đăng ký ngay
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoSecurityPolicy;
