import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion'
import Layout from './views/Layout';
import Home from './views/features/Home';
import FetchData from './views/components/FetchData';
import WebsiteTerms from './views/features/WebsiteTerms';
import CollaboratorPolicy from './views/features/CollaboratorPolicy';
import InfoSecurityPolicy from './views/features/InfoSecurityPolicy';
import TourDetail from './views/features/TourDetail';
import Register from './views/features/Register';
import Payment from './views/features/Payment';
import BuildTour from './views/features/BuildTour';
import Schedule from './views/features/Schedule';
import Service from './views/features/Service';
import PaymentStatus from './views/features/PaymentStatus';
import Confirm from './views/features/Confirm';
import NotFound from './views/components/NotFound';
import Cookies from 'js-cookie';


import 'normalize.css';
import './custom.css';
import { ScrollProvider } from './views/components/Hook/ScrollContext';

const App = () => {
    useEffect(() => {
        Cookies.set('yourCookieName', 'yourCookieValue', { expires: 365 });
    }, [])
    return (
        <AnimatePresence mode='wait'>
            <ScrollProvider>
                <Layout>
                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route path='/dieu-khoan' component={WebsiteTerms} />
                        <Route path='/chinh-sach-cong-tac-vien' component={CollaboratorPolicy} />
                        <Route path='/bao-mat' component={InfoSecurityPolicy} />
                        <Route path='/tour' component={TourDetail} />
                        <Route path='/dang-ky' component={Register} />
                        <Route path='/thanh-toan-tour' component={Payment} />
                        <Route path='/build-tour' component={BuildTour} />
                        <Route path='/lap-lich-trinh' component={Schedule} />
                        <Route path='/dich-vu' component={Service} />
                        <Route path='/thanh-toan-online' component={PaymentStatus} />
                        <Route path='/xac-nhan' component={Confirm} />

                        <Route path='/fetch-data' component={FetchData} />

                        <Route path="*" component={NotFound} />
                    </Switch>
                </Layout>
            </ScrollProvider>
        </AnimatePresence>
    );
}
export default App;
