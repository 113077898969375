import CryptoJS from 'crypto-js';

export const getUrlEncryptStr = (input) => {
    const encryptedStr = sha256(input);
    return encryptedStr;
}

const sha256 = (input) => {
    const wordArray = CryptoJS.enc.Utf8.parse(input);
    const hash = CryptoJS.SHA256(wordArray);
    return hash.toString(CryptoJS.enc.Hex);
}

export const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    value = encodeURIComponent(value);
    document.cookie = name + "=" + value + expires + "; path=/";
}

export const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return decodeURIComponent(cookie.substring(name.length + 1));
        }
    }
    return null;
}

export const gs_currencyFormatfixed0 = (num) => {
    var number = num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return number
}

export const gs_parseFloat = (a) => {
    var data = 0;
    a = a.toString().replace(/,/g, '');
    data = parseFloat(a === '' ? 0 : a)
    return data;
}

/*output : MM/dd/yyyy */
export const gs_datetime_MMddyyyy = (date, format) => {
    var MyDate = gs_datetime_original(date, format);
    return MyDate.split('|')[1] + '/' + MyDate.split('|')[0] + '/' + MyDate.split('|')[2];
}

/*input : output : yyyy/MM/dd HH:mm:ss */
export const gs_datetime_yyyyMMddhhmmss_ = (date, format) => {
    var MyDate = gs_datetime_original(date, format);
    return MyDate.split("|")[2] + "-" + MyDate.split("|")[1] + "-" + MyDate.split("|")[0] + " " + MyDate.split("|")[3] + ":" + MyDate.split("|")[4] + ":" + MyDate.split("|")[5];
}

/*input : output : yyyy-MM-dd HH:mm */
export const gs_datetime_yyyyMMddhhmm_ = (date, format) => {
    var MyDate = gs_datetime_original(date, format);
    return MyDate.split('|')[2] + '-' + MyDate.split('|')[1] + '-' + MyDate.split('|')[0] + ' ' + MyDate.split('|')[3] + ':' + MyDate.split('|')[4];
}

const gs_datetime_original = (date, format) => {
    var y = gs_year(date, format);
    var m = gs_month(date, format);
    var d = gs_day(date, format);
    var h = gs_hour(date, format);
    var mi = gs_minutes(date, format);
    var se = gs_second(date, format);
    var Oridate = new Date(y, m, d, h, mi, se);
    var dd = Oridate.getDate();
    var mm = Oridate.getMonth() + 1;
    var hh = Oridate.getHours();
    var min = Oridate.getMinutes();
    var sec = Oridate.getSeconds();
    var yyyy = Oridate.getFullYear();
    dd = dd < 10 ? "0" + dd : dd;
    mm = mm < 10 ? "0" + mm : mm;
    hh = hh < 10 ? "0" + hh : hh
    min = min < 10 ? "0" + min : min;
    sec = sec < 10 ? "0" + sec : sec;
    return dd + "|" + mm + "|" + yyyy + "|" + hh + "|" + min + "|" + sec;
}

const gs_year = (date, format) => {
    var yyyy = ""
    if (format.toLowerCase() === "dd/MM/yyyy".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy".toLowerCase()) {
        yyyy = date.substring(6, 10);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy".toLowerCase()) {
        yyyy = date.substring(6, 10);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd".toLowerCase()) {
        yyyy = date.substring(0, 4);
    }
    else if (format.toLowerCase() === "dd/MM/yyyy HH:mm".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy HH:mm".toLowerCase()) {
        yyyy = date.substring(6, 10);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy HH:mm".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy HH:mm".toLowerCase()) {
        yyyy = date.substring(6, 10);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd HH:mm".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd HH:mm".toLowerCase()) {
        yyyy = date.substring(0, 4);
    }
    else if (format.toLowerCase() === "dd/MM/yyyy HH:mm:ss".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy HH:mm:ss".toLowerCase()) {
        yyyy = date.substring(6, 10);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy HH:mm:ss".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy HH:mm:ss".toLowerCase()) {
        yyyy = date.substring(6, 10);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd HH:mm:ss".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd HH:mm:ss".toLowerCase()) {
        yyyy = date.substring(0, 4);
    } else {
        var Oridate = new Date(date)
        yyyy = Oridate.getFullYear();
    }
    return yyyy;
}

const gs_month = (date, format) => {
    var month = ""
    if (format.toLowerCase() === "dd/MM/yyyy".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy".toLowerCase()) {
        month = date.substring(3, 5);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy".toLowerCase()) {
        month = date.substring(0, 2);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd".toLowerCase()) {
        month = date.substring(5, 7);
    }
    else if (format.toLowerCase() === "dd/MM/yyyy HH:mm".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy HH:mm".toLowerCase()) {
        month = date.substring(3, 5);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy HH:mm".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy HH:mm".toLowerCase()) {
        month = date.substring(0, 2);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd HH:mm".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd HH:mm".toLowerCase()) {
        month = date.substring(5, 7);
    }
    else if (format.toLowerCase() === "dd/MM/yyyy HH:mm:ss".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy HH:mm:ss".toLowerCase()) {
        month = date.substring(3, 5);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy HH:mm:ss".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy HH:mm:ss".toLowerCase()) {
        month = date.substring(0, 2);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd HH:mm:ss".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd HH:mm:ss".toLowerCase()) {
        month = date.substring(5, 7);
    } else {
        var Oridate = new Date(date)
        month = Oridate.getMonth() + 1;
    }
    month = parseInt(month) - 1;
    return month;
}

const gs_day = (date, format) => {
    var day = ""
    if (format.toLowerCase() === "dd/MM/yyyy".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy".toLowerCase()) {
        day = date.substring(0, 2);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy".toLowerCase()) {
        day = date.substring(3, 5);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd".toLowerCase()) {
        day = date.substring(8, 10);
    }
    else if (format.toLowerCase() === "dd/MM/yyyy HH:mm".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy HH:mm".toLowerCase()) {
        day = date.substring(0, 2);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy HH:mm".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy HH:mm".toLowerCase()) {
        day = date.substring(3, 5);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd HH:mm".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd HH:mm".toLowerCase()) {
        day = date.substring(8, 10);
    }
    else if (format.toLowerCase() === "dd/MM/yyyy HH:mm:ss".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy HH:mm:ss".toLowerCase()) {
        day = date.substring(0, 2);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy HH:mm:ss".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy HH:mm:ss".toLowerCase()) {
        day = date.substring(3, 5);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd HH:mm:ss".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd HH:mm:ss".toLowerCase()) {
        day = date.substring(8, 10);
    } else {
        var Oridate = new Date(date)
        day = Oridate.getDate();
    }
    return day;
}

const gs_hour = (date, format) => {
    var hour = ""

    if (format.toLowerCase() === "dd/MM/yyyy HH:mm".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy HH:mm".toLowerCase()) {
        hour = date.substring(11, 13);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy HH:mm".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy HH:mm".toLowerCase()) {
        hour = date.substring(11, 13);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd HH:mm".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd HH:mm".toLowerCase()) {
        hour = date.substring(11, 13);
    }
    else if (format.toLowerCase() === "dd/MM/yyyy HH:mm:ss".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy HH:mm:ss".toLowerCase()) {
        hour = date.substring(11, 13);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy HH:mm:ss".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy HH:mm:ss".toLowerCase()) {
        hour = date.substring(11, 13);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd HH:mm:ss".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd HH:mm:ss".toLowerCase()) {
        hour = date.substring(11, 13);
    } else {
        var Oridate = new Date()
        hour = Oridate.getHours();
    }
    return hour;
}

const gs_minutes = (date, format) => {
    var minutes = ""

    if (format.toLowerCase() === "dd/MM/yyyy HH:mm".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy HH:mm".toLowerCase()) {
        minutes = date.substring(14, 16);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy HH:mm".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy HH:mm".toLowerCase()) {
        minutes = date.substring(14, 16);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd HH:mm".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd HH:mm".toLowerCase()) {
        minutes = date.substring(14, 16);
    }
    else if (format.toLowerCase() === "dd/MM/yyyy HH:mm:ss".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy HH:mm:ss".toLowerCase()) {
        minutes = date.substring(14, 16);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy HH:mm:ss".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy HH:mm:ss".toLowerCase()) {
        minutes = date.substring(14, 16);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd HH:mm:ss".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd HH:mm:ss".toLowerCase()) {
        minutes = date.substring(14, 16);
    } else {
        var Oridate = new Date()
        minutes = Oridate.getMinutes();
    }
    return minutes;
}

const gs_second = (date, format) => {
    var second = ""

    if (format.toLowerCase() === "dd/MM/yyyy HH:mm:ss".toLowerCase() || format.toLowerCase() === "dd-MM-yyyy HH:mm:ss".toLowerCase()) {
        second = date.substring(17, 19);
    }
    else if (format.toLowerCase() === "MM/dd/yyyy HH:mm:ss".toLowerCase() || format.toLowerCase() === "MM-dd-yyyy HH:mm:ss".toLowerCase()) {
        second = date.substring(17, 19);
    }
    else if (format.toLowerCase() === "yyyy/MM/dd HH:mm:ss".toLowerCase() || format.toLowerCase() === "yyyy-MM-dd HH:mm:ss".toLowerCase()) {
        second = date.substring(17, 19);
    } else {
        second = "00";
    }
    return second;
}
