import React, { } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './styles.css';
import logoImgFooter from '../../../wwwroot/images/TV/logo_.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row" id="footer">
                    <div className="col-lg-12" id="footer-header">
                        <span>Thông tin và chính sách</span>
                    </div>
                    <div className="col-md-4" id="footer-ttcs-address">
                        <div id="footer-ttcs-address-logoimg">
                            <img src={logoImgFooter} alt="imgLogoFooter" />
                        </div>
                        <div className="footer-ttcs-address-info">
                            Văn phòng : Số 40 Mỹ Khê 7, Phước Mỹ, Sơn Trà, Đà Nẵng
                        </div>
                        <div className="footer-ttcs-address-info">
                            Email : support@booknet.vn
                        </div>
                        <div className="footer-ttcs-address-info">
                            HotLine : 0935 699 397
                        </div>
                        <div className="footer-ttcs-address-info">
                            Địa chỉ tên miền: booknet.vn
                        </div>
                    </div>
                    <div className="col-md-4" id="ttcs-qd">
                        <div className="footer-ttcs-address-info">
                            <strong>QUY ĐỊNH </strong>
                        </div>     <br />
                        <div className="footer-ttcs-address-info">
                            <NavLink tag={Link} className="text-dark footer-csdkbm" to="/dieu-khoan">Chính sách và quy định chung</NavLink>
                        </div>
                        <div className="footer-ttcs-address-info">
                            <NavLink tag={Link} className="text-dark footer-csdkbm" to="/chinh-sach-cong-tac-vien"> Chính sách cộng tác viên</NavLink>
                        </div>
                        <div className="footer-ttcs-address-info">
                            <NavLink tag={Link} className="text-dark footer-csdkbm" to="/dieu-khoan">Quy định thanh toán</NavLink>
                        </div>
                        <div className="footer-ttcs-address-info">
                            <NavLink tag={Link} className="text-dark footer-csdkbm" to="/dieu-khoan">Quy định về xác định thông tin đặt vé</NavLink>
                        </div>
                        <div className="footer-ttcs-address-info">
                            <NavLink tag={Link} className="text-dark footer-csdkbm" to="/dieu-khoan">Chính sách về hủy đặt vé và hoàn trả tiền</NavLink>
                        </div>
                        <div className="footer-ttcs-address-info">
                            <NavLink tag={Link} className="text-dark footer-csdkbm" to="/bao-mat">Chính sách bảo mật thông tin</NavLink>
                        </div>
                    </div>
                    <div className="col-md-4" id="ttcs-dkctv">
                        <div className="footer-title__qrcode">
                            <strong>Bấm vào đây hoặc quét mã để đăng ký cộng tác viên</strong>
                        </div><br />
                        <div id="ttcs-dkctv-qr">
                            <NavLink tag={Link} className="text-dark footer-csdkbm footer-csdkbm__qr" to="/dang-ky">
                                <img src="https://chart.apis.google.com/chart?cht=qr&chs=500x500&chl=https://baydidau.vn/dang-ky" alt="QrCode" style={{ width: "200px" }} />
                            </NavLink>
                        </div>
                    </div>
                    <div className="copy-right">
                        &copy; 2023 GardenSoft Co.,LTD.All rights reserved.
                    </div>
                </div>


            </div>
        </footer>
    );
}

export default Footer;
