import React from 'react';
import './styles.css'

const Loading = () => {
    return (
        <div className="loading-container">
            <span className="loader"></span>
        </div>
    )
}

export default Loading;
