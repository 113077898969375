import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './styles.css';
import logoImg from '../../../wwwroot/images/logo-single.png';
import luckyWhellImage from '../../../wwwroot/images/4.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import ModalLucky from '../../components/ModalLucky';
import {
    getCookie,
    gs_datetime_MMddyyyy
} from "../../../wwwroot/js/GSFramescript";
import { useScrollRef } from '../../components/Hook/ScrollContext';

const NavMenu = ({ search, setSearch }) => {
    const scrollRef = useScrollRef();
    const [isActiveSearch, setIsActiveSearch] = useState(false);
    const [isActiveMobile, setIsActiveMobile] = useState(false);
    const [isOpenLucky, setIsOpenLucky] = useState(false);
    const [isOpenLuckyForm, setIsOpenLuckyForm] = useState(false);
    const [isShowLucky, setIsShowLucky] = useState(false)
    const [infoCTVCode, setInfoCTVCode] = useState("");
    const [infoCTVName, setInfoCTVName] = useState("");
    const [infoCTVLogo, setInfoCTVLogo] = useState("");
    const [infoCTVSDT, setInfoCTVSDT] = useState("");
    const [dataLuckySale, setDataLuckySale] = useState([]);
    const [displayedCode, setDisplayedCode] = useState({});
    const [sendValueName, setSendValueName] = useState("");
    const [sendValueAddress, setSendValueAddress] = useState("");
    const [sendValueTel, setSendValueTel] = useState("");
    const [sendValueEmail, setSendValueEmail] = useState("");

    const getRandomCode = () => {
        fetchDataLuckySale();
    };

    useEffect(() => {
        if (dataLuckySale.length > 0) {
            const getRandomDataImage = Math.floor(Math.random() * dataLuckySale.length);
            setDisplayedCode(dataLuckySale[getRandomDataImage]);
        }
    }, [dataLuckySale])

    useEffect(() => {
        setInfoCTVCode(getCookie("CodeCTV"));
        setInfoCTVName(getCookie("NameCTV"));
        setInfoCTVSDT(getCookie("SDTCTV"));
        setInfoCTVLogo(getCookie("LogoCTV"));
    }, [infoCTVCode, infoCTVLogo, infoCTVName, infoCTVSDT])

    useEffect(() => {
        const timerLucky = setTimeout(() => {
            setIsOpenLucky(false)
            if (isOpenLucky) {
                setIsOpenLuckyForm(true)
            }
        }, 5000)
        return () => clearTimeout(timerLucky)
    }, [isOpenLucky]);

    const fetchDataLuckySale = async () => {
        const currDate = gs_datetime_MMddyyyy(new Date(), "");

        try {
            const model = {
                StoreName: 'Usp_GetGamePromotion',
                Parametter: '@_IsActive|@_CTV|@_UseStatus|@_Date',
                Value: `1|${infoCTVCode || ""}|0|${currDate}`
            };
            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");
            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];
            if (data.length === 0 && !infoCTVCode) {
                getVoucher();
            }
            else if (data.length === 0 && infoCTVCode) {
                setIsOpenLucky(false)
                setIsShowLucky(false)
                Swal.fire({
                    title: "Mã khuyến mãi đã hết",
                    text: "",
                    icon: "warning",
                });
                setDisplayedCode({});
            }
            else {
                setDataLuckySale(data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getVoucher = async () => {
        try {
            const model = {
                StoreName: "Usp_AutoGetPromotionCodeWH",
                Parametter: "@_Name|@_Amount",
                Value: "Mã giảm giá 10k|10000",
            };
            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");
            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0][0];
            data ? setDataLuckySale([data]) : setDataLuckySale([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const sendVoucher = async () => {
        try {
            const model = {
                StoreName: "Usp_G20EmployeeInfoUpdateInsert",
                Parametter: "@_key|@_Stt|@_Name|@_Address|@_NumberId|@_Email|@_Phone|@_SellPhone|@_Description|@_IsActive",
                Value: "I||" + sendValueName + "|" + sendValueAddress + "||" + sendValueEmail + "|" + sendValueTel + "|" + sendValueTel + "||1"
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/InsertUpdate?${queryString}`);
            const dataResponse = await response.json();
            if (dataResponse.Message.includes("E404")) {
                Swal.fire({
                    title: "Đăng ký thất bại!",
                    text: dataResponse.Message.split('|')[0],
                    icon: "error"
                });
            }
            else {
                try {
                    const model = {
                        Stt: dataResponse.stingid,
                        Ctv: sendValueName,
                        PromtionCode: displayedCode.Code,
                        EmailCTV: sendValueEmail,
                    };

                    const queryString = Object.keys(model)
                        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                        .join("&");

                    const response = await fetch(`/HomeControllercs/SendEmailCTVRegister?${queryString}`);
                    await response.json();
                } catch (error) {
                    console.error('Error fetching data:', error);
                }

                try {
                    const modelowner = {
                        Name: sendValueName,
                        SellPhone: sendValueTel,
                        Email: sendValueEmail,
                        Address: sendValueAddress,
                    };

                    const queryString = Object.keys(modelowner)
                        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                        .join("&");

                    const response = await fetch(`/HomeControllercs/SendEmailOwnerRegister?${queryString}`);
                    await response.json();
                } catch (error) {
                    console.error('Error fetching data:', error);
                }

                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Đăng ký thành công!",
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(() => {
                    setIsOpenLuckyForm(false)
                }, 3000);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleClickSearchClose = (event) => {
        event.stopPropagation()
        setSearch('');
        setIsActiveSearch(false)
    };

    const handleClickSearch = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setIsActiveSearch(true);
    }

    const handleClickMobile = () => {
        setIsActiveMobile(!isActiveMobile);
    }

    const handleClickLucky = () => {
        setIsOpenLucky(true);
        setIsShowLucky(true);
        getRandomCode();
    }

    const handleClickLuckyForm = () => {
        setIsOpenLuckyForm(true);
        setIsOpenLucky(false);
    }

    return (
        <header>
            <div className="header-nav header-section">
                <div className="container">
                    <div className="row">

                        <div className="header">
                            <div className="header-top">
                                <NavLink tag={Link} className="text-dark header-logo" to={infoCTVCode ? "/?id=" + infoCTVCode : "/"} >
                                    <img src={infoCTVLogo ? "http://id.booknet.vn/" + infoCTVLogo : logoImg} alt="logo" />
                                </NavLink>
                                <div className={infoCTVCode && infoCTVCode !== "" ? "header-center" : "hide"} >
                                    <span className="name-ctv"><strong >{infoCTVName}</strong></span>
                                    <span className="span-line">-</span>
                                    <a className="phone-ctv" href={`tel:${infoCTVSDT}`}> {infoCTVSDT}</a>
                                </div>
                                <ul id="nav" className={!isActiveMobile ? '' : 'active'}>
                                    <li className="header-login">
                                        {!isShowLucky && <div className="lucky-whell" id="luckyWhell" onClick={handleClickLucky}>
                                            <img src={luckyWhellImage} alt={"img-luckywhell"} />
                                        </div>}
                                        <div className={!isActiveSearch ? "input-box" : "input-box open"} id="seach-layout" onClick={handleClickSearch}>
                                            <input id="search-list-tour" value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder="Search..." />
                                            <span className="icon">
                                                <FontAwesomeIcon className="search-icon" icon={faMagnifyingGlass} />
                                            </span>
                                            <FontAwesomeIcon className="close-icon" icon={faXmark} id="close-search" onClick={handleClickSearchClose} />
                                        </div>
                                        <a className="logo btn btn-login" href="https://id.booknet.vn/" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faRightToBracket} /> <span>Đăng nhập</span>
                                        </a>
                                    </li>
                                </ul>
                                <div className={!isActiveMobile ? 'menu' : 'menu active'} id="menu" onClick={handleClickMobile}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalLucky openModal={isOpenLucky}>
                    <div className="modal-overlay" onClick={handleClickLuckyForm}></div>
                    <div className={`${'modal-lucky'} modal-lucky__sale`}>
                        <img src={`http://id.booknet.vn/${displayedCode.ImageLink}`} alt={displayedCode.Name} />
                    </div>
                </ModalLucky>
                {isOpenLuckyForm && !isOpenLucky && <ModalLucky openModal={isOpenLuckyForm}>
                    <div className="modal-overlay" onClick={() => setIsOpenLuckyForm(true)}></div>
                    <div className={`${'modal-lucky'} modal-lucky__form`}>
                        <div className="lucky-heading">
                            <h4>Vui lòng nhập thông tin để lấy mã</h4>
                            <div className="modal-close" onClick={() => setIsOpenLuckyForm(false)}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>
                        <div className="form-horizontal__lucky">
                            <h5 className="title-sale">Chúc mừng bạn nhận được: {displayedCode.Name === "Khách lẻ" ? "Mã giảm giá 10k" : displayedCode.Name}</h5>
                            <div className="lucky-group">
                                <label htmlFor="Name" className="lucky-label">Tên</label>
                                <input type="text" className="form-control" name="Name" required="" placeholder="Họ và tên" onChange={e => setSendValueName(e.target.value)} />
                            </div>
                            <div className="lucky-group">
                                <label htmlFor="Address" className=" lucky-label">Địa chỉ</label>
                                <input type="text" className="form-control" name="Address" required="" placeholder="Địa chỉ" onChange={e => setSendValueAddress(e.target.value)} />
                            </div>
                            <div className="lucky-group">
                                <label htmlFor="Email" className=" lucky-label">Email</label>
                                <input type="email" className="form-control" name="Email" required="" placeholder="Email" onChange={e => setSendValueEmail(e.target.value)} />
                            </div>
                            <div className="lucky-group">
                                <label htmlFor="SellPhone" className=" lucky-label">Số điện thoại</label>
                                <input type="tel" className="form-control" name="SellPhone" required="" placeholder="Số điện thoại" onChange={e => setSendValueTel(e.target.value)} />
                            </div>
                            <div className="lucky-footer">
                                <button type="submit" id="Send" className="btn btn-danger btn-lucky__form" onClick={sendVoucher}>Gửi</button>
                            </div>
                        </div>
                    </div>
                </ModalLucky>}
            </div>
        </header>
    );
}

export default NavMenu;
