import React, { createContext, useContext, useRef } from 'react';

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
    const scrollRef = useRef();

    return (
        <ScrollContext.Provider value={scrollRef}>
            {children}
        </ScrollContext.Provider>
    );
};

export const useScrollRef = () => {
    return useContext(ScrollContext);
};
