import React, { } from 'react';
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const ModalFullScreen = ({ closeModal, title = "Modal Full Screen", openModal, children }) => {
    return (
        <div className={!openModal ? "modal-fullscreen__container" : "modal-fullscreen__container enable"}>
            <div className="modal-fullscreen__content">
                {/*<div className="modal-fullscreen__overlay" onClick={() => closeModal(false)}>Modal</div>*/}
                <div className="modal-fullscreen__inner">
                    <div className="modal-fullscreen__heading">
                        {/*<h4>{title}</h4>*/}
                        <div className="modal-fullscreen__close" onClick={() => closeModal(false)}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </div>
                    <div className="modal-fullscreen__body">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalFullScreen;
