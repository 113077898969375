import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane, faPlus, faXmark, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import ChangePageTitle from '../../components/Hook/ChangePageTitle'
import ModalLucky from '../../components/ModalLucky';
import {
    gs_datetime_yyyyMMddhhmmss_,
    gs_currencyFormatfixed0,
    gs_parseFloat,
    gs_datetime_yyyyMMddhhmm_,
    getCookie
} from "../../../wwwroot/js/GSFramescript";
import { useViewport } from "../../components/Hook/useViewport";

import "./styles.css";
import 'swiper/css';
import 'swiper/css/navigation';
import Button from "../../components/Button/index";
import Modal from "../../components/Modal";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import AnimatedPage from "../../components/animatedPage";
import Loading from "../../components/Loading/Index";

const buildTour = () => {
    return JSON.parse(sessionStorage.getItem("listModelBuildTour"));
};

const CTVCode = getCookie("CodeCTV");

const BuildTour = () => {
    const audioRef = useRef([]);
    const swiperRef = useRef();
    const swiper = useSwiper();
    //const apiKey = "b42e0c99b9f04daa9761f9527adfa7f9";
    const history = useHistory();
    const [paramBuildTour,] = useState(buildTour());
    const [isShowClear,] = useState(true)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenModalPay, setIsOpenModalPay] = useState(false)
    const [isOpenModalOverview, setIsOpenModalOverview] = useState(false)
    const [isShowAddItem,] = useState(false)
    const [listBuildTour, setListBuildTour] = useState([])
    const [listBuildTourNew, setListBuildTourNew] = useState([])
    const [viewDetail, setViewDetail] = useState({})
    const [viewImgDetail, setViewImgDetail] = useState([])
    const [activeIndex, setActiveIndex] = useState(null);
    const [dataAddItem, setDataAddItem] = useState([]);
    const [tablePayment, setTablePayment] = useState([]);
    const [countDataDelete, setCountDataDelete] = useState(0);
    const [totalAll, setTotalAll] = useState(0);
    const [tax, setTax] = useState(0);
    const [totalPaymentCus, setTotalPaymentCus] = useState(0);
    const [voucher, setVoucher] = useState(0);
    const [codeVoucher, setCodeVoucher] = useState("");
    const [orderConfirm, setOrderConfirm] = useState(true);
    const [showGetVoucher, setShowGetVoucher] = useState(true);
    const [isOpenCode, setIsOpenCode] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [checkShowPayment, setCheckShowPayment] = useState(true);
    const [, setIsActiveButtonPayment] = useState(true);


    const handleClearItem = (e, dateLine, docDate, docDateEnd, hoursTour, hoursEndTour, index) => {
        e.preventDefault();
        const newObject = {
            AreaCode: "",
            DateLine: dateLine,
            Description: "",
            DocDate: docDate,
            DocDateEnd: docDateEnd,
            HoursTour: hoursTour,
            HoursEndTour: hoursEndTour,
            ImageLink: "",
            ImageLinkDetail: "",
            MapLink: "",
            Price: "",
            PriceChilder: "",
            PriceInfant: "",
            RowId: "",
            ShortDescription2: "",
            Stt: "",
            TourDescription: "",
            TourId: "",
            TourNameDetai: "",
            VR360Link: "",
            Total: 0,
            QuantityAdult: 0,
            QuantityChild: 0,
            QuantityBaby: 0,
        }
        var newArr = listBuildTourNew;
        newArr[index] = newObject;
        setCountDataDelete(prev => prev + 1);
        setListBuildTourNew(newArr);
    }
    const handleAddItem = (index, tourId, hoursTour, hoursEndTour) => {
        showDataAddItem(tourId, hoursTour, hoursEndTour);
        setActiveIndex(index === activeIndex ? null : index);
    }

    const changeTotal = (event, type, index) => {
        var value = event.target.value;
        value = Number(value) < 0 ? 0 : Number(value);
        if (type === 1) {
            tablePayment[index].QuantityAdult = value;
            tablePayment[index].Total = tablePayment[index].QuantityChild * tablePayment[index].PriceChilder
                + tablePayment[index].QuantityBaby * tablePayment[index].PriceInfant
                + value * tablePayment[index].Price;
        }
        if (type === 2) {
            tablePayment[index].QuantityChild = value;
            tablePayment[index].Total = tablePayment[index].QuantityAdult * tablePayment[index].Price
                + tablePayment[index].QuantityBaby * tablePayment[index].PriceInfant
                + value * tablePayment[index].PriceChilder;
        }
        if (type === 3) {
            tablePayment[index].QuantityBaby = value;
            tablePayment[index].Total = tablePayment[index].QuantityAdult * tablePayment[index].Price
                + tablePayment[index].QuantityChild * tablePayment[index].PriceChilder
                + value * tablePayment[index].PriceInfant;
        }
        setCountDataDelete(prev => prev + 1);
    };

    const nextSlide = (event) => {
        swiperRef.current.slideNext();
    };

    const playAudio = async (el) => {
        if (!el) return;
        const audioTrue = audioRef.current.filter((el) => el != null);

        audioTrue.forEach((item) => {
            item.pause();
            item.currentTime = 0;
        });

        try {
            await audioTrue[el.realIndex].play();
        } catch (err) {
            return;
        }
    };

    const disableSlide = () => {
        playAudio(false);
        setIsOpenModalOverview(!isOpenModalOverview);
        swiperRef.current.enabled = false;
    };

    useEffect(() => {
        if (countDataDelete > 0) {
            var totalTable = 0;

            for (let item of tablePayment) {
                totalTable += item.Total;
            }
            setTotalAll(totalTable);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countDataDelete])

    useEffect(() => {
        if (totalAll > 0) {
            setTotalPaymentCus(totalAll + totalAll * parseInt(process.env.REACT_APP_TAX) / 100 - voucher);
            setTax(totalAll * parseInt(process.env.REACT_APP_TAX) / 100);
        }
        else {
            setTotalPaymentCus(0);
            setTax(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalAll])

    const showDataAddItem = async (tourId, hoursTour, hoursEndTour) => {
        try {
            const model = {
                StoreName: 'Usp_GetSchedukeSame',
                Parametter: '@_TourId|@_HoursTour|@_HoursEndTour|@_AreaCode',
                Value: tourId + '|' + hoursTour + '|' + hoursEndTour + '|' + paramBuildTour.location
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];
            data ? setDataAddItem(data) : setDataAddItem([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleClickPay = () => {
        var newArrayPayment = listBuildTourNew.filter((item) => { return item.TourId });
        setTablePayment(newArrayPayment);
        setIsOpenModalPay(true)
    }
    useEffect(() => {
        const contactButton = document.querySelector('.contact'),
            callButton = document.querySelector('.call-button'),
            inputSearch = document.querySelector('.header-login .input-box');
        contactButton.classList.add('disable')
        callButton.classList.add('disable')
        inputSearch.classList.add('disable')

        return () => {
            contactButton.classList.remove('disable')
            callButton.classList.remove('disable')
            inputSearch.classList.remove('disable')
        }
    }, [])
    useEffect(() => {
        getDataBuildTour();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const testFunc = async () => {
        for (let item of listBuildTour) {
            await getDataPriceBuildTour(item);
        }
    };

    useEffect(() => {
        testFunc();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listBuildTour]);

    const getDataPriceBuildTour = async (item) => {
        try {
            var newArrBuildTour = [];
            newArrBuildTour.push(item);
            var currentDate = gs_datetime_yyyyMMddhhmmss_(new Date(), "");
            const model = {
                StoreName: "Usp_SeachTour",
                Parametter: "@_BranchCode|@_DocDate1|@_TourId|@_OrderBy|@_Top",
                Value: "A01|" + currentDate + "|" + item.TourId + "|1|5"
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            await fetch(`/HomeControllercs/GetData?${queryString}`).then(async (response) => {
                await response.json().then((dataRes) => {
                    const data = dataRes.dataend[0][0];
                    const updatedArrBuildTour = newArrBuildTour.map(item => ({
                        ...item,
                        Price: item.TourId ? data.Price : 0,
                        PriceChilder: item.TourId ? data.PriceChilder : 0,
                        PriceInfant: item.TourId ? data.PriceInfant : 0,
                        Total: 0,
                        QuantityAdult: 0,
                        QuantityChild: 0,
                        QuantityBaby: 0,
                    }));
                    setListBuildTourNew((prevList) => [...prevList, ...updatedArrBuildTour])
                });

            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const getDataBuildTour = async () => {
        try {
            const model = {
                StoreName: "Usp_BuildListTour",
                Parametter: "@_BranchCode|@_DocDate1|@_DocDate2|@_HourDe|@_HouseTourDelay|@_AreaCode",
                Value: "A01|" + paramBuildTour.dateIn + "|" + paramBuildTour.dateOut + "|||" + paramBuildTour.location
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            await response.json().then((dataResponse) => {
                const data = dataResponse.dataend[0];
                data.length > 0 ? setListBuildTour(data) : setListBuildTour([]);
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
    }, [isShowAddItem]);

    const viewDetailModal = async (isTour, data) => {
        if (!isTour) {
            setViewDetail(data)
            setIsOpenModal(true)
        } else {
            var currentDate = gs_datetime_yyyyMMddhhmmss_(new Date(), "");
            const model = {
                StoreName: "Usp_SeachTour",
                Parametter: "@_BranchCode|@_DocDate1|@_TourId|@_OrderBy|@_Top",
                Value: "A01|" + currentDate + "|" + data + "|1|5"
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const dataRes = dataResponse.dataend[0][0];
            viewImgDetailModal(data);
            dataRes ? setViewDetail(dataRes) : setViewDetail({});
            setIsOpenModal(true)
        }
    }

    const viewImgDetailModal = async (data) => {
        const model = {
            StoreName: "Usp_GetG20ExploreTour",
            Parametter: "@_Role|@_BranchCode|@_IsActive|@_IsGroup|@_HotelId|@_RoomId",
            Value: "G20Image-Explorer|A01|1|0|" + data + "|1"
        };

        const queryString = Object.keys(model)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
            .join("&");

        const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
        const dataResponse = await response.json();
        const dataRes = dataResponse.dataend[0];
        dataRes ? setViewImgDetail(dataRes) : setViewImgDetail([]);
    }

    const changeBuildTour = async (changeTourId, dateLine, docDate, docDateEnd, hoursTour, hoursEndTour, index) => {
        var currentDate = gs_datetime_yyyyMMddhhmmss_(new Date(), "");
        var newArr = listBuildTourNew;

        const model = {
            StoreName: "Usp_SeachTour",
            Parametter: "@_BranchCode|@_DocDate1|@_TourId|@_OrderBy|@_Top",
            Value: "A01|" + currentDate + "|" + changeTourId + "|1|5"
        };

        const queryString = Object.keys(model)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
            .join("&");

        const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
        const dataResponse = await response.json();
        const data = dataResponse.dataend[0][0];
        const newObject = {
            AreaCode: "",
            DateLine: dateLine,
            Description: "",
            DocDate: docDate,
            DocDateEnd: docDateEnd,
            HoursTour: hoursTour,
            HoursEndTour: hoursEndTour,
            ImageLink: "",
            ImageLinkDetail: data.ImageLink,
            MapLink: "",
            Price: data.Price,
            PriceChilder: data.PriceChilder,
            PriceInfant: data.PriceInfant,
            RowId: "",
            ShortDescription2: "",
            Stt: "",
            TourDescription: data.ShortDescription,
            TourId: data.Stt,
            TourNameDetai: data.TourName,
            VR360Link: "",
            Total: 0,
            QuantityAdult: 0,
            QuantityChild: 0,
            QuantityBaby: 0,
        }

        newArr[index] = newObject;

        setActiveIndex(null);
        setListBuildTourNew(newArr);
    };

    const getCodeVoucher = async () => {
        if (totalAll > 0) {
            setShowGetVoucher(false);
            setIsOpenCode(true)
            try {
                const model = {
                    StoreName: "Usp_AutoGetPromotionCodeWH",
                    Parametter: "@_Name|@_Amount",
                    Value: "Khách lẻ|10000",
                };

                const queryString = Object.keys(model)
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                    .join("&");

                const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
                const dataResponse = await response.json();
                const data = dataResponse.dataend[0][0];
                data ? setCodeVoucher(data.Code) : setCodeVoucher("");
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        } else {
            Swal.fire({
                title: "Lấy mã giảm giá thất bại",
                text: "Quý khách vui lòng nhập đầy đủ thông tin để lấy mã",
                icon: "error"
            });
        }
    };
    useEffect(() => {
        const timerLucky = setTimeout(() => {
            setIsOpenCode(false)
        }, 5000)
        return () => clearTimeout(timerLucky)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenCode])
    useEffect(() => {
        checkDiscountCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeVoucher]);
    const handleClickModalCode = () => {
        setIsOpenCode(false)
    };
    const handleCheckVoucher = (e) => {
        setCodeVoucher(e.target.value);
    }

    const checkDiscountCode = async () => {
        if (codeVoucher) {
            try {
                const model = {
                    StoreName: "Usp_CheckPromotionCode",
                    Parametter: "@_Code",
                    Value: codeVoucher,
                };

                const queryString = Object.keys(model)
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                    .join("&");

                const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
                const dataResponse = await response.json();
                const data = dataResponse.dataend[0][0];
                if (data.Amount > 0) {
                    data ? setVoucher(data.Amount) : setVoucher(0);
                } else {
                    Swal.fire({
                        title: "Mã này đã được sử dụng",
                        icon: "error"
                    });
                    setVoucher(0);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    useEffect(() => {
        checkDiscountCode();
        setTotalPaymentCus(totalAll + tax - voucher);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voucher]);

    const orderBuildTour = async () => {
        insertTour();
    };

    const CreatedBookingNewCode = async () => {
        var newBookingCode = "";
        try {
            const response = await fetch(`/HomeControllercs/ExecuteStoredProc`);
            const dataResponse = await response.json();
            newBookingCode = dataResponse.resultCode;
            return newBookingCode;
        } catch (error) {
            console.error("Error fetching data:", error);
            return newBookingCode;
        }
    };

    const CreatedBookingNewLostCode = async () => {
        var newBookingCode = "";
        try {
            const response = await fetch(`/HomeControllercs/ExecuteStoredProc`);
            const dataResponse = await response.json();
            newBookingCode = dataResponse.resultCode;
            return newBookingCode;
        } catch (error) {
            console.error("Error fetching data:", error);
            return newBookingCode;
        }

    };

    const insertTour = async () => {
        // Params public
        setIsLoading(true)
        var now = new Date();
        var currentYear = now.getFullYear();
        var currentDate = gs_datetime_yyyyMMddhhmm_(new Date(), "");
        var ctv = CTVCode ? CTVCode : "";
        var tax = "";
        var totalAMountTax = 0; // đang làm cứng
        var customerTel = paramBuildTour ? paramBuildTour.tel : "";
        var customerEmail = paramBuildTour ? paramBuildTour.email : "";
        var customerName = paramBuildTour ? paramBuildTour.fullName : "";
        var lostCode = await CreatedBookingNewLostCode(); //đang làm cứng

        // Params privte
        var supplierCode = ""; //đang làm cứng
        var hotelCode = ""; //đang làm cứng
        var totalAmountOld = 0;
        var totalTotalSaleAmount = 0;
        var totalAmount = 0;
        var dateOut = "";
        var dateIn = "";
        var newBookingCode = "";
        var promotionCode = "";
        var EmailSupplier = "";//đang làm cứng
        var NameSupplier = "";//đang làm cứng
        var listInfoMail = [];

        var infoMail = {
            LostCode: lostCode,
            CustomerName: customerName,
            CustomerEmail: customerEmail,
            CustomerPhone: customerTel,
            TourName: "",
            DateIn: "",
            DocNo: "",
            EmailSupplier: "", // đang làm cứng
            NameSupplier: "", // đang làm cứng
            DiscountAmount: 0,
            QuantityAdult: 0,
            QuantityChild: 0,
            QuantityBaby: 0,
            PriceAdult: 0,
            PriceChild: 0,
            PriceBaby: 0
        };

        for (var i = 0; i < tablePayment.length; i++) {
            if (tablePayment[i].QuantityAdult > 0 || tablePayment[i].QuantityChild > 0) {
                if (i < 1) {
                    supplierCode = ""; //đang làm cứng
                    EmailSupplier = ""; //đang làm cứng
                    NameSupplier = ""; //đang làm cứng
                    hotelCode = ""; //đang làm cứng
                    totalTotalSaleAmount = voucher;
                    totalAmount = tablePayment[i].QuantityAdult * tablePayment[i].Price + tablePayment[i].QuantityChild * tablePayment[i].PriceChilder + tablePayment[i].QuantityBaby * tablePayment[i].PriceInfant;
                    totalAmountOld = totalAmount - totalTotalSaleAmount;
                    dateOut = tablePayment[i].DocDate.substring(0, 10);
                    dateIn = tablePayment[i].DocDate.substring(0, 10);
                    newBookingCode = await CreatedBookingNewCode();
                    promotionCode = voucher > 0 ? codeVoucher : "";

                    infoMail = {
                        ...infoMail, QuantityAdult: tablePayment[i].QuantityAdult ? tablePayment[i].QuantityAdult : 0, PriceAdult: tablePayment[i].Price ? tablePayment[i].Price : 0,
                        QuantityChild: tablePayment[i].QuantityChild ? tablePayment[i].QuantityChild : 0, PriceChild: tablePayment[i].PriceChilder ? tablePayment[i].PriceChilder : 0,
                        QuantityBaby: tablePayment[i].QuantityBaby ? tablePayment[i].QuantityBaby : 0, PriceBaby: tablePayment[i].PriceInfant ? tablePayment[i].PriceInfant : 0,
                        DocNo: newBookingCode,
                        TourName: tablePayment[i].TourNameDetai,
                        DateIn: dateIn,
                        DiscountAmount: totalTotalSaleAmount,
                    };
                    listInfoMail.push(infoMail);
                    try {
                        const model = {
                            StoreName: 'Usp_G30BookingNewUpdateInsert',
                            Parametter: '@_key|@_ParentId|@_IsGroup|@_BranchCode|@_FiscalYear|@_Stt|@_DocCode|@_DocDate' +
                                '|@_DocNo|@_Description|@_DescriptionOther|@_CustomerCode|@_CustomerName|@_Email|@_PassPort' +
                                '|@_DateIdPassport|@_Tel|@_Address|@_DateIn|@_DateOut|@_NumberNight|@_CurrencyCode' +
                                '|@_ExchangeRate|@_PartnerCode|@_TotalAmount|@_TotalDeposit|@_TotalSaleAmount' +
                                '|@_TotalAmountOld|@_DocStatus|@_IsActive|@_CreatedBy|@_ModifiedBy' +
                                '|@_TotalAmountTax|@_Tax|@_HotelCode|@_PromotionCode|@_CTV|@_SupplierCode|@_LostTicket',
                            Value: 'I|-1|0|A01|' + currentYear + '||BT|' + currentDate + '|' + newBookingCode + '||||' + customerName + '|' +
                                customerEmail + '||' + currentDate + '|' + customerTel + '||' + dateIn + '|' + dateOut + '|0|VND|1||' +
                                totalAmount + '|0|' + totalTotalSaleAmount + '|' + totalAmountOld + '|0|1|||' + totalAMountTax + '|' + tax + '|' +
                                hotelCode + '|' + promotionCode + '|' + ctv + '|' + supplierCode + '|' + lostCode,
                        };

                        const queryString = Object.keys(model)
                            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                            .join("&");

                        const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
                        const dataResponse = await response.json();
                        const data = dataResponse.dataend[0][0];
                        if (data && data.Stt) {
                            insertTourDetail(data.Stt, i);
                            try {
                                const modelowner = infoMail;

                                const queryString = Object.keys(modelowner)
                                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                                    .join("&");

                                const response = await fetch(`/HomeControllercs/SendEmailAddBookingnewOther?${queryString}`);
                                await response.json();
                            } catch (error) {
                                console.error('Error fetching data:', error);
                            }

                            if (EmailSupplier !== "") {
                                try {
                                    const modelowner = infoMail;

                                    const queryString = Object.keys(modelowner)
                                        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                                        .join("&");

                                    const response = await fetch(`/HomeControllercs/SendEmailAddBookingnewSupllier?${queryString}`);
                                    await response.json();
                                } catch (error) {
                                    console.error('Error fetching data:', error);
                                }
                            }

                            if (promotionCode !== "") {
                                changeVoucherCode(promotionCode);
                            }
                        }
                    } catch (error) {
                        console.error("Error fetching data:", error);
                        setOrderConfirm(false);
                    }
                }
                else {
                    supplierCode = ""; //đang làm cứng
                    EmailSupplier = ""; //đang làm cứng
                    NameSupplier = ""; //đang làm cứng
                    hotelCode = ""; //chưa sử dụng
                    totalTotalSaleAmount = 0;
                    totalAmount = tablePayment[i].QuantityAdult * tablePayment[i].Price + tablePayment[i].QuantityChild * tablePayment[i].PriceChilder + tablePayment[i].QuantityBaby * tablePayment[i].PriceInfant;
                    totalAmountOld = totalAmount - totalTotalSaleAmount;
                    dateOut = tablePayment[i].DocDate.substring(0, 10);
                    dateIn = tablePayment[i].DocDate.substring(0, 10);
                    newBookingCode = await CreatedBookingNewCode();
                    promotionCode = "";

                    infoMail = {
                        ...infoMail, QuantityAdult: tablePayment[i].QuantityAdult ? tablePayment[i].QuantityAdult : 0, PriceAdult: tablePayment[i].Price ? tablePayment[i].Price : 0,
                        QuantityChild: tablePayment[i].QuantityChild ? tablePayment[i].QuantityChild : 0, PriceChild: tablePayment[i].PriceChilder ? tablePayment[i].PriceChilder : 0,
                        QuantityBaby: tablePayment[i].QuantityBaby ? tablePayment[i].QuantityBaby : 0, PriceBaby: tablePayment[i].PriceInfant ? tablePayment[i].PriceInfant : 0,
                        DocNo: newBookingCode,
                        TourName: tablePayment[i].TourNameDetai,
                        DateIn: dateIn,
                        DiscountAmount: totalTotalSaleAmount,
                    };
                    listInfoMail.push(infoMail);
                    try {
                        const model = {
                            StoreName: 'Usp_G30BookingNewUpdateInsert',
                            Parametter: '@_key|@_ParentId|@_IsGroup|@_BranchCode|@_FiscalYear|@_Stt|@_DocCode|@_DocDate' +
                                '|@_DocNo|@_Description|@_DescriptionOther|@_CustomerCode|@_CustomerName|@_Email|@_PassPort' +
                                '|@_DateIdPassport|@_Tel|@_Address|@_DateIn|@_DateOut|@_NumberNight|@_CurrencyCode' +
                                '|@_ExchangeRate|@_PartnerCode|@_TotalAmount|@_TotalDeposit|@_TotalSaleAmount' +
                                '|@_TotalAmountOld|@_DocStatus|@_IsActive|@_CreatedBy|@_ModifiedBy' +
                                '|@_TotalAmountTax|@_Tax|@_HotelCode|@_PromotionCode|@_CTV|@_SupplierCode|@_LostTicket',
                            Value: 'I|-1|0|A01|' + currentYear + '||BT|' + currentDate + '|' + newBookingCode + '||||' + customerName + '|' +
                                customerEmail + '||' + currentDate + '|' + customerTel + '||' + dateIn + '|' + dateOut + '|0|VND|1||' +
                                totalAmount + '|0|' + totalTotalSaleAmount + '|' + totalAmountOld + '|0|1|||' + totalAMountTax + '|' + tax + '|' +
                                hotelCode + '|' + promotionCode + '|' + ctv + '|' + supplierCode + '|' + lostCode,
                        };

                        const queryString = Object.keys(model)
                            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                            .join("&");

                        const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
                        const dataResponse = await response.json();

                        const data = dataResponse.dataend[0][0];
                        if (data && data.Stt) {
                            insertTourDetail(data.Stt, i);
                            try {
                                const modelowner = infoMail;

                                const queryString = Object.keys(modelowner)
                                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                                    .join("&");

                                const response = await fetch(`/HomeControllercs/SendEmailAddBookingnewOther?${queryString}`);
                                await response.json();
                            } catch (error) {
                                console.error('Error fetching data:', error);
                            }

                            if (EmailSupplier !== "") {
                                try {
                                    const modelowner = infoMail;

                                    const queryString = Object.keys(modelowner)
                                        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                                        .join("&");

                                    const response = await fetch(`/HomeControllercs/SendEmailAddBookingnewSupllier?${queryString}`);
                                    const result = await response.json();
                                    console.log(result);
                                } catch (error) {
                                    console.error('Error fetching data:', error);
                                }
                            }
                        }
                    } catch (error) {
                        console.error("Error fetching data:", error);
                        setOrderConfirm(false);
                    }
                }
            }
            else {
                Swal.fire({
                    title: "Đặt vé thất bại",
                    text: "Cần có những người lớn đi theo chịu trách nhiệm!",
                    icon: "error"
                });
                setIsLoading(false)
                return;
            }
        }

        try {
            var afterConvert = listInfoMail.map(item => Object.values(item).join('|')).join('|');

            const response = await fetch('/HomeControllercs/SendEmailAddBookingnewList', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `model=${encodeURIComponent(afterConvert)}`,
            });

            await response.json();
        } catch (error) {
            console.error('Lỗi khi gửi dữ liệu:', error);
        }

        if (orderConfirm) {
            setCheckShowPayment(true)
            setOpenPayment(true);
            setIsOpenModalPay(false);
            setIsLoading(false)
        } else {
            setIsLoading(false)
            setCheckShowPayment(false)
            setOpenPayment(true);
            setIsOpenModalPay(false);
        }
    };
    const handleClickPayment = () => {
        setOpenPayment(false)
        setIsLoading(true)
        setIsActiveButtonPayment(false);
        setTimeout(() => {
            setIsLoading(false)
            CTVCode ? history.push("/?id=" + CTVCode) : history.push("/");
        }, 2000)
    };
    const handleClickRedirectPayment = () => {
        setIsLoading(true)
        setIsActiveButtonPayment(false);
        setTimeout(() => {
            setIsLoading(false)
            CTVCode ? history.push("/?id=" + CTVCode) : history.push("/");
        }, 2000)
    };
    const changeVoucherCode = async (promotionCode) => {
        try {
            const model = {
                StoreName: 'Usp_G20PromotionCodeWHUpdateInsert',
                Parametter: '@_key|@_Code',
                Value: 'U|' + promotionCode,
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            await fetch(`/HomeControllercs/InsertUpdate?${queryString}`);
        } catch (error) {
            setOrderConfirm(false);
            console.error("Error fetching data:", error);
        }

    };

    const insertTourDetail = async (Stt, index) => {
        var objectNew = tablePayment[index];
        var now = new Date();
        var currentYear = now.getFullYear();
        var currentDate = gs_datetime_yyyyMMddhhmm_(new Date(), '');
        if (objectNew.QuantityAdult > 0) {
            const model = {
                StoreName: 'Usp_G30BookingNewDetailUpdateInsert',
                Parametter: '@_key|@_ParentId|@_IsGroup|@_BranchCode|@_FiscalYear|@_Stt|@_DateIn|@_DateOut|@_NumberNight|@_DocCode|@_DocDate|@_Description|@_ItemCode|@_Quantity|@_Price|@_Amount|@_AmountSale|@_OriginalAmount|@_DocStatus|@_IsActive|@_QuantityAdults|@_QuantityChilder',
                Value: 'I|-1|0|A01|' + currentYear + '|' + Stt + '|' + objectNew.DocDate.substring(0, 10) + '|' + objectNew.DocDate.substring(0, 10) + '|0|BT|' + currentDate + '|Người lớn|' + objectNew.TourId + '|' + objectNew.QuantityAdult + '|' + objectNew.Price + '|' + objectNew.QuantityAdult * objectNew.Price + '|0|' + objectNew.QuantityAdult * objectNew.Price + '|0|1|0|0',
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            await fetch(`/HomeControllercs/InsertUpdate?${queryString}`);
        }
        if (objectNew.QuantityChild > 0) {
            const model = {
                StoreName: 'Usp_G30BookingNewDetailUpdateInsert',
                Parametter: '@_key|@_ParentId|@_IsGroup|@_BranchCode|@_FiscalYear|@_Stt|@_DateIn|@_DateOut|@_NumberNight|@_DocCode|@_DocDate|@_Description|@_ItemCode|@_Quantity|@_Price|@_Amount|@_AmountSale|@_OriginalAmount|@_DocStatus|@_IsActive|@_QuantityAdults|@_QuantityChilder',
                Value: 'I|-1|0|A01|' + currentYear + '|' + Stt + '|' + objectNew.DocDate.substring(0, 10) + '|' + objectNew.DocDate.substring(0, 10) + '|0|BT|' + currentDate + '|Trẻ em|' + objectNew.TourId + '|' + objectNew.QuantityChild + '|' + objectNew.PriceChilder + '|' + objectNew.QuantityChild * objectNew.PriceChilder + '|0|' + objectNew.QuantityChild * objectNew.PriceChilder + '|0|1|0|0',
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            await fetch(`/HomeControllercs/InsertUpdate?${queryString}`);
        }
        if (objectNew.QuantityBaby > 0) {
            const model = {
                StoreName: 'Usp_G30BookingNewDetailUpdateInsert',
                Parametter: '@_key|@_ParentId|@_IsGroup|@_BranchCode|@_FiscalYear|@_Stt|@_DateIn|@_DateOut|@_NumberNight|@_DocCode|@_DocDate|@_Description|@_ItemCode|@_Quantity|@_Price|@_Amount|@_AmountSale|@_OriginalAmount|@_DocStatus|@_IsActive|@_QuantityAdults|@_QuantityChilder',
                Value: 'I|-1|0|A01|' + currentYear + '|' + Stt + '|' + objectNew.DocDate.substring(0, 10) + '|' + objectNew.DocDate.substring(0, 10) + '|0|BT|' + currentDate + '|Trẻ em|' + objectNew.TourId + '|' + objectNew.QuantityBaby + '|' + objectNew.PriceInfant + '|' + objectNew.QuantityBaby * objectNew.PriceInfant + '|0|' + objectNew.QuantityBaby * objectNew.PriceInfant + '|0|1|0|0',
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            await fetch(`/HomeControllercs/InsertUpdate?${queryString}`);
        }
    };
    const handleDeletePayment = (e, index) => {
        e.preventDefault();
        tablePayment.splice(index, 1);
        setCountDataDelete(prev => prev + 1);
    }

    const viewPort = useViewport();
    const isMobile = viewPort.width <= 1199.98;
    if (isMobile) {
        return (
            <AnimatedPage>
                <ChangePageTitle pageTitle="Lập lịch trình" />
                {isLoading && <Loading />}
                <div className="tour-banner" id="tourBanner">
                    <div className="container-fluid bootstrap snippets bootdeys">
                        <div className="col-md-12">
                            <div className="timeline-centered timeline-sm" id="build-list-tour">
                                <article className="timeline-entry">
                                    <div className="timeline-entry-inner">
                                        <div className="timeline-icon bg-gradient plane-start">
                                            <FontAwesomeIcon icon={faPlane} />
                                        </div>
                                        <div className="timeline-label">
                                            <h4 className="timeline-title">LỊCH TRÌNH DU LỊCH (2023/11/15 - 2023/11/21)</h4>
                                            <div className="timeline-body">
                                                <p>Lịch trình tham quan đã được Booknet.vn sắp xếp hợp lý.
                                                    Hãy thư giãn và tận hưởng mọi phút giây trong kỳ nghỉ của Quý khách!</p>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                {listBuildTourNew.map((item, index) => (
                                    <article className="timeline-entry" key={index}>
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon bg-gradient">
                                                <FontAwesomeIcon icon={faStar} />
                                            </div>
                                            <div className="timeline-label" >
                                                <h4 className="timeline-title">
                                                    {item.DateLine} ({item.DocDate.substring(0, 10)}) - {item.HoursTour} - {item.HoursEndTour}
                                                    <span className="add-item" onClick={() => handleAddItem(index, item.TourId, item.HoursTour, item.HoursEndTour)}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                        <div className={`${"add-schedule"} ${index === activeIndex ? "enable" : ""}`}>
                                                            {
                                                                dataAddItem.map((itemChild, indexChild) => {
                                                                    return (
                                                                        <label key={indexChild} className={"featured" + itemChild ? "" : "hide"} onClick={() => changeBuildTour(itemChild.TourId, item.DateLine, item.DocDate, item.DocDateEnd, item.HoursTour, item.HoursEndTour, index)}>
                                                                            <div className="schedule-content" >
                                                                                <div className="schedule-image">
                                                                                    <img src={"http://id.booknet.vn/" + itemChild.ImageLink} alt="" />
                                                                                </div>
                                                                                <p className="schedule-item">
                                                                                    <strong>
                                                                                        {itemChild.TourNameDetai}
                                                                                    </strong>
                                                                                    <input id={itemChild.TourId} type="radio" name="addItemBuildTour" />
                                                                                </p>
                                                                            </div>
                                                                        </label>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </span>
                                                </h4>
                                                {isShowClear && <div className={item.TourNameDetai ? "timeline-body" : "hide timeline-body"} >
                                                    <div className="timeline-inner">
                                                        <div className="timeline-body-inner-img">
                                                            <img src={"http://id.booknet.vn/" + item.ImageLinkDetail} alt="" />
                                                        </div>
                                                        <div className="timeline-body-inner-detail">
                                                            <p className="title">{item.TourNameDetai}</p>
                                                            <p className="description">
                                                                {item.TourDescription}
                                                            </p>
                                                            <div className={item.Price > 0 ? "price-footer" : "hide price-footer"} >
                                                                <p className="price"> {gs_currencyFormatfixed0(gs_parseFloat(item.Price))} VND</p>
                                                                <span>/người</span>
                                                            </div>
                                                        </div>
                                                        <span className="clearItem" onClick={(e) => handleClearItem(e, item.DateLine, item.DocDate, item.DocDateEnd, item.HoursTour, item.HoursEndTour, index)}>
                                                            <FontAwesomeIcon icon={faXmark} />
                                                        </span>
                                                    </div>
                                                    <div className="footer-tour">
                                                        {item.VR360Link && <Button btnBuidTour target="_blank" href={item.VR360Link} title="View 360" />}
                                                        {<Button btnBuidTour title="Chi tiết" onClick={() => item.TourId ? viewDetailModal(true, item.TourId) : viewDetailModal(false, { 'notTour': true, 'ImageLink': item.ImageLinkDetail, 'Description': item.TourDescription })} />}
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </article>
                                ))}
                                <article className="timeline-entry">
                                    <div className="timeline-entry-inner">
                                        <div className="timeline-icon bg-gradient plane-end">
                                            <FontAwesomeIcon icon={faPlane} />
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div className="timeline-footer">
                                <Button title="Tổng quan" onClick={e => setIsOpenModalOverview(!isOpenModalOverview)} btnOverview />
                                <Button title="Đặt dịch vụ" onClick={handleClickPay} btnPay />
                            </div>
                            <Modal title="Overview" className="popup-slide" modalHeading={false} closeModal={() => setIsOpenModalOverview(!isOpenModalOverview)} openModal={isOpenModalOverview}>
                                <div className={`popup ${isOpenModalOverview ? 'show' : ''}`}>
                                    <div className="popup-inner">
                                        <div className="popup-heading">
                                            <div className="popup-close" onClick={disableSlide}>
                                                <FontAwesomeIcon icon={faXmark} />
                                            </div>
                                        </div>
                                        <div className="popup-body">
                                            {!isOpenModalOverview || (
                                                <Swiper
                                                    ref={swiper}
                                                    style={{
                                                        '--swiper-navigation-color': '#fff',
                                                        '--swiper-pagination-color': '#fff',
                                                    }}
                                                    speed={2000}
                                                    loop={true}
                                                    parallax={true}
                                                    slidesPerView={1}
                                                    direction={'vertical'}
                                                    navigation={true}
                                                    modules={[Navigation, Autoplay]}
                                                    className="mySwiper"
                                                    onSwiper={(swiper) => {
                                                        swiperRef.current = swiper;
                                                    }}
                                                    onSlideChange={(el) => playAudio(el)}
                                                >
                                                    {
                                                        listBuildTourNew.map((item, index) =>
                                                            item.TourNameDetai.length === 0 || (
                                                                <SwiperSlide key={index}>
                                                                    <div className="swiper-slide__image">
                                                                        <img src={`http://id.booknet.vn/${item.ImageLinkDetail}`} alt={item?.TourNameDetai} />
                                                                    </div>
                                                                    <div className="swiper-slide__title">
                                                                        {item?.TourNameDetai || 'name tour'}
                                                                    </div>
                                                                    <audio
                                                                        id="myAudio"
                                                                        ref={(el) => (audioRef.current[index] = el)}
                                                                        onEnded={nextSlide}
                                                                    >
                                                                        <source
                                                                            src={`https://id.booknet.vn/${item?.ShortDescription2}`}
                                                                            type="audio/wav"
                                                                        />
                                                                    </audio>
                                                                </SwiperSlide>
                                                            )
                                                        )
                                                    }
                                                </Swiper>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <Modal title="Đặt hàng thanh toán" closeModal={setIsOpenModalPay} openModal={isOpenModalPay}>
                                <div id="no-more-tables">
                                    <table className="table table-bordered table-striped text-center table-responsive" id="tablePayMent">
                                        <thead className="table-success">
                                            <tr>
                                                <th rowSpan="2" >Tour Dịch vụ</th>
                                                <th rowSpan="2" >Ngày khởi hành</th>
                                                <th colSpan="3" >Số lượng</th>
                                                <th colSpan="3" >Giá vé</th>
                                                <th rowSpan="2" >Thành tiền</th>
                                                <th rowSpan="2" >Hành động</th>
                                            </tr>
                                            <tr>
                                                <th >Người lớn</th>
                                                <th >Trẻ em</th>
                                                <th >Em bé</th>
                                                <th >Người lớn</th>
                                                <th >Trẻ em</th>
                                                <th >Em bé</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tablePayment.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td data-title="Tour Dịch vụ">{item.TourNameDetai}</td>
                                                            <td data-title="Ngày khởi hành" >{item.DocDate.substring(0, 10)}</td>
                                                            <td data-title="Số lượng người lớn"><input type="number" min={0} defaultValue={item.QuantityAdult} onInput={(event) => changeTotal(event, 1, index)} place="" className="form-control" required="" /></td>
                                                            <td data-title="Số lượng trẻ em"><input type="number" min={0} defaultValue={item.QuantityChild} onInput={(event) => changeTotal(event, 2, index)} className="form-control" required="" /></td>
                                                            <td data-title="Số lượng em bé"><input type="number" min={0} defaultValue={item.QuantityBaby} onInput={(event) => changeTotal(event, 3, index)} className="form-control" required="" /></td>
                                                            <td data-title="Giá vé người lớn">{gs_currencyFormatfixed0(gs_parseFloat(item.Price))}</td>
                                                            <td data-title="Giá vé em bé">{gs_currencyFormatfixed0(gs_parseFloat(item.PriceChilder))}</td>
                                                            <td data-title="Giá vé trẻ em">{gs_currencyFormatfixed0(gs_parseFloat(item.PriceInfant))}</td>
                                                            <td data-title="Thành tiền" >{gs_currencyFormatfixed0(gs_parseFloat(item.Total)) ?? 0} VND</td>
                                                            <td data-title="Hành động" >
                                                                <div className="action_btns d-flex">
                                                                    <a href="#/" onClick={(e) => handleDeletePayment(e, index)} className="action_btn action_btn_trash">  <FontAwesomeIcon icon={faTrash} /> </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )

                                                })
                                            }
                                            <tr>
                                                <td className="table-total__price table-total__title" colSpan="8">Tổng thành tiền</td>
                                                <td className="table-total__price" >{gs_currencyFormatfixed0(gs_parseFloat(totalAll))} VND</td>
                                            </tr>
                                            <tr>
                                                <td className="table-total__price table-total__title" colSpan="8">Thuế (10%)</td>
                                                <td className="table-total__price" >{gs_currencyFormatfixed0(gs_parseFloat(tax))} VND</td>
                                            </tr>
                                            <tr>
                                                <td className="table-total__price table-total__title" colSpan="8">Giảm giá</td>
                                                <td className="table-total__price" >{gs_currencyFormatfixed0(gs_parseFloat(voucher))} VND</td>
                                            </tr>
                                            <tr>
                                                <td className="table-total__price table-total__title" colSpan="8">Thanh toán</td>
                                                <td className="table-total__price" >{gs_currencyFormatfixed0(gs_parseFloat(totalPaymentCus))} VND</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="subtitle-order">
                                        <div className="discount-footer">
                                            <input id="btnDiscountCode" placeholder="Thêm mã" onBlur={e => handleCheckVoucher(e)} defaultValue={codeVoucher} />
                                            {showGetVoucher && <Button title="Lấy mã" btnGetcode onClick={getCodeVoucher} />}
                                        </div>
                                        <Button title="Xác nhận đặt hàng" btnPrimaryBaydidau onClick={orderBuildTour} />
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <Modal title={viewDetail.notTour ? 'Chi tiết' : 'Chi tiết lịch trình'} closeModal={setIsOpenModal} openModal={isOpenModal}>
                        <div className="tz-gallery__checkout">
                            <div className="photos-grid-container gallery" style={viewDetail.notTour ? { gridTemplateColumns: 'unset' } : {}}>
                                <div className="main-photo img-box">
                                    <img src={"http://id.booknet.vn/" + viewDetail.ImageLink} alt="" />
                                </div>
                                {
                                    viewDetail.notTour ||
                                    <div className="sub">
                                        {
                                            viewImgDetail.map((item, index) => {
                                                if (index < 4) {
                                                    return (
                                                        <div className="img-box" key={item.Id}>
                                                            <img src={"http://id.booknet.vn/" + item.Link} alt="" />
                                                        </div>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="panel panel-info">
                            <div className="panel-heading">
                                Nội dung
                            </div>
                            <div className="panel-body" dangerouslySetInnerHTML={{ __html: viewDetail.Description }}>
                            </div>
                        </div>
                    </Modal>
                    <ModalLucky openModal={isOpenCode}>
                        <div className="modal-overlay" onClick={handleClickModalCode}></div>
                        <div className={`${'modal-lucky'} modal-lucky__sale`}>
                            <img src={`http://id.booknet.vn/Images/Emp/1.png`} alt={`http://id.booknet.vn/Images/Emp/1.png`} />
                        </div>
                    </ModalLucky>
                    <ModalLucky openModal={openPayment}>
                        <div className="modal-overlay" onClick={handleClickPayment}></div>
                        <div className={`${'modal-lucky'} modal-lucky__payment`}>
                            <div className={`${"modal-payment"}`}>
                                {checkShowPayment ? <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                    <circle className={`${"path"} ${openPayment ? "circle" : ""}`} fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                    <polyline className={`${"path"} ${openPayment ? "check" : ""}`} fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                </svg> : <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                    <circle class="path circle" fill="none" stroke="#D06079" strokeWidth="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                    <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                    <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                                </svg>}
                                <h4 className="title">{checkShowPayment ? "Thành công" : "Thất bại"}</h4>
                                {checkShowPayment ? <p className="des">Bạn đã đặt thành công chuyến đi.<br />
                                    Nhân viên tư vấn sẽ sớm liên lạc lại để xác nhận.<br />
                                    Xin cám ơn đã sử dụng dịch vụ tại Booknet.vn!</p> :
                                    <p className="des">Hiện tại hệ thống bị quá tải vì lượng truy lớn.<br />
                                        Quý khách vui lòng chọn lại dịch vụ lần nữa.<br /> Rất xin lỗi vì sự bất tiện này.</p>}
                                <div className="footer-modal__payment">
                                    <Button title="Xác nhận" btnComfirm onClick={handleClickRedirectPayment} />
                                </div>
                            </div>
                        </div>
                    </ModalLucky>
                </div>
            </AnimatedPage>
        )
    }
    return (
        <AnimatedPage>
            <ChangePageTitle pageTitle="Lập lịch trình" />
            {isLoading && <Loading />}
            <div className="tour-banner" id="tourBanner">
                <div className="container-fluid bootstrap snippets bootdeys">
                    <div className="col-md-12">
                        <div className="timeline-centered timeline-sm" id="build-list-tour">
                            <article className="timeline-entry">
                                <div className="timeline-entry-inner">
                                    <div className="timeline-icon bg-gradient plane-start">
                                        <FontAwesomeIcon icon={faPlane} />
                                    </div>
                                    <div className="timeline-label">
                                        <h4 className="timeline-title">LỊCH TRÌNH DU LỊCH ({paramBuildTour.dateIn.substring(0, 10)} - {paramBuildTour.dateOut.substring(0, 10)})</h4>
                                        <div className="timeline-body">
                                            <p>Lịch trình tham quan đã được Booknet.vn sắp xếp hợp lý.
                                                Hãy thư giãn và tận hưởng mọi phút giây trong kỳ nghỉ của Quý khách!</p>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {
                                listBuildTourNew.map((item, index) => {
                                    if ((index + 4) % 2 === 0) {
                                        return (
                                            <article className="timeline-entry left-aligned" key={index}>
                                                <div className="timeline-entry-inner">
                                                    <div className="timeline-icon bg-gradient">
                                                        <FontAwesomeIcon icon={faStar} />
                                                    </div>
                                                    <div className="timeline-label" >
                                                        <h4 className="timeline-title">
                                                            {item.DateLine} ({item.DocDate.substring(0, 10)}) - {item.HoursTour} - {item.HoursEndTour}
                                                            <span className="add-item" onClick={() => handleAddItem(index, item.TourId, item.HoursTour, item.HoursEndTour)}>
                                                                <FontAwesomeIcon icon={faPlus} />
                                                                <div className={`${"add-schedule"} ${index === activeIndex ? "enable" : ""}`}>
                                                                    {
                                                                        dataAddItem.map((itemChild, indexChild) => {
                                                                            return (
                                                                                <label key={indexChild} className={"featured" + itemChild ? "" : "hide"} onClick={() => changeBuildTour(itemChild.TourId, item.DateLine, item.DocDate, item.DocDateEnd, item.HoursTour, item.HoursEndTour, index)}>
                                                                                    <div className="schedule-content" >
                                                                                        <div className="schedule-image">
                                                                                            <img src={"http://id.booknet.vn/" + itemChild.ImageLink} alt="" />
                                                                                        </div>
                                                                                        <p className="schedule-item">
                                                                                            <strong>
                                                                                                {itemChild.TourNameDetai}
                                                                                            </strong>
                                                                                            <input id={itemChild.TourId} type="radio" name="addItemBuildTour" />
                                                                                        </p>
                                                                                    </div>
                                                                                </label>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            </span>
                                                        </h4>
                                                        {isShowClear && <div className={item.TourNameDetai ? "timeline-body" : "hide timeline-body"} >
                                                            <div className="timeline-inner">
                                                                <div className="timeline-body-inner-img">
                                                                    <img src={"http://id.booknet.vn/" + item.ImageLinkDetail} alt="" />
                                                                </div>
                                                                <div className="timeline-body-inner-detail">
                                                                    <p className="title">{item.TourNameDetai}</p>
                                                                    <p className="description">
                                                                        {item.TourDescription}
                                                                    </p>
                                                                    <div className={item.Price > 0 ? "price-footer" : "hide price-footer"} >
                                                                        <p className="price"> {gs_currencyFormatfixed0(gs_parseFloat(item.Price))} VND</p>
                                                                        <span>/người</span>
                                                                    </div>
                                                                </div>
                                                                <span className="clearItem" onClick={(e) => handleClearItem(e, item.DateLine, item.DocDate, item.DocDateEnd, item.HoursTour, item.HoursEndTour, index)}>
                                                                    <FontAwesomeIcon icon={faXmark} />
                                                                </span>
                                                            </div>
                                                            <div className="footer-tour">
                                                                {item.VR360Link && <Button btnBuidTour target="_blank" href={item.VR360Link} title="View 360" />}
                                                                {<Button btnBuidTour title="Chi tiết" onClick={() => item.TourId ? viewDetailModal(true, item.TourId) : viewDetailModal(false, { 'notTour': true, 'ImageLink': item.ImageLinkDetail, 'Description': item.TourDescription })} />}
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </article>
                                        )
                                    } else {
                                        return (
                                            <article className="timeline-entry" key={index}>
                                                <div className="timeline-entry-inner">
                                                    <div className="timeline-icon bg-gradient">
                                                        <FontAwesomeIcon icon={faStar} />
                                                    </div>
                                                    <div className="timeline-label" >
                                                        <h4 className="timeline-title">
                                                            {item.DateLine} ({item.DocDate.substring(0, 10)}) - {item.HoursTour} - {item.HoursEndTour}
                                                            <span className="add-item" onClick={() => handleAddItem(index, item.TourId, item.HoursTour, item.HoursEndTour)}>
                                                                <FontAwesomeIcon icon={faPlus} />
                                                                <div className={`${"add-schedule"} ${index === activeIndex ? "enable" : ""}`}>
                                                                    {
                                                                        dataAddItem.map((itemChild, indexChild) => {
                                                                            return (
                                                                                <label key={indexChild} className={"featured" + itemChild ? "" : "hide"} onClick={() => changeBuildTour(itemChild.TourId, item.DateLine, item.DocDate, item.DocDateEnd, item.HoursTour, item.HoursEndTour, index)}>
                                                                                    <div className="schedule-content" >
                                                                                        <div className="schedule-image">
                                                                                            <img src={"http://id.booknet.vn/" + itemChild.ImageLink} alt="" />
                                                                                        </div>
                                                                                        <p className="schedule-item">
                                                                                            <strong>
                                                                                                {itemChild.TourNameDetai}
                                                                                            </strong>
                                                                                            <input id={itemChild.TourId} type="radio" name="addItemBuildTour" />
                                                                                        </p>
                                                                                    </div>
                                                                                </label>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            </span>
                                                        </h4>
                                                        {isShowClear && <div className={item.TourNameDetai ? "timeline-body" : "hide timeline-body"} >
                                                            <div className="timeline-inner">
                                                                <div className="timeline-body-inner-img">
                                                                    <img src={"http://id.booknet.vn/" + item.ImageLinkDetail} alt="" />
                                                                </div>
                                                                <div className="timeline-body-inner-detail">
                                                                    <p className="title">{item.TourNameDetai}</p>
                                                                    <p className="description">
                                                                        {item.TourDescription}
                                                                    </p>
                                                                    <div className={item.Price > 0 ? "price-footer" : "hide price-footer"} >
                                                                        <p className="price"> {gs_currencyFormatfixed0(gs_parseFloat(item.Price))} VND</p>
                                                                        <span>/người</span>
                                                                    </div>
                                                                </div>
                                                                <span className="clearItem" onClick={(e) => handleClearItem(e, item.DateLine, item.DocDate, item.DocDateEnd, item.HoursTour, item.HoursEndTour, index)}>
                                                                    <FontAwesomeIcon icon={faXmark} />
                                                                </span>
                                                            </div>
                                                            <div className="footer-tour">
                                                                {item.VR360Link && <Button btnBuidTour target="_blank" href={item.VR360Link} title="View 360" />}
                                                                {<Button btnBuidTour title="Chi tiết" onClick={() => item.TourId ? viewDetailModal(true, item.TourId) : viewDetailModal(false, { 'notTour': true, 'ImageLink': item.ImageLinkDetail, 'Description': item.TourDescription })} />}
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </article>
                                        )
                                    }

                                })
                            }
                            <article className="timeline-entry">
                                <div className="timeline-entry-inner">
                                    <div className="timeline-icon bg-gradient plane-end">
                                        <FontAwesomeIcon icon={faPlane} />
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="timeline-footer">
                            <Button title="Tổng quan" onClick={e => setIsOpenModalOverview(!isOpenModalOverview)} btnOverview />
                            <Button title="Đặt dịch vụ" onClick={handleClickPay} btnPay />
                        </div>
                        <Modal title="Overview" className="popup-slide" modalHeading={false} closeModal={disableSlide} openModal={isOpenModalOverview}>
                            <div className={`popup ${isOpenModalOverview ? 'show' : ''}`}>
                                <div className="popup-inner">
                                    <div className="popup-heading">
                                        <div className="popup-close" onClick={disableSlide}>
                                            <FontAwesomeIcon icon={faXmark} />
                                        </div>
                                    </div>
                                    <div className="popup-body">
                                        {!isOpenModalOverview || (
                                            <Swiper
                                                ref={swiper}
                                                style={{
                                                    '--swiper-navigation-color': '#fff',
                                                    '--swiper-pagination-color': '#fff',
                                                }}
                                                speed={2000}
                                                loop={true}
                                                parallax={true}
                                                slidesPerView={1}
                                                direction={'vertical'}
                                                navigation={true}
                                                modules={[Navigation, Autoplay]}
                                                className="mySwiper"
                                                onSwiper={(swiper) => {
                                                    swiperRef.current = swiper;
                                                }}
                                                onSlideChange={(el) => playAudio(el)}
                                            >
                                                {
                                                    listBuildTourNew.map((item, index) =>
                                                        item.TourNameDetai.length === 0 || (
                                                            <SwiperSlide key={index}>
                                                                <div className="swiper-slide__image">
                                                                    <img src={`http://id.booknet.vn/${item.ImageLinkDetail}`} alt={item?.TourNameDetai} />
                                                                </div>
                                                                <div className="swiper-slide__title">
                                                                    {item?.TourNameDetai || 'name tour'}
                                                                </div>
                                                                <audio
                                                                    id="myAudio"
                                                                    ref={(el) => (audioRef.current[index] = el)}
                                                                    onEnded={nextSlide}
                                                                >
                                                                    <source
                                                                        src={`https://id.booknet.vn/${item?.ShortDescription2}`}
                                                                        type="audio/wav"
                                                                    />
                                                                </audio>
                                                            </SwiperSlide>
                                                        )
                                                    )
                                                }
                                            </Swiper>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Modal title="Đặt hàng thanh toán" closeModal={setIsOpenModalPay} openModal={isOpenModalPay} className="modal-payment__custom">
                            <div id="no-more-tables">
                                <table className="table table-bordered table-striped text-center table-responsive" id="tablePayMent">
                                    <thead className="table-success">
                                        <tr>
                                            <th rowSpan="2" >Tour Dịch vụ</th>
                                            <th rowSpan="2" >Ngày khởi hành</th>
                                            <th colSpan="3" >Số lượng</th>
                                            <th colSpan="3" >Giá vé</th>
                                            <th rowSpan="2" >Thành tiền</th>
                                            <th rowSpan="2" >Hành động</th>
                                        </tr>
                                        <tr>
                                            <th >Người lớn</th>
                                            <th >Trẻ em</th>
                                            <th >Em bé</th>
                                            <th >Người lớn</th>
                                            <th >Trẻ em</th>
                                            <th >Em bé</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tablePayment.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td data-title="Tour Dịch vụ">{item.TourNameDetai}</td>
                                                        <td data-title="Ngày khởi hành" >{item.DocDate.substring(0, 10)}</td>
                                                        <td className="custom-width__sl" data-title="Số lượng người lớn"><input type="number" min={0} defaultValue={item.QuantityAdult} onInput={(event) => changeTotal(event, 1, index)} place="" className="form-control" required="" /></td>
                                                        <td className="custom-width__sl" data-title="Số lượng trẻ em"><input type="number" min={0} defaultValue={item.QuantityChild} onInput={(event) => changeTotal(event, 2, index)} className="form-control" required="" /></td>
                                                        <td className="custom-width__sl" data-title="Số lượng em bé"><input type="number" min={0} defaultValue={item.QuantityBaby} onInput={(event) => changeTotal(event, 3, index)} className="form-control" required="" /></td>
                                                        <td className="custom-width" data-title="Giá vé người lớn">{gs_currencyFormatfixed0(gs_parseFloat(item.Price))}</td>
                                                        <td className="custom-width" data-title="Giá vé em bé">{gs_currencyFormatfixed0(gs_parseFloat(item.PriceChilder))}</td>
                                                        <td data-title="Giá vé trẻ em">{gs_currencyFormatfixed0(gs_parseFloat(item.PriceInfant))}</td>
                                                        <td className="custom-width" data-title="Thành tiền" >{gs_currencyFormatfixed0(gs_parseFloat(item.Total)) ?? 0} VND</td>
                                                        <td data-title="Hành động" >
                                                            <div className="action_btns">
                                                                <a href="#/" onClick={(e) => handleDeletePayment(e, index)} className="action_btn action_btn_trash">  <FontAwesomeIcon icon={faTrash} /> </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )

                                            })
                                        }
                                        <tr>
                                            <td className="table-total__price table-total__title" colSpan="9">Tổng thành tiền</td>
                                            <td className="table-total__price" >{gs_currencyFormatfixed0(gs_parseFloat(totalAll))} VND</td>
                                        </tr>
                                        <tr>
                                            <td className="table-total__price table-total__title" colSpan="9">Thuế (10%)</td>
                                            <td className="table-total__price" >{gs_currencyFormatfixed0(gs_parseFloat(tax))} VND</td>
                                        </tr>
                                        <tr>
                                            <td className="table-total__price table-total__title" colSpan="9">Giảm giá</td>
                                            <td className="table-total__price" >{gs_currencyFormatfixed0(gs_parseFloat(voucher))} VND</td>
                                        </tr>
                                        <tr>
                                            <td className="table-total__price table-total__title" colSpan="9">Thanh toán</td>
                                            <td className="table-total__price" >{gs_currencyFormatfixed0(gs_parseFloat(totalPaymentCus))} VND</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="subtitle-order">
                                    <div className="discount-footer">
                                        <input id="btnDiscountCode" placeholder="Thêm mã" onBlur={e => handleCheckVoucher(e)} defaultValue={codeVoucher} />
                                        {showGetVoucher && <Button title="Lấy mã" btnGetcode onClick={getCodeVoucher} />}
                                    </div>
                                    <Button title="Xác nhận đặt hàng" btnPrimaryBaydidau onClick={orderBuildTour} />
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
                <Modal title={viewDetail.notTour ? 'Chi tiết' : 'Chi tiết lịch trình'} closeModal={setIsOpenModal} openModal={isOpenModal}>
                    <div className="tz-gallery__checkout">
                        <div className="photos-grid-container gallery" style={viewDetail.notTour ? { gridTemplateColumns: 'unset' } : {}}>
                            <div className="main-photo img-box">
                                <img src={"http://id.booknet.vn/" + viewDetail.ImageLink} alt="" />
                            </div>
                            {
                                viewDetail.notTour ||
                                <div className="sub">
                                    {
                                        viewImgDetail.map((item, index) => {
                                            if (index < 4) {
                                                return (
                                                    <div className="img-box" key={item.Id}>
                                                        <img src={"http://id.booknet.vn/" + item.Link} alt="" />
                                                    </div>
                                                )
                                            } else {
                                                return null
                                            }
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="panel panel-info">
                        <div className="panel-heading">
                            Nội dung
                        </div>
                        <div className="panel-body" style={viewDetail.notTour ? { fontSize: '16px' } : {}} dangerouslySetInnerHTML={{ __html: viewDetail.Description }}>
                        </div>
                    </div>
                </Modal>
                <ModalLucky openModal={isOpenCode}>
                    <div className="modal-overlay" onClick={handleClickModalCode}></div>
                    <div className={`${'modal-lucky'} modal-lucky__sale`}>
                        <img src={`http://id.booknet.vn/Images/Emp/1.png`} alt={`http://id.booknet.vn/Images/Emp/1.png`} />
                    </div>
                </ModalLucky>
                <ModalLucky openModal={openPayment}>
                    <div className="modal-overlay" onClick={handleClickPayment}></div>
                    <div className={`${'modal-lucky'} modal-lucky__payment`}>
                        <div className={`${"modal-payment"}`}>
                            {checkShowPayment ? <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle className={`${"path"} ${openPayment ? "circle" : ""}`} fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                <polyline className={`${"path"} ${openPayment ? "check" : ""}`} fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                            </svg> : <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle class="path circle" fill="none" stroke="#D06079" strokeWidth="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                            </svg>}
                            <h4 className="title">{checkShowPayment ? "Thành công" : "Thất bại"}</h4>
                            {checkShowPayment ? <p className="des">Bạn đã đặt thành công chuyến đi.<br />
                                Nhân viên tư vấn sẽ sớm liên lạc lại để xác nhận.<br />
                                Xin cám ơn đã sử dụng dịch vụ tại Booknet.vn!</p> :
                                <p className="des">Hiện tại hệ thống bị quá tải vì lượng truy lớn.<br />
                                    Quý khách vui lòng chọn lại dịch vụ lần nữa.<br /> Rất xin lỗi vì sự bất tiện này.</p>}
                            <div className="footer-modal__payment">
                                <Button title="Xác nhận" btnComfirm onClick={handleClickRedirectPayment} />
                            </div>
                        </div>
                    </div>
                </ModalLucky>
            </div>
        </AnimatedPage>
    )
}

export default BuildTour
