import React, { } from 'react';
import './styles.css';
import steendy from '../../../wwwroot/images/steendy.jpg';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const CollaboratorPolicy = () => {
    return (
        <div className="container" style={{ marginTop: "20px" }}>
            <div className="row">
                <div className="col-lg-4 mb-3">
                    <img src={steendy} style={{ width: "90%" }} alt="steendy" />
                </div>
                <div className="col-lg-8">
                    <div className="row">
                        <div className="panel panel-info">
                            <div className="panel-heading">
                                Chính sách cộng tác viên Đại lý vé máy bay baydidau.vn
                            </div>
                            <div className="panel-body">
                                <p className="position-col-8"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>HÃY LƯU ĐƯỜNG LINK CỦA BẠN LẠI VÌ CHẮC CHẮN CÓ LÚC NGƯỜI QUEN BẠN SẼ ĐI MÁY BAY!</strong></span></span></span></p>
                                <p className="position-col-8"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>Nhận tiền hoa hồng chỉ việc CHIA SẺ LINK HOẶC QR CODE!</strong></span></span></span></p>
                                <p className="position-col-8"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>Liên hệ phone/zalo: 0935 699 815 để được hỗ trợ  cộng tác viên (CTV) và đặt in Standee.</strong></span></span></span></p>
                                <p className="position-col-8">
                                    <span style={{ fontSize: "16px" }}><span>Đại lý vé máy bay Baydidau.vn có mức giá tốt nhất và chi trả hoa hồng cao cho CTV. Với hình thức làm việc đơn giản chỉ gửi link giới thiệu hoặc mã qrcode cho khách vào đặt vé là bạn đã hưởng chiết khấu từ chúng tôi.</span></span>
                                </p>
                                <p className="position-col-8"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>I. Bảng chiết khấu CTV</strong></span></span></span></p>
                                <p className="position-col-8">
                                    <span style={{ fontSize: "16px" }}><span>- Chiết khấu linh động tuỳ vào từng loại vé và từng cấp đại lý giao động từ 50.000đ đến 200.000đ</span></span>
                                </p>
                                <p className="position-col-8"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>II. Cách thức cộng tác</strong></span></span></span></p>
                                <p className="position-col-8">
                                    <span style={{ fontSize: "16px" }}><span><strong> Bước 1:</strong> Vào website baydidau.vn đăng ký CTV. Sau khi đăng ký bạn sẽ nhận link riêng của bạn để giới thiệu khách, ví dụ: baydidau.vn/Id=ctv1234 và mã QR Code riêng bạn. Bạn hãy lưu thông tin này lại.</span></span>
                                </p>
                                <p className="position-col-8">
                                    <span style={{ fontSize: "16px" }}><span><strong> Bước 2:</strong> Giới thiệu Link của bạn hoặc mã QR Code cho khách vào đặt vé trên smartphone. Nếu khách không tự đặt vé được thì bạn sẽ đặt vé giúp khách hàng.</span></span>
                                </p>
                                <p className="position-col-8">
                                    <span style={{ fontSize: "16px" }}><span><strong> Bước 3:</strong> Sau khi khách đặt vé xong thì sẽ khách sẽ thanh toán chuyển khoản ngân hàng cho đại lý để đại lý xuất vé. Nếu khách trả tiền mặt thì bạn sẽ thu tiền mặt khách và bạn chuyển khoản tài khoản của bạn qua đại lý.</span></span>
                                </p>
                                <p className="position-col-8">
                                    <span style={{ fontSize: "16px" }}><span><strong> Bước 4:</strong> Sau khi xuất vé thành công, CTV sẽ được ghi nhận doanh thu để đại lý trích chiết khấu. Bạn sẽ được đại lý chi trả chiết khấu khi đủ tối thiểu 250.000 đồng và thứ hai hằng tuần.</span></span>
                                </p>
                                <p className="position-col-8">
                                    <span style={{ fontSize: "16px" }}><span>Sau khi xuất vé thành công, nhân viên hành chính đại lý sẽ tiếp tục hỗ trợ khách hàng thủ tục tiếp theo.</span></span>
                                </p>

                                <p className="position-col-8"><span style={{ color: "#e74c3c" }}><span style={{ fontSize: "16px" }}><span><strong>III. Hỗ trợ quảng cáo</strong></span></span></span></p>
                                <p className="position-col-8">
                                    <span style={{ fontSize: "16px" }}><span>Để hoạt động quảng cáo tốt hơn các CTV đang là chủ cửa hàng, khách sạn, nhà nghỉ đang kinh doanh ở vị trí nhiều khách qua lại. Đại lý Baydidau.vn có hỗ trợ thiết kế và in Standee quảng cáo để thuận lợi cho quá trình kinh doanh.</span></span>
                                </p>

                                <p className="position-col-8">
                                    <span style={{ fontSize: "16px" }}><span>Mẫu Standee ( 60cm*160cm) như mẫu ở bên trái màn hình.</span></span>
                                </p>
                                <br />
                                <div className="text-center">
                                    <NavLink tag={Link} className="footer-csdkbm btn btn-danger" to="/dang-ky">
                                        Đăng ký ngay
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CollaboratorPolicy;