import React from 'react';
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ closeModal, title = "Modal", openModal, children = "", className = "", modalHeading = true }) => {
    return (
        <div className={!openModal ? "modal-container" : "modal-container enable"}>
            <div className="modal-overlay" onClick={() => closeModal(false)}>Modal</div>
            <div className={`${'modal-inner'} ${className}`}>
                {
                    !modalHeading ||
                    <div className="modal-heading">
                        <h4>{title}</h4>
                        <div className="modal-close" onClick={() => closeModal(false)}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </div>
                }
                <div className="modal-body">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal;