import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Parallax, Navigation, Autoplay } from 'swiper/modules';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import "./styles.css";

const Schedule = () => {
    const [show, setShow] = useState(false);
    //responsiveVoice.speak("xin chào", "UK English Male");

    return (
        <div>
            <input className="" type="button" value="show slide" onClick={() => setShow(!show)} />

            <div className={`popup ${show ? 'show' : ''}`}>
                <div className="popup-inner">
                    <div className="popup-heading">
                        <div className="popup-close" onClick={() => setShow(!show)}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </div>
                    <div className="popup-body">

                        <Swiper
                            style={{
                                '--swiper-navigation-color': '#fff',
                                '--swiper-pagination-color': '#fff',
                            }}
                            speed={2000}
                            loop={true}
                            parallax={true}
                            slidesPerView={1}
                            direction={'vertical'}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Parallax, Navigation, Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="swiper-slide__image">
                                    <img src="http://id.booknet.vn/Images/Picture/sun-world-ba-na-hills-10.jpg" alt="sun-world-ba-na-hills" />
                                </div>
                                <div className="swiper-slide__title">
                                    SƠN TRÀ – NGŨ HÀNH SƠN – HỘI AN
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide__image">
                                    <img src="http://id.booknet.vn/Images/Picture/rung-dua-bay-mau-3.jpg" alt="khach-san-ba-na-hills" />
                                </div>
                                <div className="swiper-slide__title">
                                    SÂN BAY – BẢO TÀNG 3D – CHỢ ĐÊM SƠN TRÀ
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide__image">
                                    <img src="http://id.booknet.vn/Images/Picture/C%E1%BB%95ng-tr%E1%BB%9Di-%C4%90%C3%B4ng-Giang-ivivu.jpg" alt="du-thuyen-tren-song-han-banne" />
                                </div>
                                <div className="swiper-slide__title">
                                    BÀ NÀ HILLS – CÁC CÂY CẦU – NGỒI DU THUYỀN SÔNG HÀN
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Schedule;
