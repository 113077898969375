import React, { } from 'react';
import AOS from 'aos';
import 'bootstrap/dist/css/bootstrap.css';
import 'aos/dist/aos.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { createRoot } from "react-dom/client";
import ScrollToTop from './views/components/ScrollToTop/ScrollToTop'
import Cookies from 'js-cookie';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const idCtv = new URLSearchParams(window.location.search).get("id");
const getInfoCTVCookies = Cookies.get("CodeCTV") ? Cookies.get("CodeCTV") : "";

const getInfoCTV = async () => {
    try {
        const model = {
            StoreName: "Usp_GetInfoCtv",
            Parametter: "@_EmployeeCode",
            Value: idCtv,
        };

        const queryString = Object.keys(model)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
            .join("&");

        const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
        const dataResponse = await response.json();
        const data = dataResponse.dataend[0][0];

        if (data) {
            Cookies.set('CodeCTV', data.Code, { expires: 365 * 10 });
            Cookies.set('EmailCTV', data.Email, { expires: 365 * 10 });
            Cookies.set('QrCTV', data.ImgQr, { expires: 365 * 10 });
            Cookies.set('LinkCTV', data.Link, { expires: 365 * 10 });
            Cookies.set('NameCTV', data.Name, { expires: 365 * 10 });
            Cookies.set('SDTCTV', data.SellPhone, { expires: 365 * 10 });
            Cookies.set('LogoCTV', data.Picture, { expires: 365 * 10 });
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

const initializeApp = async () => {
    if (idCtv && idCtv !== "") {
        await getInfoCTV();
    }

    root.render(
        <BrowserRouter>
            <ScrollToTop />
            <App />
        </BrowserRouter>
    );
    AOS.init();
};

initializeApp();