import React, { createContext, useEffect, useState } from 'react';
import NavMenu from './shares/Header';
import Footer from './shares/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import './styles.css'
import sendMail from '../wwwroot/images/paper-plane.png';
import facebook from '../wwwroot/images/facebook.png';
import zalo from '../wwwroot/images/zalo-icon.png';
import phone from '../wwwroot/images/phone.png';
import comment from '../wwwroot/images/comment.png';
import {
    getCookie
} from "../wwwroot/js/GSFramescript";
import Loading from './components/Loading/Index';

export const MyContext = createContext();

const getInfoCTVPhone = getCookie("SDTCTV");
const getInfoCTVEmail = getCookie("EmailCTV");

const Layout = (props) => {
    const [scrolltopdata, setscrolltopdata] = useState('');
    const [isActiceContact, setIsActiceContact] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");
    useEffect(() => {
        setIsLoading(true)
        const timer = setTimeout(() => {
            setIsLoading(false)
        }, 3000)
        return () => clearTimeout(timer)
    }, [])
    const handleClickShowComment = (e) => {
        e.preventDefault();
        setIsActiceContact(prevCheck => !prevCheck)
    }
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY < 15) {
                setscrolltopdata('');
            } else {
                setscrolltopdata('scrolled');
            }
        });
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div>
            {isLoading && <Loading />}
            <NavMenu search={search} setSearch={setSearch} />
            <div className="main" tag="main">
                <MyContext.Provider value={{ search }}>
                    {props.children}
                </MyContext.Provider>
            </div>
            <button className={`btn-scroll__top ${scrolltopdata}`} onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}>
                <FontAwesomeIcon icon={faArrowUp} />
            </button>
            <div className="call-button">
                <div id="phone-vr" className="button-contact">
                    <div className="phone-vr">
                        <div className="phone-vr-circle-fill"></div>
                        <div className="phone-vr-img-circle">
                            <a href={`tel:${getInfoCTVPhone ? getInfoCTVPhone : "0935699815"}`}>
                                <img src={phone} alt="phone" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact">
                <div className="ws-contact">
                    <a href={`mailto:${getInfoCTVEmail ? getInfoCTVEmail : "dunglt@gardensoft.com.vn"}`} tooltip="Gửi mail" className={`ws-contact-icon mail ${isActiceContact ? 'not-active' : 'is-active'}`}>
                        <img src={sendMail} alt="Send mail" />
                    </a>
                    <a href="https://www.facebook.com/baydidau.vn" tooltip="Trang Facebook" className={`ws-contact-icon facebook ${isActiceContact ? 'not-active' : 'is-active'}`} target="_blank" rel="noreferrer">
                        <img src={facebook} alt="facebook" />
                    </a>
                    <a href="https://zalo.me/0787616118" tooltip="Chat Zalo" className={`ws-contact-icon zalo ${isActiceContact ? 'not-active' : 'is-active'}`} target="_blank" rel="noreferrer" >
                        <img src={zalo} alt="zalo" />
                    </a>
                    <a href="/#" tooltip="Thông tin liên hệ" className="ws-contact-icon btnn" onClick={handleClickShowComment}>
                        <img className="wh-comment" src={comment} alt="comment" />
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Layout;
