import React, { useEffect, useState } from "react";
import "./styles.css";
import noImage from "../../../wwwroot/images/no-img.jpg";
import Button from '../../components/Button'
import AnimatedPage from '../../components/animatedPage'
import {
    gs_datetime_yyyyMMddhhmmss_,
    gs_currencyFormatfixed0,
    gs_parseFloat,
} from "../../../wwwroot/js/GSFramescript";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faHouse, faTreeCity } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import ChangePageTitle from "../../components/Hook/ChangePageTitle";
import { useScrollRef } from "../../components/Hook/ScrollContext";

const getDate = () => {
    const today = new Date();
    const date = padTo2Digits(today.getDate());
    const month = padTo2Digits(today.getMonth() + 1);
    const year = today.getFullYear();
    return `${year}-${month}-${date}`;
}

const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
}

const getTourIdSession = () => {
    var tourIDSession = sessionStorage.getItem("tourId");
    return tourIDSession;
}

const getTourName = () => {
    return sessionStorage.getItem("tourName");
}

const getTourType = () => {
    return sessionStorage.getItem("tourType");
}

const getAreaCode = () => {
    return sessionStorage.getItem("areaCode");
}


const Service = () => {
    const history = useHistory();
    const [tourId, setTourId] = useState(getTourIdSession);
    const [tourDetail, setTourDetail] = useState({});
    const [tourDetailService, setTourDetailService] = useState([]);
    const [tourTypeCarService, setTourTypeCarService] = useState([]);
    const [tourPropertiesService, setTourPropertiesService] = useState([]);
    const [tourDataLocationService, setTourDataLocationService] = useState([]);
    const [tourDataAllService, setDataAllService] = useState([]);
    const [tourDetailImg, setTourDetailImg] = useState([]);
    const [tourSimilar, setTourSimilar] = useState([]);
    const [show, setShow] = useState(true);
    const [, setShowButtonBook] = useState(true);
    const [startDate, setStartDate] = useState(getDate());
    const [emailSupplier, setEmailSupplier] = useState("");
    const [codeSupplier, setCodeSupplier] = useState("");
    const [selectedTour, setSelectedTour] = useState('');
    const [selectedProperties, setSelectedProperties] = useState('');
    const [selectedCarType, setSelectedCarType] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [, setReRender] = useState(0);
    const scrollRef = useScrollRef();

    useEffect(() => {
        const inputSearch = document.querySelector('.header-login .input-box');
        inputSearch.classList.add('disable')
        return () => {
            inputSearch.classList.remove('disable')
        }
    }, []);

    useEffect(() => {
        if (tourId !== "") {
            sessionStorage.removeItem("listOrderTour");
            sessionStorage.removeItem("itemCarService");
            fetchDataTourDetail();
            fetchDataTourDetailService();
            fetchDataTypeCarService();
            fetchDataLocationService();
            fetchDataPropertiesService();
            fetchDataTourDetailImage();
            fetchDataTourDetailSimilar();
            fetchSupplier();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchDataTourDetail();
        fetchDataTourDetailService();
        fetchDataPropertiesService();
        fetchDataTypeCarService();
        fetchDataLocationService();
        fetchDataTourDetailImage();
        fetchDataTourDetailSimilar();
        fetchSupplier();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourId]);
    useEffect(() => {
        fetchAllDataService(selectedTour, selectedProperties, selectedCarType, selectedLocation)
        if (selectedTour !== "" && selectedProperties !== "" && selectedCarType !== "" && tourDataAllService.length > 0) {
            setShowButtonBook(false)
        } else {
            setShowButtonBook(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTour, selectedProperties, selectedCarType, selectedLocation])

    const handleChangeTour = event => {
        setSelectedTour(event.target.value);
    };
    const handleChangeProperties = event => {
        setSelectedProperties(event.target.value);
    };
    const handleChangeCarType = event => {
        setSelectedCarType(event.target.value);
    };
    const handleChangeLocation = event => {
        setSelectedLocation(event.target.value);
    };


    const getTourDetailSimilar = (tourId) => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        sessionStorage.setItem("tourId", tourId);
        setTourId(tourId);
    };

    const fetchDataTourDetail = async () => {
        var DocDate = gs_datetime_yyyyMMddhhmmss_(new Date(), "");

        try {
            const model = {
                StoreName: "Usp_SeachTour",
                Parametter: "@_DocDate1|@_BranchCode|@_TourId|@_OrderBy|@_Top|@_AreaCode|@_TourType|@_TourName|@_CTVCode",
                Value: DocDate + "|A01|" + tourId + "||1000||||"
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0][0];
            data ? setTourDetail(data) : setTourDetail({});
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchDataTourDetailImage = async () => {
        try {
            const model = {
                StoreName: "Usp_GetG20ExploreTour",
                Parametter: "@_Role|@_BranchCode|@_IsActive|@_HotelId",
                Value: "G20Image-Explorer|A01|1|" + tourId
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];

            data.length > 0 ? setTourDetailImg(data) : setTourDetailImg([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchDataTourDetailSimilar = async () => {
        var DocDate = gs_datetime_yyyyMMddhhmmss_(new Date(), "");
        var tourType = getTourType();
        var areaCode = getAreaCode();

        try {
            const model = {
                StoreName: "Usp_SeachTour",
                Parametter: "@_DocDate1|@_BranchCode|@_TourId|@_OrderBy|@_Top|@_AreaCode|@_TourType|@_TourName",
                Value: DocDate + "|A01|||1000|" + areaCode + "|" + tourType + "|"
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];

            data.length > 0 ? setTourSimilar(data) : setTourSimilar([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchSupplier = async () => {
        try {
            const model = {
                StoreName: "Usp_SupplierCodeRandom",
                Parametter: "@_Code",
                Value: tourId,
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0][0];
            data ? setEmailSupplier(data.Email) : setEmailSupplier("");
            data ? setCodeSupplier(data.Code) : setCodeSupplier("");

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleSubmit = (e, item) => {
        e.preventDefault();

        if (item.quantity > 0) {
            var orderTourArr = [];
            var orderTour = {};
            orderTour = {
                Stt: '',
                RowId: '',
                DocCode: 'BT',
                RoomType: tourId,
                Amount: item.Price * item.quantity,
                AmountSale: 0,
                Price: item.Price,
                AmountOld: item.Price * item.quantity,
                PercentDiscount: 0,
                DateIn: startDate,
                DateOut: startDate,
                Quantity: item.quantity,
                RoomDescription: item.Name + " " + item.CarType,
                HotelName: getTourName(), // tên dịch vụ/vé
                CTV: "", //đang lấy cứng
                Email: emailSupplier,
                SupplierCode: codeSupplier,
                TemPlateDetail: "",
                ShortDescription: "",
            };
            orderTourArr.push(orderTour);

            if (orderTourArr.length > 0) {
                sessionStorage.setItem("listOrderTour", JSON.stringify(orderTourArr));
                sessionStorage.setItem("itemCarService", JSON.stringify(item));
                history.push("/thanh-toan-tour");
            }
        } else {
            Swal.fire({
                title: "Đặt xe thất bại!",
                html: "Quý khách vui lòng nhập số lượng",
                icon: "error"
            });
        }


    }

    const fetchDataTourDetailService = async () => {
        try {
            const model = {
                StoreName: "Usp_SearchServiceCarType",
                Parametter: "@_Type",
                Value: "Name"
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];
            data ? setTourDetailService(data) : setTourDetailService({});
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchDataPropertiesService = async () => {
        try {
            const model = {
                StoreName: "Usp_SearchServiceCarType",
                Parametter: "@_Type",
                Value: "Type"
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];
            data ? setTourPropertiesService(data) : setTourPropertiesService([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchDataTypeCarService = async () => {
        try {
            const model = {
                StoreName: "Usp_SearchServiceCarType",
                Parametter: "@_Type",
                Value: "CarType"
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];
            data ? setTourTypeCarService(data) : setTourTypeCarService([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchDataLocationService = async () => {
        try {
            const model = {
                StoreName: "Usp_GetG20Explore",
                Parametter: "@_Role|@_BranchCode|@_IsActive",
                Value: "G20Area-Explorer|A01|1"
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];
            data ? setTourDataLocationService(data) : setTourDataLocationService([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchAllDataService = async (name = "", type = "", carType = " ", location = "") => {
        try {
            const model = {
                StoreName: "Usp_SearchServiceCar",
                Parametter: "@_Name|@_Type|@_CarType|@_Location",
                Value: name + '|' + type + '|' + carType + '|' + location
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();

            const data = dataResponse.dataend[0];
            var newArrData = data.map(item => ({ ...item, quantity: 0 }));
            data ? setDataAllService(newArrData) : setDataAllService([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleRedirectBreadcrumm = (e) => {
        e.preventDefault()
        history.push('/')
    }
    return (
        <AnimatedPage>
            <ChangePageTitle pageTitle="Thông tin dịch vụ" />
            <div className="order service">
                <div className="order-title">
                    <div className="container">
                        <div className="breadcrumb-custom">
                            <nav>
                                <ol className="cd-breadcrumb custom-separator">
                                    <li><a href="#" onClick={(e) => handleRedirectBreadcrumm(e)}><FontAwesomeIcon icon={faHouse} />&nbsp;Trang chủ</a></li>
                                    <li className="current"><em><FontAwesomeIcon icon={faTreeCity} />&nbsp;{tourDetail.TourName}</em></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <h1 className="order-title__heading">{getTourName()}</h1>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tz-gallery__checkout tour-detail">
                                <div className="photos-grid-container gallery">
                                    <div className="main-photo img-box">
                                        <img src={tourDetail.ImageLink ? "http://id.booknet.vn/" + tourDetail.ImageLink : noImage} alt={tourDetail.Stt} />
                                    </div>
                                    <div className="sub">
                                        {
                                            tourDetailImg.map((item, index) => {
                                                if (index < 4) {
                                                    return (
                                                        <div key={index} className="img-box">
                                                            <img src={item.Link ? `http://id.booknet.vn/${item.Link}` : noImage} alt={item.HotelCode} />
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-12 col-lg-9 col-xl-9">
                            <div ref={scrollRef} className="book-info service">
                                <center> <strong>Thông tin đặt xe</strong> </center>
                                <div className="form-info">
                                    <div className="form-horizontal">
                                        <div className="group-info">
                                            <div className="info-item">
                                                <label className="info-label">
                                                    <strong>Ngày đi</strong>
                                                </label>
                                                <input
                                                    className="input-cta__date form-control"
                                                    type="date"
                                                    name="departure"
                                                    value={startDate}
                                                    min={new Date().toJSON().slice(0, 10)}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </div>
                                            <div className="info-item">
                                                <label htmlFor="startDate" className="info-label">
                                                    <strong>Lịch trình</strong>
                                                </label>
                                                <select className="form-control" id="startDate" onChange={handleChangeTour}>
                                                    <option value="">Chọn lịch trình</option>
                                                    {
                                                        tourDetailService.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.Name}> {item.Name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="info-item">
                                                <label htmlFor="startDate2" className="info-label">
                                                    <strong>Thuộc tính</strong>
                                                </label>
                                                <select className="form-control" id="startDate2" onChange={handleChangeProperties}>
                                                    <option value="">Chọn thuộc tính</option>
                                                    {
                                                        tourPropertiesService.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.Type}> {item.Type}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="info-item">
                                                <label htmlFor="startDate2" className="info-label">
                                                    <strong>Loại xe</strong>
                                                </label>
                                                <select className="form-control" id="startDate2" onChange={handleChangeCarType}>
                                                    <option value="">Chọn loại xe</option>
                                                    {
                                                        tourTypeCarService.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.CarType}> {item.CarType}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="info-item hide">
                                                <label htmlFor="startDate2" className="info-label">
                                                    <strong>Địa điểm</strong>
                                                </label>
                                                <select className="form-control" id="startDate2" onChange={handleChangeLocation}>
                                                    <option value="">Chọn địa điểm</option>
                                                    {
                                                        tourDataLocationService.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.Code}> {item.Name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="order-info__content">
                                <div className="order-info__content-head">
                                    <span>Thông tin đặt xe</span>
                                    <span
                                        className="order-info__content-head--btn"
                                        onClick={() => setShow(!show)}
                                    >
                                        <FontAwesomeIcon icon={faRightToBracket} />
                                    </span>
                                </div>
                                <div
                                    className={`order-info__content-main ${show ? "show" : "hide"
                                        }`}
                                >
                                    <div className="content-text">
                                        <div className="panel-body" style={{ overflowX: "auto" }}>
                                            <ul className="list">
                                                {tourDataAllService.length > 0 ? tourDataAllService.map((item, index) => (
                                                    <li key={index} className="work">
                                                        <span className="title">Lịch trình:&nbsp; {item.Name}</span>
                                                        <span className="subtitle">Loại xe:&nbsp;{item.CarType}</span>
                                                        <span className="subtitle">Thuộc tính:&nbsp;{item.Type}</span>
                                                        <span className="subtitle">Địa điểm:&nbsp;{item.LocationName}</span>
                                                        <div className="info-item__block service">
                                                            <label className="info-label">
                                                                <strong>Số lượng</strong>
                                                            </label>
                                                            <div className="d-flex">
                                                                <span
                                                                    className="input-cta input-number-decrement"
                                                                    onClick={() => {
                                                                        tourDataAllService[index].quantity = Math.max(0, tourDataAllService[index].quantity - 1);
                                                                        setReRender(prev => prev + 1);
                                                                    }}
                                                                >
                                                                    -
                                                                </span>
                                                                <input
                                                                    className="input-number input-cta"
                                                                    type="text"
                                                                    name="quantity"
                                                                    value={item.quantity}
                                                                    onChange={() => { }}
                                                                />
                                                                <span
                                                                    className="input-cta input-number-increment"
                                                                    onClick={() => {
                                                                        tourDataAllService[index].quantity = tourDataAllService[index].quantity + 1
                                                                        setReRender(prev => prev + 1);
                                                                    }}
                                                                >
                                                                    +
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="info-item">
                                                            <span className="subtitle subtitle-price">Giá:&nbsp;{gs_currencyFormatfixed0(gs_parseFloat(item.Price))} VND</span>

                                                            <Button title="Đặt xe" BTsearchbk onClick={(e) => handleSubmit(e, item)} classNameCustom={"btn-book"} />
                                                        </div>
                                                    </li>
                                                )) : <h4>Không có dữ liệu</h4>}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-12 col-xl-3 col-xs-12">
                            <div className="tour-order col-md-12">
                                <div className="order-info__similar-head">
                                    <strong>Tương tự khác</strong>
                                </div>
                                {
                                    tourSimilar.map((item, index) => {
                                        if (index < 5) {
                                            return (
                                                <div key={index} className="item-order" onClick={() => getTourDetailSimilar(item.Stt)}>
                                                    <div className="similar-item-box">
                                                        <img src={item.ImageLink ? "http://id.booknet.vn/" + item.ImageLink : noImage} alt={item.HotelCode} />
                                                    </div>
                                                    <div className="div_diadiem_link">
                                                        <h5 className="mihawk-list-hotel">
                                                            {item.TourName}
                                                        </h5>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return null;
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </AnimatedPage >
    )
}

export default Service;
