import React, { useEffect, useState } from "react";
import "./styles.css";
import noImage from "../../../wwwroot/images/no-img.jpg";
import Button from '../../components/Button'
import AnimatedPage from '../../components/animatedPage'
import {
    gs_datetime_yyyyMMddhhmmss_,
    gs_currencyFormatfixed0,
    gs_parseFloat,
    getCookie
} from "../../../wwwroot/js/GSFramescript";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faTreeCity, faHouse } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import ChangePageTitle from "../../components/Hook/ChangePageTitle";
import { useScrollRef } from "../../components/Hook/ScrollContext";

const getInfoCTVCookies = getCookie("CodeCTV") ? getCookie("CodeCTV") : "";

const getDate = () => {
    const today = new Date();
    const date = padTo2Digits(today.getDate());
    const month = padTo2Digits(today.getMonth() + 1);
    const year = today.getFullYear();
    return `${year}-${month}-${date}`;
}

const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
}

const getTourIdSession = () => {
    var tourIDSession = sessionStorage.getItem("tourId");
    return tourIDSession;
}

const getTourType = () => {
    return sessionStorage.getItem("tourType");
}

const getAreaCode = () => {
    return sessionStorage.getItem("areaCode");
}

const TourDetail = () => {
    const history = useHistory();
    const [tourId, setTourId] = useState(getTourIdSession);
    const [tourDetail, setTourDetail] = useState({});
    const [tourDetailImg, setTourDetailImg] = useState([]);
    const [tourSimilar, setTourSimilar] = useState([]);
    const [show, setShow] = useState(true);
    const [numAdult, setNumAdult] = useState(1);
    const [numChild, setNumChild] = useState(0);
    const [numBaby, setNumBaby] = useState(0);
    const [startDate, setStartDate] = useState(getDate());
    const [infoSupplier, setInfoSupplier] = useState({});
    const [checkTourType, setCheckTourType] = useState(false);
    const scrollRef = useScrollRef();

    useEffect(() => {
        const inputSearch = document.querySelector('.header-login .input-box');
        inputSearch.classList.add('disable')
        return () => {
            inputSearch.classList.remove('disable')
        }
    }, []);

    useEffect(() => {
        if (tourId !== "") {
            sessionStorage.removeItem("listOrderTour");
            sessionStorage.removeItem("itemCarService");
            fetchDataTourDetail();
            fetchDataTourDetailImage();
            fetchDataTourDetailSimilar();
            fetchSupplier();
            if (getTourType() === "Sản phẩm Du lịch") {
                setCheckTourType(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchDataTourDetail();
        fetchDataTourDetailImage();
        fetchDataTourDetailSimilar();
        fetchSupplier();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourId]);

    const getTourDetailSimilar = (tourId) => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        sessionStorage.setItem("tourId", tourId);
        setTourId(tourId);
    };

    const fetchDataTourDetail = async () => {
        var DocDate = gs_datetime_yyyyMMddhhmmss_(new Date(), "");

        try {
            var model = {}
            if (getInfoCTVCookies && getInfoCTVCookies != null) {
                model = {
                    StoreName: "Usp_SeachTour",
                    Parametter: "@_DocDate1|@_BranchCode|@_TourId|@_OrderBy|@_Top|@_AreaCode|@_TourType|@_TourName|@_CTVCode",
                    Value: DocDate + "|A01|" + tourId + "||1000||||" + getInfoCTVCookies
                };
            } else {
                model = {
                    StoreName: "Usp_SeachTour",
                    Parametter: "@_DocDate1|@_BranchCode|@_TourId|@_OrderBy|@_Top|@_AreaCode|@_TourType|@_TourName|@_CTVCode",
                    Value: DocDate + "|A01|" + tourId + "||1000||||"
                };
            }

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0][0];
            data ? setTourDetail(data) : setTourDetail({});
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchDataTourDetailImage = async () => {
        try {
            const model = {
                StoreName: "Usp_GetG20ExploreTour",
                Parametter: "@_Role|@_BranchCode|@_IsActive|@_HotelId",
                Value: "G20Image-Explorer|A01|1|" + tourId
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];

            data.length > 0 ? setTourDetailImg(data) : setTourDetailImg([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchDataTourDetailSimilar = async () => {
        var DocDate = gs_datetime_yyyyMMddhhmmss_(new Date(), "");
        var tourType = getTourType();
        var areaCode = getAreaCode();

        try {
            const model = {
                StoreName: "Usp_SeachTour",
                Parametter: "@_DocDate1|@_BranchCode|@_TourId|@_OrderBy|@_Top|@_AreaCode|@_TourType|@_TourName",
                Value: DocDate + "|A01|||1000|" + areaCode + "|" + tourType + "|"
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];

            data.length > 0 ? setTourSimilar(data) : setTourSimilar([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchSupplier = async () => {
        try {
            const model = {
                StoreName: "Usp_SupplierCodeRandom",
                Parametter: "@_Code",
                Value: tourId,
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];
            data ? setInfoSupplier(data) : setInfoSupplier({});
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var orderTourArr = [];
        var orderTour = {};
        if (getTourType() === "Sản phẩm Du lịch") {
            if (numAdult > 0) {
                orderTour = {
                    Stt: '',
                    RowId: '',
                    DocCode: 'BT',
                    RoomType: tourId,
                    Amount: getInfoCTVCookies && getInfoCTVCookies !== null && tourDetail.PriceCTV > 0 ? numAdult * tourDetail.PriceCTV : numAdult * tourDetail.Price,
                    AmountSale: 0,
                    Price: getInfoCTVCookies && getInfoCTVCookies !== null && tourDetail.PriceCTV > 0 ? tourDetail.PriceCTV : tourDetail.Price,
                    AmountOld: getInfoCTVCookies && getInfoCTVCookies !== null && tourDetail.PriceCTV > 0 ? numAdult * tourDetail.PriceCTV : numAdult * tourDetail.Price,
                    PercentDiscount: 0,
                    DateIn: startDate,
                    DateOut: startDate,
                    Quantity: numAdult,
                    RoomDescription: "Sản phẩm",
                    HotelName: tourDetail.TourName,
                    CTV: "", //đang lấy cứng
                    SupplierEmail: infoSupplier.Email ? infoSupplier.Email : "",
                    SupplierCode: infoSupplier.Code ? infoSupplier.Code : "",
                    SupplierName: infoSupplier.Name ? infoSupplier.Name : "",
                    TemPlateDetail: tourDetail.Description,
                    ShortDescription: "",
                };
                orderTourArr.push(orderTour);
                if (orderTourArr.length > 0) {
                    sessionStorage.setItem("listOrderTour", JSON.stringify(orderTourArr));
                    history.push("/thanh-toan-tour");
                }
            } else {
                Swal.fire({
                    title: "Đặt sản phẩm thất bại!",
                    text: "Bạn chưa nhập số lượng!",
                    icon: "error"
                });
            }
        } else {
            if (numAdult > 0 || numChild > 0) {
                if (numAdult > 0) {
                    orderTour = {
                        Stt: '',
                        RowId: '',
                        DocCode: 'BT',
                        RoomType: tourId,
                        Amount: getInfoCTVCookies && getInfoCTVCookies !== null && tourDetail.PriceCTV > 0 ? numAdult * tourDetail.PriceCTV : numAdult * tourDetail.Price,
                        AmountSale: 0,
                        Price: getInfoCTVCookies && getInfoCTVCookies !== null && tourDetail.PriceCTV > 0 ? tourDetail.PriceCTV : tourDetail.Price,
                        AmountOld: getInfoCTVCookies && getInfoCTVCookies !== null && tourDetail.PriceCTV > 0 ? numAdult * tourDetail.PriceCTV : numAdult * tourDetail.Price,
                        PercentDiscount: 0,
                        DateIn: startDate,
                        DateOut: startDate,
                        Quantity: numAdult,
                        RoomDescription: 'Người lớn',
                        HotelName: tourDetail.TourName,
                        CTV: "",
                        SupplierEmail: infoSupplier.Email ? infoSupplier.Email : "",
                        SupplierCode: infoSupplier.Code ? infoSupplier.Code : "",
                        SupplierName: infoSupplier.Name ? infoSupplier.Name : "",
                        TemPlateDetail: tourDetail.Description,
                        ShortDescription: "",
                    };
                    orderTourArr.push(orderTour);
                }

                if (numChild > 0) {
                    orderTour = {
                        Stt: '',
                        RowId: '',
                        DocCode: 'BT',
                        RoomType: tourId,
                        Amount: getInfoCTVCookies && getInfoCTVCookies !== null && tourDetail.PriceChilderCTV > 0 ? numChild * tourDetail.PriceChilderCTV : numChild * tourDetail.PriceChilder,
                        AmountSale: 0,
                        Price: getInfoCTVCookies && getInfoCTVCookies !== null && tourDetail.PriceChilderCTV > 0 ? tourDetail.PriceChilderCTV : tourDetail.PriceChilder,
                        AmountOld: getInfoCTVCookies && getInfoCTVCookies !== null && tourDetail.PriceChilderCTV > 0 ? numChild * tourDetail.PriceChilderCTV : numChild * tourDetail.PriceChilder,
                        PercentDiscount: 0,
                        DateIn: startDate,
                        DateOut: startDate,
                        Quantity: numChild,
                        RoomDescription: 'Trẻ em',
                        HotelName: tourDetail.TourName,
                        CTV: "",
                        SupplierEmail: infoSupplier.Email ? infoSupplier.Email : "",
                        SupplierCode: infoSupplier.Code ? infoSupplier.Code : "",
                        SupplierName: infoSupplier.Name ? infoSupplier.Name : "",
                        TemPlateDetail: tourDetail.Description,
                        ShortDescription: "",
                    };
                    orderTourArr.push(orderTour);
                }

                if (numBaby > 0) {
                    orderTour = {
                        Stt: '',
                        RowId: '',
                        DocCode: 'BT',
                        RoomType: tourId,
                        Amount: getInfoCTVCookies && getInfoCTVCookies !== null && tourDetail.PriceInfantCTV > 0 ? numBaby * tourDetail.PriceInfantCTV : numBaby * tourDetail.PriceInfant,
                        AmountSale: 0,
                        Price: getInfoCTVCookies && getInfoCTVCookies !== null && tourDetail.PriceInfantCTV > 0 ? tourDetail.PriceInfantCTV : tourDetail.PriceInfant,
                        AmountOld: getInfoCTVCookies && getInfoCTVCookies !== null && tourDetail.PriceInfantCTV > 0 ? numBaby * tourDetail.PriceInfantCTV : numBaby * tourDetail.PriceInfant,
                        PercentDiscount: 0,
                        DateIn: startDate,
                        DateOut: startDate,
                        Quantity: numBaby,
                        RoomDescription: 'Em bé',
                        HotelName: tourDetail.TourName,
                        CTV: "",
                        SupplierEmail: infoSupplier.Email ? infoSupplier.Email : "",
                        SupplierCode: infoSupplier.Code ? infoSupplier.Code : "",
                        SupplierName: infoSupplier.Name ? infoSupplier.Name : "",
                        TemPlateDetail: tourDetail.Description,
                        ShortDescription: "",
                    };
                    orderTourArr.push(orderTour);
                }

                if (orderTourArr.length > 0) {
                    sessionStorage.setItem("listOrderTour", JSON.stringify(orderTourArr));
                    history.push("/thanh-toan-tour");
                }
            } else {
                Swal.fire({
                    title: "Đặt vé thất bại",
                    text: "Cần có những người lớn đi theo chịu trách nhiệm!",
                    icon: "error"
                });
            }
        }

    };

    var total;
    if (getInfoCTVCookies && getInfoCTVCookies != null) {
        if (tourDetail.PriceCTV > 0) {
            if (tourDetail.PriceChilderCTV > 0) {
                if (tourDetail.PriceInfantCTV > 0) {
                    total = gs_currencyFormatfixed0(gs_parseFloat(numAdult * tourDetail.PriceCTV + numChild * tourDetail.PriceChilderCTV + numBaby * tourDetail.PriceInfantCTV))
                } else {
                    total = gs_currencyFormatfixed0(gs_parseFloat(numAdult * tourDetail.PriceCTV + numChild * tourDetail.PriceChilderCTV + numBaby * tourDetail.PriceInfant))
                }
            } else {
                total = gs_currencyFormatfixed0(gs_parseFloat(numAdult * tourDetail.PriceCTV + numChild * tourDetail.PriceChilder + numBaby * tourDetail.PriceInfant))
            }
        } else {
            total = gs_currencyFormatfixed0(gs_parseFloat(numAdult * tourDetail.Price + numChild * tourDetail.PriceChilder + numBaby * tourDetail.PriceInfant))
        }
    } else {
        total = gs_currencyFormatfixed0(gs_parseFloat(numAdult * tourDetail.Price + numChild * tourDetail.PriceChilder + numBaby * tourDetail.PriceInfant))
    }
    const handleRedirectBreadcrumm = (e) => {
        e.preventDefault()
        history.push('/')
    }
    return (
        <AnimatedPage>
            <ChangePageTitle pageTitle="Thông tin vé" />
            <div className="order tour-detail">
                <div className="order-title">
                    <div className="container">
                        <div className="breadcrumb-custom">
                            <nav>
                                <ol className="cd-breadcrumb custom-separator">
                                    <li><a href="#/" onClick={(e) => handleRedirectBreadcrumm(e)}><FontAwesomeIcon icon={faHouse} />&nbsp;Trang chủ</a></li>
                                    <li className="current"><em><FontAwesomeIcon icon={faTreeCity} />&nbsp;{tourDetail.TourName}</em></li>
                                </ol>
                            </nav>
                        </div>
                        <h1 className="order-title__heading">{tourDetail.TourName}</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tz-gallery__checkout tour-detail">
                                <div className="photos-grid-container gallery">
                                    <div className="main-photo img-box">
                                        <img src={tourDetail.ImageLink ? "http://id.booknet.vn/" + tourDetail.ImageLink : noImage} alt={tourDetail.Stt} />
                                    </div>
                                    <div className="sub">
                                        {
                                            tourDetailImg.map((item, index) => {
                                                if (index < 4) {
                                                    return (
                                                        <div key={index} className="img-box">
                                                            <img src={item.Link ? `http://id.booknet.vn/${item.Link}` : noImage} alt={item.HotelCode} />
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })
                                        }
                                        {/*<div className="img-box">*/}

                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-12 col-lg-9 col-xl-9">
                            {
                                !checkTourType &&
                                <div ref={scrollRef} className="book-info">
                                    <center> <strong>Thông tin đặt vé</strong> </center>
                                    <div className="form-info">
                                        <div className="form-horizontal">
                                            <div className="group-info">
                                                <div className="info-item">
                                                    <label className="info-label">
                                                        <strong>Ngày khởi hành</strong>
                                                    </label>
                                                    <input
                                                        className="input-cta__date"
                                                        type="date"
                                                        name="departure"
                                                        value={startDate}
                                                        min={new Date().toJSON().slice(0, 10)}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                                <div className="info-item info-select d-flex">
                                                    <div className="info-item__block">
                                                        <label className="info-label">
                                                            <strong>Người lớn</strong>
                                                        </label>
                                                        <div className="d-flex">
                                                            <span
                                                                className="input-cta input-number-decrement"
                                                                onClick={() =>
                                                                    setNumAdult(!numAdult * 1 === 0 ? numAdult - 1 : 0)
                                                                }
                                                            >
                                                                -
                                                            </span>
                                                            <input
                                                                className="input-number input-cta"
                                                                type="text"
                                                                name="adult"
                                                                value={numAdult}
                                                                onChange={() => { }}
                                                            />
                                                            <span
                                                                className="input-cta input-number-increment"
                                                                onClick={() => setNumAdult(numAdult + 1)}
                                                            >
                                                                +
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="info-item__block">
                                                        <label className="info-label">
                                                            <strong>Trẻ em</strong>
                                                        </label>
                                                        <div className="d-flex">
                                                            <span
                                                                className="input-cta input-number-decrement"
                                                                onClick={() =>
                                                                    setNumChild(!numChild * 1 === 0 ? numChild - 1 : 0)
                                                                }
                                                            >
                                                                -
                                                            </span>
                                                            <input
                                                                className="input-number input-cta"
                                                                type="text"
                                                                name="children"
                                                                value={numChild}
                                                                onChange={() => { }}
                                                            />
                                                            <span
                                                                className="input-cta input-number-increment"
                                                                onClick={() => setNumChild(numChild + 1)}
                                                            >
                                                                +
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="info-item__block">
                                                        <label className="info-label">
                                                            <strong>Em bé</strong>
                                                        </label>
                                                        <div className="d-flex">
                                                            <span
                                                                className="input-cta input-number-decrement"
                                                                onClick={() =>
                                                                    setNumBaby(!numBaby * 1 === 0 ? numBaby - 1 : 0)
                                                                }
                                                            >
                                                                -
                                                            </span>
                                                            <input
                                                                className="input-number input-cta"
                                                                type="text"
                                                                name="baby"
                                                                value={numBaby}
                                                                onChange={() => { }}
                                                            />
                                                            <span
                                                                className="input-cta input-number-increment"
                                                                onClick={() => setNumBaby(numBaby + 1)}
                                                            >
                                                                +
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info-item">
                                                    <label className="control-label info-label">&nbsp;</label>
                                                    <Button title="Đặt vé" BTsearchbk onClick={handleSubmit} classNameCustom="btn-book" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info-footer">
                                        <div className="info-price">
                                            <strong>Giá : </strong>
                                            {getInfoCTVCookies && getInfoCTVCookies != null && tourDetail.PriceCTV > 0 ? gs_currencyFormatfixed0(gs_parseFloat(tourDetail.PriceCTV * 1)) : gs_currencyFormatfixed0(gs_parseFloat(tourDetail.Price * 1))} VND
                                        </div>
                                        <div className="info-price__total">
                                            <strong>Tổng tiền : </strong>
                                            {total}
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                checkTourType &&
                                <div ref={scrollRef} className="book-info">
                                    <center> <strong>Thông tin đặt sản phẩm</strong> </center>
                                    <div className="form-info">
                                        <div className="form-horizontal">
                                            <div className="group-info group-info__product">
                                                <div className="info-item">
                                                    <label className="info-label">
                                                        <strong>Nhập số lượng sản phẩm</strong>
                                                    </label>
                                                    <div className="d-flex">
                                                        <span
                                                            className="input-cta input-number-decrement"
                                                            onClick={() =>
                                                                setNumAdult(!numAdult * 1 === 0 ? numAdult - 1 : 0)
                                                            }
                                                        >
                                                            -
                                                        </span>
                                                        <input
                                                            className="input-number input-cta"
                                                            type="text"
                                                            name="adult"
                                                            value={numAdult}
                                                            onChange={() => { }}
                                                        />
                                                        <span
                                                            className="input-cta input-number-increment"
                                                            onClick={() => setNumAdult(numAdult + 1)}
                                                        >
                                                            +
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="info-item">
                                                    <label className="control-label info-label">&nbsp;</label>
                                                    <Button title=" Đặt hàng" BTsearchbk onClick={handleSubmit} classNameCustom="btn-book" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info-footer">
                                        <div className="info-price">
                                            <strong>Giá : </strong>
                                            {getInfoCTVCookies && getInfoCTVCookies != null && tourDetail.PriceCTV > 0 ? gs_currencyFormatfixed0(gs_parseFloat(tourDetail.PriceCTV * 1)) : gs_currencyFormatfixed0(gs_parseFloat(tourDetail.Price * 1))} VND
                                        </div>
                                        <div className="info-price__total">
                                            <strong>Tổng tiền : </strong>
                                            {total}
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="order-info__content">
                                <div className="order-info__content-head">
                                    <span>{checkTourType ? "Thông tin chi tiết sản phẩm" : "Thông tin chi tiết"}</span>
                                    <span
                                        className="order-info__content-head--btn"
                                        onClick={() => setShow(!show)}
                                    >
                                        <FontAwesomeIcon icon={faRightToBracket} />
                                    </span>
                                </div>
                                <div
                                    className={`order-info__content-main ${show ? "show" : "hide"
                                        }`}
                                >
                                    <div className="content-text">
                                        <div className="panel-body" style={{ overflowX: "auto" }}
                                            dangerouslySetInnerHTML={{ __html: tourDetail.Description }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-12 col-xl-3 col-xs-12">
                            <div className="tour-order col-md-12">
                                <div className="order-info__similar-head">
                                    <strong>Tương tự khác</strong>
                                </div>
                                {
                                    tourSimilar.map((item, index) => {
                                        if (index < 6 && item.Stt !== tourDetail.Stt) {
                                            return (
                                                <div key={index} className="item-order" onClick={() => getTourDetailSimilar(item.Stt)}>
                                                    <div className="similar-item-box">
                                                        <img src={item.ImageLink ? "http://id.booknet.vn/" + item.ImageLink : noImage} alt={item.HotelCode} />
                                                    </div>
                                                    <div className="div_diadiem_link">
                                                        <h5 className="mihawk-list-hotel">
                                                            {item.TourName}
                                                        </h5>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return null;
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </AnimatedPage>
    )
}

export default TourDetail;
