import React, { useState, useEffect } from "react";
import "./styles.css";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const Register = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [captCha, setCaptCha] = useState("");
    const regPhone = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
    const regEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const history = useHistory();

    useEffect(() => {
        const inputSearch = document.querySelector('.header-login .input-box');
        inputSearch.classList.add('disable')
        return () => {
            inputSearch.classList.remove('disable')
        }
    }, [])

    const onSubmit = async (data) => {
        if (captCha) {
            try {
                const model = {
                    StoreName: "Usp_G20EmployeeInfoUpdateInsert",
                    Parametter: "@_key|@_Stt|@_Name|@_Address|@_NumberId|@_Email|@_Phone|@_SellPhone|@_Description|@_IsActive",
                    Value: "I||" + data.name + "|" + data.address + "||" + data.email + "|" + data.phone + "|" + data.phone + "||1"
                };

                const queryString = Object.keys(model)
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                    .join("&");

                const response = await fetch(`/HomeControllercs/InsertUpdate?${queryString}`);
                const dataResponse = await response.json();
                if (dataResponse.Message.includes("E404")) {
                    Swal.fire({
                        title: "Đăng ký thất bại!",
                        text: dataResponse.Message.split('|')[0],
                        icon: "error"
                    });
                }
                else {
                    try {
                        const model = {
                            Stt: dataResponse.stingid,
                            Ctv: data.name,
                            PromtionCode: "",
                            EmailCTV: data.email,
                        };

                        const queryString = Object.keys(model)
                            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                            .join("&");

                        const response = await fetch(`/HomeControllercs/SendEmailCTVRegister?${queryString}`);
                        await response.json();
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }

                    try {
                        const modelowner = {
                            Name: data.name,
                            SellPhone: data.phone,
                            Email: data.email,
                            Address: data.address,
                        };

                        const queryString = Object.keys(modelowner)
                            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                            .join("&");

                        const response = await fetch(`/HomeControllercs/SendEmailOwnerRegister?${queryString}`);
                        await response.json();
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Đăng ký thành công!",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setTimeout(() => {
                        history.push("/");
                    }, 3000);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        } else {
            Swal.fire({
                title: "Đăng ký thất bại!",
                text: "Phiền bạn xác thực capcha để tiếp tục!",
                icon: "error"
            });
        }
    };

    const onChange = (value) => {
        setCaptCha(value);
    }

    return (
        <div className="home-body register">
            <div className="container mt-4">
                <div className="row">
                    <div className="col-lg-6">
                        <iframe width="100%" height="506" src="https://www.youtube.com/embed/UXMpdsUgzZg?si=ysLlkqgq_PMm2giQ" title="Cộng tác viên Online giới thiệu LINK và QR Code đặt vé máy bay" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                    <div className="col-lg-6">
                        <div className="panel panel-info">
                            <div className="panel-heading">
                                Đăng ký cộng tác viên
                                <span className="tools pull-right"></span>
                            </div>
                            <div className="panel-body">
                                <div className="hide">
                                    <button type="button" className="close close-sm" data-dismiss="alert">
                                        <i className="fa fa-times" />
                                    </button>
                                    <h4>
                                        <i className="icon-ok-sign" />
                                        Thất bại!
                                    </h4>
                                    <p>Phiền bạn xác thực capcha để tiếp tục</p>
                                </div>
                                <form
                                    className="form-horizontal"
                                >
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-lg-3 col-sm-2 control-label text-left text-lg-right"
                                        >
                                            Tên
                                        </label>
                                        <div className="col-lg-9 form-input">
                                            <input
                                                id="inputName"
                                                type="text"
                                                className="form-control"
                                                name="Name"
                                                placeholder="Họ và tên"
                                                defaultValue=""
                                                required
                                                autoComplete="off"
                                                {...register("name", {
                                                    required: {
                                                        value: true,
                                                        message: "Họ tên không được để trống",
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: "Tên phải nhiều hơn 3 kí tự",
                                                    },
                                                })}
                                            />
                                            {!errors?.name?.message || (
                                                <span className="form__error">
                                                    {errors?.name?.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputAddress"
                                            className="col-lg-3 col-sm-2 control-label text-left text-lg-right"
                                        >
                                            Địa chỉ
                                        </label>
                                        <div className="col-lg-9 form-input">
                                            <input
                                                id="inputAddress"
                                                type="text"
                                                className="form-control"
                                                name="Address"
                                                placeholder="Địa chỉ"
                                                defaultValue=""
                                                required
                                                autoComplete="off"
                                                {...register("address", {
                                                    required: {
                                                        value: true,
                                                        message: "Địa chỉ không được để trống",
                                                    }
                                                })}
                                            />
                                            {!errors?.address?.message || (
                                                <span className="form__error">
                                                    {errors?.address?.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputEmail"
                                            className="col-lg-3 col-sm-2 control-label text-left text-lg-right"
                                        >
                                            Email
                                        </label>
                                        <div className="col-lg-9 form-input">
                                            <input
                                                id="inputEmail"
                                                type="email"
                                                className="form-control"
                                                name="Email"
                                                placeholder="Email"
                                                defaultValue=""
                                                required
                                                {...register("email", {
                                                    required: {
                                                        value: true,
                                                        message: "Email không được để trống",
                                                    },
                                                    pattern: {
                                                        value: regEmail,
                                                        message: "Email không hợp lệ",
                                                    },
                                                })}
                                            />
                                            {!errors?.email?.message || (
                                                <span className="form__error">
                                                    {errors?.email?.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputPhone"
                                            className="col-lg-3 col-sm-2 control-label text-left text-lg-right"
                                        >
                                            Số điện thoại
                                        </label>
                                        <div className="col-lg-9 form-input">
                                            <input
                                                id="inputPhone"
                                                type="tel"
                                                className="form-control"
                                                name="SellPhone"
                                                placeholder="Số điện thoại"
                                                defaultValue=""
                                                required
                                                autoComplete="off"
                                                {...register("phone", {
                                                    required: {
                                                        value: true,
                                                        message: "Số điện thoại không được để trống",
                                                    },
                                                    pattern: {
                                                        value: regPhone,
                                                        message: "Số điện thoại không hợp lệ",
                                                    },
                                                })}
                                            />
                                            {!errors?.phone?.message || (
                                                <span className="form__error">
                                                    {errors?.phone?.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            className="col-lg-3 col-sm-2 control-label text-left text-lg-right"
                                        />
                                        <div className="col-lg-9">
                                            <ReCAPTCHA
                                                sitekey="6LcX9Q8pAAAAACXknafUOLgA5Q46P_ksRMCmRQom"
                                                onChange={onChange}
                                            />,
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="offset-lg-3 col-lg-10">
                                            <button type="button" id="Send" className="btn btn-danger" onClick={handleSubmit(onSubmit)}>
                                                Gửi
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Register;
