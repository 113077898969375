import React, { useEffect, useState } from "react";
import "./styles.css";
import { useForm } from "react-hook-form";
import {
    gs_currencyFormatfixed0,
    gs_parseFloat,
    gs_datetime_yyyyMMddhhmm_,
    getCookie
} from "../../../wwwroot/js/GSFramescript";
import AnimatedPage from "../../components/animatedPage";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import ChangePageTitle from "../../components/Hook/ChangePageTitle";
import ModalLucky from '../../components/ModalLucky';
import Button from '../../components/Button';
import momo from "../../../wwwroot/images/MoMo_Logo.png";
import Zalo from "../../../wwwroot/images/Zalo-pay.png";
import PaymentIcon from "../../../wwwroot/images/payment_icons.png";
import VNPay from "../../../wwwroot/images/vnpayicons.png";
import Loading from "../../components/Loading/Index";

const listOrderTour = () => {
    return JSON.parse(sessionStorage.getItem("listOrderTour"));
};

const itemCarService = () => {
    return JSON.parse(sessionStorage.getItem("itemCarService"));
};

const formatDate = (input) => {
    const daysOfWeek = ["Chủ Nhật", "Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"];
    const monthsOfYear = ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"];

    const dateParts = input.split("-");
    const year = dateParts[0];
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);

    const dayOfWeekIndex = new Date(input).getDay();

    const formattedDate = `${daysOfWeek[dayOfWeekIndex]}, ngày ${day} ${monthsOfYear[month - 1]} năm ${year}`;
    return formattedDate;
}

const Payment = () => {
    const history = useHistory();
    var listOrderTourPayment = listOrderTour() ? listOrderTour() : [];

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [total, setTotal] = useState(0);
    const [voucher, setVoucher] = useState(0);
    const [totalAll, setTotalAll] = useState(0);
    const [codeVoucher, setCodeVoucher] = useState("");
    const [bookingNewCode, setBookingNewCode] = useState("");
    const [infoCus, setInfoCus] = useState({});
    const [showGetVoucher, setShowGetVoucher] = useState(true);
    const [orderConfirm, setOrderConfirm] = useState(true);
    const [, setIsActiveButtonPayment] = useState(true);
    const [isOpenCode, setIsOpenCode] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [checkShowPayment, setCheckShowPayment] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [infoCTVCode, setInfoCTVCode] = useState("");

    const regPhone = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
    const regEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const listOrderTourFirst = sessionStorage.getItem('listOrderTour');
    const itemCarServiceFirst = sessionStorage.getItem('itemCarService');

    if (!listOrderTourFirst && !itemCarServiceFirst) {
        history.push("/error")
    }

    useEffect(() => {
        const inputSearch = document.querySelector('.header-login .input-box');
        inputSearch.classList.add('disable')
        return () => {
            inputSearch.classList.remove('disable')
        }
    }, []);

    useEffect(() => {
        var totalStart = 0;
        listOrderTourPayment.forEach((item) => {
            totalStart += item.Amount;
        });
        setTotal(totalStart);
        setInfoCTVCode(getCookie("CodeCTV"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTotalAll(total + total * parseInt(process.env.REACT_APP_TAX) / 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total]);

    const getVoucher = async () => {
        setShowGetVoucher(false);
        setIsOpenCode(true)
        try {
            const model = {
                StoreName: "Usp_AutoGetPromotionCodeWH",
                Parametter: "@_Name|@_Amount",
                Value: "Khách lẻ|10000",
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0][0];
            data ? setCodeVoucher(data.Code) : setCodeVoucher("");
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        checkDiscountCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeVoucher])

    const handleCheckVoucher = (e) => {
        setCodeVoucher(e.target.value);
    }

    const checkDiscountCode = async () => {
        if (codeVoucher) {
            try {
                const model = {
                    StoreName: "Usp_CheckPromotionCode",
                    Parametter: "@_Code",
                    Value: codeVoucher,
                };

                const queryString = Object.keys(model)
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                    .join("&");

                const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
                const dataResponse = await response.json();
                const data = dataResponse.dataend[0][0];
                if (data.Amount > 0) {
                    data ? setVoucher(data.Amount) : setVoucher(0);
                } else {
                    Swal.fire({
                        title: "Mã này đã được sử dụng",
                        icon: "error"
                    });
                    setVoucher(0);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    useEffect(() => {
        setTotalAll(total + total * parseInt(process.env.REACT_APP_TAX) / 100 - voucher);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voucher]);

    const CreatedBookingNewCode = async () => {
        try {
            const response = await fetch(`/HomeControllercs/ExecuteStoredProc`);
            const dataResponse = await response.json();
            setBookingNewCode(dataResponse.resultCode);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (bookingNewCode) {
            insertTour();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingNewCode])

    useEffect(() => {
        const timerLucky = setTimeout(() => {
            setIsOpenCode(false)
        }, 5000)
        return () => clearTimeout(timerLucky)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenCode])

    const insertTour = async () => {
        setIsLoading(true);
        var now = new Date();
        var currentYear = now.getFullYear();
        var currentDate = gs_datetime_yyyyMMddhhmm_(new Date(), "");
        var supplierCode = listOrderTourPayment[0].SupplierCode;
        var ctv = infoCTVCode ? infoCTVCode : "";
        var promotionCode = voucher > 0 ? codeVoucher : "";
        var hotelCode = listOrderTourPayment[0].RoomType;
        var tax = "";
        var totalAMountTax = parseInt(process.env.REACT_APP_TAX);
        var totalAmountOld = totalAll;
        var totalTotalSaleAmount = voucher;
        var totalAmount = total;
        var dateOut = listOrderTourPayment[0].DateIn;
        var dateIn = listOrderTourPayment[0].DateIn;
        var customerTel = infoCus ? infoCus.phone : "";
        var customerEmail = infoCus ? infoCus.email : "";
        var customerName = infoCus ? infoCus.name : "";
        var customerDes = infoCus ? infoCus.request : "";
        var newBookingCode = bookingNewCode;

        //Dịch vụ/ vé mail
        var infoMail = {
            LostCode: "",
            CustomerName: infoCus ? infoCus.name : "",
            CustomerEmail: infoCus ? infoCus.email : "",
            CustomerPhone: infoCus ? infoCus.phone : "",
            TourName: listOrderTourPayment ? listOrderTourPayment[0].HotelName : "",
            DateIn: listOrderTourPayment[0].DateIn,
            DocNo: newBookingCode,
            EmailSupplier: listOrderTourPayment[0].SupplierEmail ? listOrderTourPayment[0].SupplierEmail : "",
            NameSupplier: listOrderTourPayment[0].SupplierName ? listOrderTourPayment[0].SupplierName : "",
            DiscountAmount: voucher,
            QuantityAdult: 0,
            QuantityChild: 0,
            QuantityBaby: 0,
            PriceAdult: 0,
            PriceChild: 0,
            PriceBaby: 0
        };

        listOrderTourPayment.forEach(async (item) => {
            if (item.RoomDescription === "Người lớn") {
                infoMail = { ...infoMail, QuantityAdult: item.Quantity, PriceAdult: item.Price };
            }
            if (item.RoomDescription === "Trẻ em") {
                infoMail = { ...infoMail, QuantityChild: item.Quantity, PriceChild: item.Price };
            }
            if (item.RoomDescription === "Em bé") {
                infoMail = { ...infoMail, QuantityBaby: item.Quantity, PriceBaby: item.Price };
            }
        });

        try {
            const model = {
                StoreName: 'Usp_G30BookingNewUpdateInsert',
                Parametter: '@_key|@_ParentId|@_IsGroup|@_BranchCode|@_FiscalYear|@_Stt|@_DocCode|@_DocDate' +
                    '|@_DocNo|@_Description|@_DescriptionOther|@_CustomerCode|@_CustomerName|@_Email|@_PassPort' +
                    '|@_DateIdPassport|@_Tel|@_Address|@_DateIn|@_DateOut|@_NumberNight|@_CurrencyCode' +
                    '|@_ExchangeRate|@_PartnerCode|@_TotalAmount|@_TotalDeposit|@_TotalSaleAmount' +
                    '|@_TotalAmountOld|@_DocStatus|@_IsActive|@_CreatedBy|@_ModifiedBy' +
                    '|@_TotalAmountTax|@_Tax|@_HotelCode|@_PromotionCode|@_CTV|@_SupplierCode|@_LostTicket',
                Value: 'I|-1|0|A01|' + currentYear + '||BT|' + currentDate + '|' + newBookingCode + '|' + customerDes + '|||' + customerName + '|' +
                    customerEmail + '||' + currentDate + '|' + customerTel + '||' + dateIn + '|' + dateOut + '|0|VND|1||' +
                    totalAmount + '|0|' + totalTotalSaleAmount + '|' + totalAmountOld + '|0|1|||' + totalAMountTax + '|' + tax + '|' +
                    hotelCode + '|' + promotionCode + '|' + ctv + '|' + supplierCode + '|',
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0][0];
            if (data && data.Stt) {
                insertTourDetail(data.Stt);
                if (promotionCode) {
                    changeVoucherCode(promotionCode);
                }
            }
        } catch (error) {
            setOrderConfirm(false);
            console.error("Error fetching data:", error);
        }

        // Xe dịch vụ 
        if (itemCarService() !== null) {
            var infoMailCarService = {
                CustomerName: infoCus ? infoCus.name : "",
                CustomerEmail: infoCus ? infoCus.email : "",
                CustomerPhone: infoCus ? infoCus.phone : "",
                DateIn: listOrderTourPayment[0].DateIn,
                ServiceName: itemCarService().Name,
                Quantity: listOrderTourPayment[0].Quantity,
                Price: itemCarService().Price,
                DocNo: newBookingCode,
                DiscountAmount: voucher,
                CarType: itemCarService().CarType,
                Type: itemCarService().Type,
            };

            try {
                const modelowner = infoMailCarService;

                const queryString = Object.keys(modelowner)
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                    .join("&");

                const response = await fetch(`/HomeControllercs/SendEmailAddBookingnewCardService?${queryString}`);
                await response.json();
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            try {
                const modelowner = infoMailCarService;

                const queryString = Object.keys(modelowner)
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                    .join("&");

                const response = await fetch(`/HomeControllercs/SendEmailAddBookingnewOtherCardService?${queryString}`);
                await response.json();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            if (listOrderTourPayment[0].RoomDescription === "Sản phẩm") {
                infoMail = { ...infoMail, QuantityAdult: listOrderTourPayment[0].Quantity, PriceAdult: listOrderTourPayment[0].Price };
                try {
                    const modelowner = infoMail;

                    const queryString = Object.keys(modelowner)
                        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                        .join("&");

                    const response = await fetch(`/HomeControllercs/SendEmailAddBookingnewSP?${queryString}`);
                    await response.json();
                } catch (error) {
                    console.error('Error fetching data:', error);
                }

                try {
                    const modelowner = infoMail;

                    const queryString = Object.keys(modelowner)
                        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                        .join("&");

                    const response = await fetch(`/HomeControllercs/SendEmailAddBookingnewOtherSP?${queryString}`);
                    await response.json();
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else {
                try {
                    const modelowner = infoMail;

                    const queryString = Object.keys(modelowner)
                        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                        .join("&");

                    const response = await fetch(`/HomeControllercs/SendEmailAddBookingnew?${queryString}`);
                    await response.json();
                } catch (error) {
                    console.error('Error fetching data:', error);
                }

                try {
                    const modelowner = infoMail;

                    const queryString = Object.keys(modelowner)
                        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                        .join("&");

                    const response = await fetch(`/HomeControllercs/SendEmailAddBookingnewOther?${queryString}`);
                    await response.json();
                } catch (error) {
                    console.error('Error fetching data:', error);
                }

                if (listOrderTourPayment[0].SupplierEmail) {
                    try {
                        const modelowner = infoMail;

                        const queryString = Object.keys(modelowner)
                            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                            .join("&");

                        const response = await fetch(`/HomeControllercs/SendEmailAddBookingnewSupllier?${queryString}`);
                        await response.json();
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                }
            }
        }

        if (orderConfirm) {
            setCheckShowPayment(true)
            setOpenPayment(true);
            setIsLoading(false)
        } else {
            setCheckShowPayment(false)
            setOpenPayment(true);
            setIsLoading(false)
        }
    };

    const changeVoucherCode = async (promotionCode) => {
        try {
            const model = {
                StoreName: 'Usp_G20PromotionCodeWHUpdateInsert',
                Parametter: '@_key|@_Code',
                Value: 'U|' + promotionCode,
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            await fetch(`/HomeControllercs/InsertUpdate?${queryString}`);
        } catch (error) {
            setOrderConfirm(false);
            console.error("Error fetching data:", error);
        }

    };

    const insertTourDetail = async (Stt) => {
        var now = new Date();
        var currentYear = now.getFullYear();
        var currentDate = gs_datetime_yyyyMMddhhmm_(new Date(), '');

        listOrderTourPayment.forEach(async (item) => {
            const model = {
                StoreName: 'Usp_G30BookingNewDetailUpdateInsert',
                Parametter: '@_key|@_ParentId|@_IsGroup|@_BranchCode|@_FiscalYear|@_Stt|@_DateIn|@_DateOut|@_NumberNight|@_DocCode|@_DocDate|@_Description|@_ItemCode|@_Quantity|@_Price|@_Amount|@_AmountSale|@_OriginalAmount|@_DocStatus|@_IsActive|@_QuantityAdults|@_QuantityChilder',
                Value: 'I|-1|0|A01|' + currentYear + '|' + Stt + '|' + item.DateIn + '|' + item.DateIn + '|0|BT|' + currentDate + '|' + item.RoomDescription + '|' + item.RoomType + '|' + item.Quantity + '|' + item.Price + '|' + item.Amount + '|' + item.AmountSale + '|' + item.Amount + '|0|1|0|0',
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/InsertUpdate?${queryString}`);
            await response.json();
        });
    };

    const onSubmit = (data) => {
        setInfoCus(data);
        CreatedBookingNewCode();
    };

    const handlePaymentVNPay = async (data) => {
        var now = new Date();
        var currentYear = now.getFullYear();
        var currentDate = gs_datetime_yyyyMMddhhmm_(new Date(), "");
        var supplierCode = listOrderTourPayment[0].SupplierCode;
        var ctv = infoCTVCode ? infoCTVCode : "";
        var promotionCode = voucher > 0 ? codeVoucher : "";
        var hotelCode = listOrderTourPayment[0].RoomType;
        var tax = "";
        var totalAMountTax = parseInt(process.env.REACT_APP_TAX);
        var totalAmountOld = totalAll;
        var totalTotalSaleAmount = voucher;
        var totalAmount = total;
        var dateOut = listOrderTourPayment[0].DateIn;
        var dateIn = listOrderTourPayment[0].DateIn;
        var customerTel = data.phone ? data.phone : "";
        var customerEmail = data.email ? data.email : "";
        var customerName = data.name ? data.name : "";
        var customerDes = data.request ? data.request : "";
        var newBookingCode = await CreatedBookingNewCodePaymentOnline();

        try {
            const model = {
                StoreName: 'Usp_G30BookingNewUpdateInsert',
                Parametter: '@_key|@_ParentId|@_IsGroup|@_BranchCode|@_FiscalYear|@_Stt|@_DocCode|@_DocDate' +
                    '|@_DocNo|@_Description|@_DescriptionOther|@_CustomerCode|@_CustomerName|@_Email|@_PassPort' +
                    '|@_DateIdPassport|@_Tel|@_Address|@_DateIn|@_DateOut|@_NumberNight|@_CurrencyCode' +
                    '|@_ExchangeRate|@_PartnerCode|@_TotalAmount|@_TotalDeposit|@_TotalSaleAmount' +
                    '|@_TotalAmountOld|@_DocStatus|@_IsActive|@_CreatedBy|@_ModifiedBy' +
                    '|@_TotalAmountTax|@_Tax|@_HotelCode|@_PromotionCode|@_CTV|@_SupplierCode|@_LostTicket',
                Value: 'I|-1|0|A01|' + currentYear + '||BT|' + currentDate + '|' + newBookingCode + '|' + customerDes + '|||' + customerName + '|' +
                    customerEmail + '||' + currentDate + '|' + customerTel + '||' + dateIn + '|' + dateOut + '|0|VND|1||' +
                    totalAmount + '|0|' + totalTotalSaleAmount + '|' + totalAmountOld + '|0|1|||' + totalAMountTax + '|' + tax + '|' +
                    hotelCode + '|' + promotionCode + '|' + ctv + '|' + supplierCode + '|',
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0][0];
            if (data && data.Stt) {
                await insertTourDetail(data.Stt);
                if (promotionCode) {
                    await changeVoucherCode(promotionCode);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }

        const PaymentInformationModel = {
            Amount: totalAll ? parseFloat(totalAll) : 0,
            BookingNewCode: await CreatedBookingNewCodePaymentOnline(),
        };
        try {
            const modelowner = PaymentInformationModel;

            const queryString = Object.keys(modelowner)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(modelowner[key])}`)
                .join("&");

            const currentDomain = window.location.origin;

            window.location.href = `${currentDomain}/HomeControllercs/CreatePaymentUrl?${queryString}`;
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }

    const CreatedBookingNewCodePaymentOnline = async () => {
        var newBookingCode = "";
        try {
            const response = await fetch(`/HomeControllercs/ExecuteStoredProc`);
            const dataResponse = await response.json();
            newBookingCode = dataResponse.resultCode;
            return newBookingCode;
        } catch (error) {
            console.error("Error fetching data:", error);
            return newBookingCode;
        }
    };

    const handleClickModalCode = () => {
        setIsOpenCode(false)
    };

    const handleClickPayment = () => {
        setOpenPayment(false);
        setIsLoading(true);
        setIsActiveButtonPayment(false);
        setTimeout(() => {
            setIsLoading(false);
            infoCTVCode ? history.push("/?id=" + infoCTVCode) : history.push("/");
        }, 2000)
    };

    const handleClickRedirectPayment = () => {
        setIsActiveButtonPayment(false);
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            infoCTVCode ? history.push("/?id=" + infoCTVCode) : history.push("/");
        }, 2000)
    };
    return (
        <AnimatedPage>
            <ChangePageTitle pageTitle="Thông tin thanh toán" />
            {isLoading && <Loading />}
            <div className="checkout mt-5 mb-5 mb-lg-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-8 order-last order-lg-first">
                            <div className="frame-form">
                                <h4 className="frame-form__head">THÔNG TIN ĐẶT DỊCH VỤ</h4>
                                <div className="frame-form__foot">
                                    <form className="checkout-form">
                                        <div className="checkout-form-group mt-0">
                                            <input
                                                type="text"
                                                className="checkout-form-group__input"
                                                defaultValue=""
                                                required
                                                autoComplete="off"
                                                {...register("name", {
                                                    required: {
                                                        value: true,
                                                        message: "Vui lòng nhập tên",
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: "Tên phải nhiều hơn 3 kí tự",
                                                    },
                                                })}
                                            />
                                            <label className="checkout-form-group__label checkout-form-group__title" required>
                                                Họ và tên
                                            </label>
                                            {!errors?.name?.message || (
                                                <span className="checkout-form-group__error">
                                                    {errors?.name?.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-between flex-column flex-xl-row">
                                            <div className="checkout-form-group group-phone w-100 w-xl-50">
                                                <input
                                                    type="tel"
                                                    className="checkout-form-group__input"
                                                    defaultValue=""
                                                    required
                                                    autoComplete="off"
                                                    {...register("phone", {
                                                        required: {
                                                            value: true,
                                                            message: "Vui lòng nhập số điện thoại",
                                                        },
                                                        pattern: {
                                                            value: regPhone,
                                                            message: "Số điện thoại không hợp lệ",
                                                        },
                                                    })}
                                                />
                                                <label
                                                    className="checkout-form-group__label checkout-form-group__title"
                                                    required
                                                >
                                                    Số điện thoại
                                                </label>
                                                {!errors?.phone?.message || (
                                                    <span className="checkout-form-group__error">
                                                        {errors?.phone?.message}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="checkout-form-group group-email w-100 w-xl-50">
                                                <input
                                                    type="text"
                                                    className="checkout-form-group__input"
                                                    defaultValue=""
                                                    required
                                                    autoComplete="off"
                                                    {...register("email", {
                                                        required: {
                                                            value: false,
                                                            message: "Vui lòng nhập email",
                                                        },
                                                        pattern: {
                                                            value: regEmail,
                                                            message: "Email không hợp lệ",
                                                        },
                                                    })}
                                                />
                                                <label
                                                    className="checkout-form-group__label checkout-form-group__title"
                                                >
                                                    Email
                                                </label>
                                                {!errors?.email?.message || (
                                                    <span className="checkout-form-group__error">
                                                        {errors?.email?.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="checkout-form-group group-request">
                                            <label className="checkout-form-group__title" required>
                                                Yêu cầu đặc biệt ?
                                            </label>
                                            <textarea
                                                className="checkout-form-group__textarea"
                                                defaultValue=""
                                                {...register("request", {})}
                                            />
                                        </div>
                                    </form>
                                    <div className="pay-method pay-block info-customer">
                                        <h3 className="title">Các phương thức thanh toán hỗ trợ</h3>
                                        <div className="item-new" >
                                            <div className="item-b" onClick={handleSubmit(onSubmit)}>
                                                <img src={PaymentIcon} alt="Payment" />
                                                <p>Thanh toán sau</p>
                                            </div>
                                            <div className="item-b" onClick={handleSubmit(handlePaymentVNPay)}>
                                                <img src={VNPay} alt="VNPay" />
                                                <p>Thanh toán ví điện tử VNPay</p>
                                            </div>
                                            <div className="item-b">
                                                <img src={momo} alt="momo" />
                                                <p>Thanh toán ví điện tử Momo</p>
                                            </div>
                                            <div className="item-b">
                                                <img src={Zalo} alt="zalo" />
                                                <p>Thanh toán ví điện tử Zalo Pay</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="checkout-bill">
                                <div className="checkout-bill-head">Thông tin đơn hàng</div>
                                <div className="checkout-bill-info">
                                    <div className="checkout-bill-info__name checkout-bill-info__item">
                                        {listOrderTourPayment && listOrderTourPayment !== null ? listOrderTourPayment[0].HotelName : ""}
                                    </div>
                                    <div className="checkout-bill-info__date checkout-bill-info__item">
                                        <p>{listOrderTourPayment[0].RoomDescription === "Sản phẩm" ? "Ngày đặt" : "Ngày khởi hành"}</p>
                                        <span>{formatDate(listOrderTourPayment[0].DateIn)}</span>
                                    </div>
                                    {
                                        listOrderTourPayment.map((item, index) => (
                                            <div key={index} className="checkout-bill-info__adult checkout-bill-info__item">
                                                <p>{item.RoomDescription}</p>
                                                <span>
                                                    {item.Price > 0 ? item.Quantity + " x " + gs_currencyFormatfixed0(gs_parseFloat(item.Price)) : item.Quantity}
                                                </span>
                                            </div>
                                        ))
                                    }

                                    <div className="checkout-bill-info__voucher checkout-bill-info__item">
                                        <div className="checkout-bill-info__voucher-title"><strong className="text-nowrap">Mã giảm</strong></div>
                                        <div className="d-flex justify-content-end align-items-center">
                                            <input
                                                type="text"
                                                autoComplete="false"
                                                name="addvoucher"
                                                placeholder="Thêm mã"
                                                defaultValue={codeVoucher}
                                                onBlur={e => handleCheckVoucher(e)}
                                            />
                                            {
                                                showGetVoucher &&
                                                <input
                                                    className="checkout-btn-get btn btn-success"
                                                    type="button"
                                                    defaultValue="Lấy mã"
                                                    onClick={getVoucher}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="checkout-bill-foot">
                                    <div className="checkout-bill-info__item">
                                        <p>TỔNG TIỀN</p>
                                        <div className="checkout-bill-foot__price">
                                            {gs_currencyFormatfixed0(gs_parseFloat(total))} VND
                                        </div>
                                    </div>
                                    <div className="checkout-bill-info__item">
                                        <p>THUẾ (10%)</p>
                                        <div className="checkout-bill-foot__price">
                                            {gs_currencyFormatfixed0(gs_parseFloat(total * parseInt(process.env.REACT_APP_TAX) / 100))} VND
                                        </div>
                                    </div>
                                    <div className="checkout-bill-info__item">
                                        <p>GIẢM GIÁ</p>
                                        <div className="checkout-bill-foot__discount">
                                            {gs_currencyFormatfixed0(gs_parseFloat(voucher))} VND
                                        </div>
                                    </div>
                                    <div className="checkout-bill-info__item">
                                        <p>THANH TOÁN</p>
                                        <div className="checkout-bill-foot__total">
                                            {gs_currencyFormatfixed0(gs_parseFloat(totalAll))} VND
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalLucky openModal={isOpenCode}>
                <div className="modal-overlay" onClick={handleClickModalCode}></div>
                <div className={`${'modal-lucky'} modal-lucky__sale`}>
                    <img src={`http://id.booknet.vn/Images/Emp/1.png`} alt={`http://id.booknet.vn/Images/Emp/1.png`} />
                </div>
            </ModalLucky>
            <ModalLucky openModal={openPayment}>
                <div className="modal-overlay" onClick={handleClickPayment}></div>
                <div className={`${'modal-lucky'} modal-lucky__payment`}>
                    <div className={`${"modal-payment"}`}>
                        {checkShowPayment ? <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className={`${"path"} ${openPayment ? "circle" : ""}`} fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline className={`${"path"} ${openPayment ? "check" : ""}`} fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg> : <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path circle" fill="none" stroke="#D06079" strokeWidth="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                            <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                        </svg>}
                        <h4 className="title">{checkShowPayment ? "Thành công" : "Thất bại"}</h4>
                        {checkShowPayment ? <p className="des">Bạn đã đặt thành công chuyến đi.<br />
                            Nhân viên tư vấn sẽ sớm liên lạc lại để xác nhận.<br />
                            Xin cám ơn đã sử dụng dịch vụ tại Booknet.vn!</p> :
                            <p className="des">Hiện tại hệ thống bị quá tải vì lượng truy lớn.<br />
                                Quý khách vui lòng chọn lại dịch vụ lần nữa.<br /> Rất xin lỗi vì sự bất tiện này.</p>}
                        <div className="footer-modal__payment">
                            <Button title="Xác nhận" btnComfirm onClick={handleClickRedirectPayment} />
                        </div>
                    </div>
                </div>
            </ModalLucky>
        </AnimatedPage>
    );
}

export default Payment;
