import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";

// import required css from library
import "react-datepicker/dist/react-datepicker.css";
import StickyBox from "react-sticky-box";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./styles.css";
import noImage from "../../../wwwroot/images/no-img.jpg";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faCertificate, faTag, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import ModalFullScreen from '../../components/ModalFullScreen'
import Button from '../../components/Button'
import AnimatedPage from '../../components/animatedPage'
import { MyContext } from "../../Layout";
import {
    gs_datetime_yyyyMMddhhmmss_,
    gs_datetime_yyyyMMddhhmm_,
    gs_currencyFormatfixed0,
    gs_parseFloat,
    setCookie,
    getCookie
} from "../../../wwwroot/js/GSFramescript";
import Swal from "sweetalert2";
import axios from 'axios';
import { useScrollRef } from "../../components/Hook/ScrollContext";

const idCtv = new URLSearchParams(window.location.search).get("id");
const getInfoCTVCookies = getCookie("CodeCTV") ? getCookie("CodeCTV") : "";

const Home = () => {
    const { search } = useContext(MyContext);
    const history = useHistory();
    const [geoGraphicalLocation, setGeoGraphicalLocation] = useState({});
    const [location, setLocation] = useState([]);
    const [service, setService] = useState([]);
    const [tours, setTours] = useState([]);
    const [toursSave, setToursSave] = useState([]);
    const [checkedItemsLocation, setCheckedItemsLocation] = useState([]);
    const [paramsLocation, setParamsLocation] = useState("");
    const [paramsService, setParamsService] = useState("");
    const [paramsSoft, setParamsSoft] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openService, setOpenService] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const startDateObj = new Date(startDate);
    const nextDay = new Date(startDateObj);
    const dateEnd = nextDay.setDate(startDateObj.getDate() + 1);
    const [endDate, setEndDate] = useState(new Date(dateEnd));
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [locationCode, setLocationCode] = useState("");
    const [checkCurrentLocation, setCheckCurrentLocation] = useState(false);
    const scrollRef = useScrollRef();

    const formatDateTime = (startDate) => {
        // Chuyển đổi thành đối tượng thời gian
        var originalTime = new Date(startDate);

        // Lấy các thành phần thời gian cần thiết
        var year = originalTime.getFullYear();
        var month = ('0' + (originalTime.getMonth() + 1)).slice(-2);
        var day = ('0' + originalTime.getDate()).slice(-2);
        var hours = ('0' + originalTime.getHours()).slice(-2);
        var minutes = ('0' + originalTime.getMinutes()).slice(-2);

        // Định dạng lại thành chuỗi mới
        var formattedTimeString = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
        return formattedTimeString
    }

    useEffect(() => {
        if (window.innerHeight < 768) {
            setOpenFilter(true)
            setOpenService(true)
        } else {
            setOpenFilter(false)
            setOpenService(false)
        }
    }, []);

    useEffect(() => {
        //sessionStorage.clear();
        fetchDataLocation();
        fetchDataService();
        fetchDataTourAll();
        getGeoGraphicalLocation();
        //if (idCtv && idCtv !== "" && idCtv !== getInfoCTVCookies) {
        //    getInfoCTV();
        //};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getGeoGraphicalLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async function (position) {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;

                    const nominatimApiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

                    try {
                        const response = await axios.get(nominatimApiUrl);
                        const data = response.data;
                        const address = data.address;
                        setGeoGraphicalLocation(address);

                    } catch (error) {
                        console.error('Lỗi khi gọi API: ', error);
                    }
                },
                function (error) {
                    console.error('Không thể lấy được vị trí: ', error);
                }
            );
        } else {
            console.error('Trình duyệt của bạn không hỗ trợ định vị địa lý.');
        }
    }

    useEffect(() => {
        if (geoGraphicalLocation && geoGraphicalLocation.city) {
            fetchDataTourAll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoGraphicalLocation])

    const getInfoCTV = async () => {
        try {
            const model = {
                StoreName: "Usp_GetInfoCtv",
                Parametter: "@_EmployeeCode",
                Value: idCtv,
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0][0];
            if (data) {
                setCookie("CodeCTV", data.Code, 365 * 10);
                setCookie("EmailCTV", data.Email, 365 * 10);
                setCookie("QrCTV", data.ImgQr, 365 * 10);
                setCookie("LinkCTV", data.Link, 365 * 10);
                setCookie("NameCTV", data.Name, 365 * 10);
                setCookie("SDTCTV", data.SellPhone, 365 * 10);
                setCookie("LogoCTV", data.Picture, 365 * 10);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleClickBuild = () => {
        if (
            !validateField(name, "name") ||
            !validateField(phone, "phone")
        ) {
            Swal.fire({
                title: "Lập lịch trình thất bại",
                text: `
                    ${!validateField(name, "name") &&
                        !validateField(phone, "phone") &&
                        !validateField(email, "email")
                        ? "Quý khách vui lòng điền đầy đủ thông tin"
                        : `[${!validateField(name, "name") ? "-Tên" : ""}${!validateField(phone, "phone")
                            ? "-Số điện thoại"
                            : ""
                        }${!validateField(email, "email") ? "-Email" : ""
                        } -] không hợp lệ`
                    }
                `,
                icon: "error",
            });
            return;
        }

        var modelBuildTour = {
            //dateIn: startDate.replaceAll("T", " "),
            dateIn: formatDateTime(startDate),
            dateOut: formatDateTime(endDate),
            //dateOut: endDate.replaceAll("T", " "),
            fullName: name.trim(),
            tel: phone.trim(),
            email: email.trim(),
            location: locationCode,
        };
        sessionStorage.setItem("listModelBuildTour", JSON.stringify(modelBuildTour));
        history.push("/build-tour");
    };

    const validateField = (string, type) => {
        const regName = /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\W|_]{3,}/g;
        const regEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        const regPhone = /^(([+]{0,1}\d{2})|\d?)[\s-]?[0-9]{2}[\s-]?[0-9]{3}[\s-]?[0-9]{4}$/gm;
        switch (type) {
            case 'name':
                return regName.test(string) ? true : false;
            case 'phone':
                return regPhone.test(string) ? true : false;
            case 'email':
                return regEmail.test(string) ? true : false;
            default:
                return false;
        }
    }
    const fetchDataLocation = async () => {
        try {
            const model = {
                StoreName: "Usp_GetArea",
                Parametter: "@_BranchCode|@_IsGroup|@_Code",
                Value: "A01||",
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];

            data.length > 0 ? setLocation(data) : setLocation([]);
            data.length > 0 ? setLocationCode(data[0].AreaCode) : setLocation([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchDataService = async () => {
        try {
            const model = {
                StoreName: "Usp_GetTourType",
                Parametter: "@_Action",
                Value: "1",
            };

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];

            data.length > 0 ? setService(data) : setService([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchDataTourAll = async () => {
        var paramslocationResquest = "";
        for (const item of location) {
            if (!checkCurrentLocation && geoGraphicalLocation.city && geoGraphicalLocation.city === item.Name.trim()) {
                paramslocationResquest = item.AreaCode;
                setCheckCurrentLocation(true);
                setCheckedItemsLocation([item.AreaCode])
                break;
            }
            else if (!checkCurrentLocation && geoGraphicalLocation.city) {
                paramslocationResquest = "AR00001";
                setCheckCurrentLocation(true);
                setCheckedItemsLocation(["AR00001"])
                break;
            }
            else if (checkCurrentLocation || Object.keys(geoGraphicalLocation).length === 0) {
                paramslocationResquest = paramsLocation;
                setCheckCurrentLocation(true);
                break;
            }
        }
        var DocDate = gs_datetime_yyyyMMddhhmmss_(new Date(), "");
        try {
            var model = {}
            if (getInfoCTVCookies && getInfoCTVCookies != null) {
                model = {
                    StoreName: "Usp_SeachTour",
                    Parametter: "@_DocDate1|@_BranchCode|@_TourId|@_OrderBy|@_Top|@_AreaCode|@_TourType|@_TourName|@_CTVCode",
                    Value: DocDate + "|A01||" + paramsSoft + "|1000|" + paramslocationResquest + "|" + paramsService + "||" + getInfoCTVCookies
                };
            } else {
                model = {
                    StoreName: "Usp_SeachTour",
                    Parametter: "@_DocDate1|@_BranchCode|@_TourId|@_OrderBy|@_Top|@_AreaCode|@_TourType|@_TourName|@_CTVCode",
                    Value: DocDate + "|A01||" + paramsSoft + "|1000|" + paramslocationResquest + "|" + paramsService + "|||"
                };
            }

            const queryString = Object.keys(model)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(model[key])}`)
                .join("&");

            const response = await fetch(`/HomeControllercs/GetData?${queryString}`);
            const dataResponse = await response.json();
            const data = dataResponse.dataend[0];

            data.length > 0 ? setTours(data) : setTours([]);
            data.length > 0 ? setToursSave(data) : setToursSave([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const convertDaysToText = (selectedDays) => {
        const weekDays = [
            'Chủ Nhật',
            'Thứ 2',
            'Thứ 3',
            'Thứ 4',
            'Thứ 5',
            'Thứ 6',
            'Thứ 7',
        ];

        const daysArray = selectedDays.split(',').map(Number);

        if (
            !daysArray.length ||
            (daysArray.length === 7 &&
                daysArray.every((day) => day >= 1 && day <= 7))
        ) {
            return daysArray.includes(1) ? 'Hàng ngày' : 'Thứ 2 đến Thứ 7 hàng tuần';
        }

        if (!daysArray.includes(1)) {
            daysArray.unshift(1);
        }

        daysArray.sort((a, b) => a - b);

        const selectedDayNames = daysArray.map((day) => weekDays[day - 1]);

        const result = selectedDayNames.join(', ');
        return result + ' hàng tuần';
    }

    const directionTourDetail = (item) => {
        sessionStorage.setItem("tourId", item.Stt);
        sessionStorage.setItem("tourName", item.TourName);
        sessionStorage.setItem("tourType", item.TourType);
        sessionStorage.setItem("areaCode", item.AreaCode);

        if (item.TourType === "Xe dịch vụ") {
            history.push("/dich-vu");
        } else {
            history.push("/tour");
        }
    }

    const selectParamsLocation = (areaCode) => {
        const isChecked = checkedItemsLocation.includes(areaCode);

        const newCheckedItems = isChecked
            ? checkedItemsLocation.filter(item => item !== areaCode)
            : [...checkedItemsLocation, areaCode];

        setCheckedItemsLocation(newCheckedItems);
    };

    const handleClickScrollToDiv = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    useEffect(() => {
        setParamsLocation(checkedItemsLocation.toString());
    }, [checkedItemsLocation]);

    useEffect(() => {
        fetchDataTourAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsLocation]);

    useEffect(() => {
        fetchDataTourAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsService]);

    useEffect(() => {
        fetchDataTourAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsSoft]);

    const changeAllLocation = () => {
        const allChecked = checkedItemsLocation.length === location.length;
        const newCheckedItems = allChecked ? [] : location.map(item => item.AreaCode);
        setCheckedItemsLocation(newCheckedItems);
    };

    useEffect(() => {
        setTours(() => {
            if (getInfoCTVCookies && getInfoCTVCookies != null) {
                let searchData = toursSave.filter(item => item.TourName.toLowerCase().includes(search) || (item.PriceCTV > 0 ? item.PriceCTV.toString().includes(search) : item.Price.toString().includes(search)));
                return search ? searchData : toursSave;
            } else {
                let searchData = toursSave.filter(item => item.TourName.toLowerCase().includes(search) || item.Price.toString().includes(search));
                return search ? searchData : toursSave;
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const handleClickLocation = () => {
        setOpenLocation(!openLocation);
    }
    const handleClickService = () => {
        setOpenService(!openService);
    }
    const handleClickFilter = () => {
        setOpenFilter(!openFilter);
    }
    return (
        <AnimatedPage>
            <div className="home-body">
                <div className="sliders">
                    <div className="container hide-mobile set-col__container">
                        <div className="row">
                            <div className="col-12 set-col__padding">
                                <div className="content-build__form">
                                    <div className="build-form">
                                        <div className="build-heading">
                                            <h4><FontAwesomeIcon icon={faCalendarDays} />&nbsp; Lập lịch trình</h4>
                                        </div>
                                        <div className="build-content">
                                            <div className="panel-build__tour build-toud__col">
                                                <div className="build-item">
                                                    <label>Ngày đến</label>
                                                    <div className="position-relative">
                                                        {/*<input*/}
                                                        {/*    className="form-control date-start form_datetime_yyyy"*/}
                                                        {/*    type="datetime-local"*/}
                                                        {/*    value={startDate}*/}
                                                        {/*    min={startDate}*/}
                                                        {/*    onChange={(e) => setStartDate(e.target.value)}*/}
                                                        {/*    required*/}
                                                        {/*/>*/}
                                                        <DatePicker
                                                            selected={startDate}
                                                            className="form-control date-start"
                                                            onChange={(date) => setStartDate(date)}
                                                            timeInputLabel="Time:"
                                                            dateFormat="MM/dd/yyyy h:mm aa"
                                                            minDate={new Date()}
                                                            showTimeInput
                                                            required
                                                        />
                                                        <FontAwesomeIcon className="build-item__icon" icon={faCalendarDays} size="sm" />
                                                    </div>
                                                </div>
                                                <div className="build-item">
                                                    <label>Ngày đi</label>
                                                    <div className="position-relative">
                                                        {/*<input*/}
                                                        {/*    className="form-control date-end form_datetime_yyyy"*/}
                                                        {/*    type="datetime-local"*/}
                                                        {/*    value={endDate}*/}
                                                        {/*    min={startDate}*/}
                                                        {/*    onChange={(e) => setEndDate(e.target.value)}*/}
                                                        {/*    required*/}
                                                        {/*/>*/}
                                                        <DatePicker
                                                            className="form-control date-end"
                                                            selected={endDate}
                                                            onChange={(date) => setEndDate(date)}
                                                            timeInputLabel="Time:"
                                                            dateFormat="MM/dd/yyyy h:mm aa"
                                                            minDate={dateEnd}
                                                            showTimeInput
                                                            required
                                                        />
                                                        <FontAwesomeIcon className="build-item__icon" icon={faCalendarDays} size="sm" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel-build__tour">
                                                <label>Họ và tên </label>
                                                <div>
                                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control form_datetime_time" id="TxtFullName" required />
                                                </div>
                                            </div>
                                            <div className="panel-build__tour">
                                                <label>Email </label>
                                                <div>
                                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control form_datetime_time" id="TxtEmail" required />
                                                </div>
                                            </div>
                                            <div className="panel-build__tour build-toud__col">
                                                <div className="build-item">
                                                    <label>Số điện thoại </label>
                                                    <div>
                                                        <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control form_datetime_time" id="TxtTel" required />
                                                    </div>
                                                </div>
                                                <div className="build-item">
                                                    <label>Chọn địa điểm</label>
                                                    <select className="form-control" id="timeLineTour" defaultValue={locationCode} onChange={(e) => setLocationCode(e.target.value)}>
                                                        {
                                                            location.map((item) => {
                                                                return (
                                                                    <option key={item.AreaCode} value={item.AreaCode}> {item.Name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="panel-build__tour">
                                                <div className="build-button">
                                                    <Button title="Lập lịch" BTsearchbk onClick={handleClickBuild} className="btn-detail" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container set-col__form container-build__form">
                    <div className="content-build__form">
                        <div className="build-form">
                            <div className="build-heading">
                                <h4><FontAwesomeIcon icon={faCalendarDays} />&nbsp; Lập lịch trình</h4>
                            </div>
                            <div className="build-content">
                                <div className="panel-build__tour build-toud__col">
                                    <div className="build-item">
                                        <label>Ngày đến</label>
                                        <div className="position-relative">
                                            {/*<input*/}
                                            {/*    className="form-control date-start form_datetime_yyyy"*/}
                                            {/*    type="datetime-local"*/}
                                            {/*    value={startDate}*/}
                                            {/*    min={startDate}*/}
                                            {/*    onChange={(e) => setStartDate(e.target.value)}*/}
                                            {/*    required*/}
                                            {/*/>*/}
                                            <DatePicker
                                                selected={startDate}
                                                className="form-control date-start"
                                                onChange={(date) => setStartDate(date)}
                                                timeInputLabel="Time:"
                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                minDate={new Date()}
                                                showTimeInput
                                                required
                                            />
                                            <FontAwesomeIcon className="build-item__icon" icon={faCalendarDays} size="sm" />
                                        </div>
                                    </div>
                                    <div className="build-item">
                                        <label>Ngày đi</label>
                                        <div className="position-relative">
                                            {/*<input*/}
                                            {/*    className="form-control date-end form_datetime_yyyy"*/}
                                            {/*    type="datetime-local"*/}
                                            {/*    value={endDate}*/}
                                            {/*    min={startDate}*/}
                                            {/*    onChange={(e) => setEndDate(e.target.value)}*/}
                                            {/*    required*/}
                                            {/*/>*/}
                                            <DatePicker
                                                className="form-control date-end"
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                timeInputLabel="Time:"
                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                minDate={dateEnd}
                                                showTimeInput
                                                required
                                            />
                                            <FontAwesomeIcon className="build-item__icon" icon={faCalendarDays} size="sm" />
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-build__tour">
                                    <label>Họ và tên </label>
                                    <div>
                                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control form_datetime_time" id="TxtFullName" required />
                                    </div>
                                </div>
                                <div className="panel-build__tour">
                                    <label>Email </label>
                                    <div>
                                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control form_datetime_time" id="TxtEmail" required />
                                    </div>
                                </div>
                                <div className="panel-build__tour build-toud__col">
                                    <div className="build-item">
                                        <label>Số điện thoại </label>
                                        <div>
                                            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control form_datetime_time" id="TxtTel" required />
                                        </div>
                                    </div>
                                    <div className="build-item">
                                        <label>Chọn địa điểm</label>
                                        <select className="form-control" id="timeLineTour" defaultValue={locationCode} onChange={(e) => setLocationCode(e.target.value)}>
                                            {
                                                location.map((item) => {
                                                    return (
                                                        <option key={item.AreaCode} value={item.AreaCode}> {item.Name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="panel-build__tour">
                                    <div className="build-button">
                                        <Button title="Lập lịch" BTsearchbk onClick={handleClickBuild} className="btn-detail" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container set-col__container">
                    <div id="showTourTicket">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 col-xs-12 set-col__padding sidebar-main">
                                <StickyBox offsetTop={70} offsetBottom={-150}>
                                    <div className="filter-header">
                                        <button className="filter-modal" id="modalFilter" onClick={() => setOpenModal(true)} >Bộ lọc</button>
                                    </div>
                                    <div className="filter-content">
                                        <div className="filter-heading">
                                            <span className="filter-title">Bộ lọc</span>
                                        </div>
                                        <div className="filter-inner">
                                            <div className="filter-block list-location" id="list-location">
                                                <div id="filter-content--location">
                                                    <h3 className="filter-sub">Địa điểm<FontAwesomeIcon icon={!openLocation ? faAngleDown : faAngleUp} onClick={handleClickLocation} /></h3>
                                                </div>
                                                {!openLocation && <ul className="fields">
                                                    {
                                                        location.map((item, index) => (
                                                            <li key={index}>
                                                                <input id={"location--" + index} type="checkbox" value={item.AreaCode} checked={checkedItemsLocation.includes(item.AreaCode)} onChange={() => selectParamsLocation(item.AreaCode)} onClick={handleClickScrollToDiv} />
                                                                <label htmlFor={"location--" + index} onClick={handleClickScrollToDiv}>{item.Name}</label>
                                                            </li>
                                                        ))
                                                    }
                                                    <li>
                                                        <input id={"location--all"} type="checkbox" value="" checked={checkedItemsLocation.length === location.length} onChange={() => changeAllLocation()} onClick={handleClickScrollToDiv} />
                                                        <label htmlFor={"location--all"} onClick={handleClickScrollToDiv}>Chọn tất cả</label>
                                                    </li>
                                                </ul>}

                                            </div>
                                            <div className="filter-block list-service" id="list-service">
                                                <h3 className="filter-sub">Dịch vụ<FontAwesomeIcon icon={!openService ? faAngleDown : faAngleUp} onClick={handleClickService} /></h3>
                                                {!openService && <ul className="fields">
                                                    {
                                                        service.map((item, index) => (
                                                            <li key={index}>
                                                                <input id={"service--" + index} type="radio" name="search_service" value={item.TourType} onChange={() => setParamsService(item.TourType)} onClick={handleClickScrollToDiv} />
                                                                <label htmlFor={"service--" + index} onClick={handleClickScrollToDiv}>{item.TourType}</label>
                                                            </li>
                                                        ))
                                                    }
                                                    <li>
                                                        <input id="service--all" type="radio" name="search_service" value="" onChange={() => setParamsService("")} onClick={handleClickScrollToDiv} />
                                                        <label htmlFor="service--all" onClick={handleClickScrollToDiv}>Chọn tất cả</label>
                                                    </li>
                                                </ul>}

                                            </div>
                                            <div className="filter-block list-soft" id="list-soft">
                                                <div id="filter-content--soft">
                                                    <h3 className="filter-sub">Sắp xếp<FontAwesomeIcon icon={!openFilter ? faAngleDown : faAngleUp} onClick={handleClickFilter} /></h3>
                                                    <p id="up-down-soft" className="fa fa-chevron-down collapse-filter"></p>
                                                </div>
                                                {!openFilter && <ul className="fields">
                                                    <li>
                                                        <input type="radio" id="soft1" value="1" name="search_soft" onChange={() => setParamsSoft("1")} onClick={handleClickScrollToDiv} />
                                                        <label htmlFor="soft1" onClick={handleClickScrollToDiv}>Gợi ý</label>
                                                    </li>
                                                    <li >
                                                        <input type="radio" id="soft2" value="2" name="search_soft" onChange={() => setParamsSoft("2")} onClick={handleClickScrollToDiv} />
                                                        <label htmlFor="soft2" onClick={handleClickScrollToDiv}>Giá thấp</label>
                                                    </li>
                                                    <li >
                                                        <input type="radio" id="soft3" value="3" name="search_soft" onChange={() => setParamsSoft("3")} onClick={handleClickScrollToDiv} />
                                                        <label htmlFor="soft3" onClick={handleClickScrollToDiv}>Giá cao</label>
                                                    </li>
                                                </ul>}

                                            </div>
                                        </div>
                                    </div>
                                </StickyBox>
                            </div>

                            <div ref={scrollRef} className="col-lg-9 col-xl-10 col-xs-12 set-col__padding ticket-main" id="list-all-tour">
                                <div className="row set-row__margin">
                                    {tours.map((item, index) => {
                                        if (getInfoCTVCookies && getInfoCTVCookies != null) {
                                            return (
                                                <div key={index} className="col-md-6 col-lg-6 col-xl-4 col-xs-12 col-sm-12 set-col__padding"
                                                    data-aos="fade-up"
                                                    data-aos-easing="linear"
                                                    data-aos-anchor-placement="top-bottom">
                                                    <div className="ticket-content" onClick={() => directionTourDetail(item)}>
                                                        <div className="ticket-inner">
                                                            <div className="ticket-image">
                                                                <LazyLoadImage src={item.ImageLink ? "http://id.booknet.vn/" + item.ImageLink : noImage}
                                                                    llaceholdersrc={item.ImageLink}
                                                                    effect="blur"
                                                                    alt={item.Stt}
                                                                />
                                                            </div>
                                                            <div className="ticket-sub">
                                                                <div className="content-heading">
                                                                    <h2 className="ticket-heading" onClick={() => directionTourDetail(item)}>
                                                                        <p>{item.TourName}</p>
                                                                    </h2>
                                                                    <div className="ticket-footer">
                                                                        <span className="ticket-tag"><FontAwesomeIcon icon={faTag} />&nbsp;{convertDaysToText(item.Calenda)}</span>
                                                                        <span className="ticket-certificate"><FontAwesomeIcon icon={faCertificate} />&nbsp;{item.TourType}</span>
                                                                    </div>
                                                                </div>
                                                                <p className="ticket-des">
                                                                    {item.ShortDescription}
                                                                </p>
                                                            </div>
                                                            <div className="ticket-price">
                                                                <h2 className={item.TourType === "Xe dịch vụ" ? "hide" : "title-price"}>{gs_currencyFormatfixed0(gs_parseFloat(item.PriceCTV > 0 ? item.PriceCTV : item.Price))}VND<span>{item.TourType === "Sản phẩm Du lịch" ? "/sản phẩm" : "/người"}</span></h2>
                                                                <Button onClick={() => directionTourDetail(item)} buttonDetail title="chi tiết" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={index} className="col-md-6 col-lg-6 col-xl-4 col-xs-12 col-sm-12 set-col__padding"
                                                    data-aos="fade-up"
                                                    data-aos-easing="linear"
                                                    data-aos-anchor-placement="top-bottom">
                                                    <div className="ticket-content" onClick={() => directionTourDetail(item)}>
                                                        <div className="ticket-inner">
                                                            <div className="ticket-image">
                                                                <LazyLoadImage src={item.ImageLink ? "http://id.booknet.vn/" + item.ImageLink : noImage}
                                                                    llaceholdersrc={item.ImageLink}
                                                                    effect="blur"
                                                                    alt={item.Stt}
                                                                />
                                                            </div>
                                                            <div className="ticket-sub">
                                                                <div className="content-heading">
                                                                    <h2 className="ticket-heading" onClick={() => directionTourDetail(item)}>
                                                                        <p>{item.TourName}</p>
                                                                    </h2>
                                                                    <div className="ticket-footer">
                                                                        <span className="ticket-tag"><FontAwesomeIcon icon={faTag} />&nbsp;{convertDaysToText(item.Calenda)}</span>
                                                                        <span className="ticket-certificate"><FontAwesomeIcon icon={faCertificate} />&nbsp;{item.TourType}</span>
                                                                    </div>
                                                                </div>
                                                                <p className="ticket-des">
                                                                    {item.ShortDescription}
                                                                </p>
                                                            </div>
                                                            <div className="ticket-price">
                                                                <h2 className={item.TourType === "Xe dịch vụ" ? "hide" : "title-price"}>{gs_currencyFormatfixed0(gs_parseFloat(item.PriceCTV > 0 ? item.PriceCTV : item.Price))}VND<span>{item.TourType === "Sản phẩm Du lịch" ? "/sản phẩm" : "/người"}</span></h2>
                                                                <Button onClick={() => directionTourDetail(item)} buttonDetail title="chi tiết" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {openModal && <ModalFullScreen
                    closeModal={setOpenModal} openModal={openModal}>
                    <div className="filter-heading">
                        <span className="filter-title">Bộ lọc</span>
                    </div>
                    <div className="filter-inner">
                        <div className="filter-block list-location" id="list-location">
                            <div id="filter-content--location">
                                <h3 className="filter-sub">Địa điểm<FontAwesomeIcon icon={!openLocation ? faAngleDown : faAngleUp} onClick={handleClickLocation} /></h3>
                            </div>
                            {!openLocation && <ul className="fields">
                                {
                                    location.map((item, index) => (
                                        <li key={index}>
                                            <input id={"location--" + index} type="checkbox" value={item.AreaCode} checked={checkedItemsLocation.includes(item.AreaCode)} onChange={() => selectParamsLocation(item.AreaCode)} onClick={handleClickScrollToDiv} />
                                            <label htmlFor={"location--" + index} onClick={handleClickScrollToDiv}>{item.Name}</label>
                                        </li>
                                    ))
                                }
                                <li>
                                    <input id={"location--all"} type="checkbox" value="" checked={checkedItemsLocation.length === location.length} onChange={() => changeAllLocation()} onClick={handleClickScrollToDiv} />
                                    <label htmlFor={"location--all"} onClick={handleClickScrollToDiv}>Chọn tất cả</label>
                                </li>
                            </ul>}

                        </div>
                        <div className="filter-block list-service" id="list-service">
                            <h3 className="filter-sub">Dịch vụ<FontAwesomeIcon icon={!openService ? faAngleDown : faAngleUp} onClick={handleClickService} /></h3>
                            {!openService && <ul className="fields">
                                {
                                    service.map((item, index) => (
                                        <li key={index}>
                                            <input id={"service--mobie" + index} type="radio" name="search_service" value={item.TourType} onChange={() => setParamsService(item.TourType)} onClick={handleClickScrollToDiv} />
                                            <label htmlFor={"service--mobie" + index} onClick={handleClickScrollToDiv}>{item.TourType}</label>
                                        </li>
                                    ))
                                }
                                <li>
                                    <input id="service--all--mobie" type="radio" name="search_service" value="" onChange={() => setParamsService("")} onClick={handleClickScrollToDiv} />
                                    <label htmlFor="service--all--mobie" onClick={handleClickScrollToDiv}>Chọn tất cả</label>
                                </li>
                            </ul>}

                        </div>
                        <div className="filter-block list-soft" id="list-soft">
                            <div id="filter-content--soft">
                                <h3 className="filter-sub">Sắp xếp<FontAwesomeIcon icon={!openFilter ? faAngleDown : faAngleUp} onClick={handleClickFilter} /></h3>
                                <p id="up-down-soft" className="fa fa-chevron-down collapse-filter"></p>
                            </div>
                            {!openFilter && <ul className="fields">
                                <li>
                                    <input type="radio" id="soft1-mobie" value="1" name="search_soft" onChange={() => setParamsSoft("1")} onClick={handleClickScrollToDiv} />
                                    <label htmlFor="soft1-mobie" onClick={handleClickScrollToDiv}>Gợi ý</label>
                                </li>
                                <li >
                                    <input type="radio" id="soft2-mobie" value="2" name="search_soft" onChange={() => setParamsSoft("2")} onClick={handleClickScrollToDiv} />
                                    <label htmlFor="soft2-mobie" onClick={handleClickScrollToDiv}>Giá thấp</label>
                                </li>
                                <li >
                                    <input type="radio" id="soft3-mobie" value="3" name="search_soft" onChange={() => setParamsSoft("3")} onClick={handleClickScrollToDiv} />
                                    <label htmlFor="soft3-mobie" onClick={handleClickScrollToDiv}>Giá cao</label>
                                </li>
                            </ul>}

                        </div>
                    </div>
                    <div className="filter-header">
                        <button className="filter-modal" id="modalFilter" onClick={() => setOpenModal(false)} >Lọc</button>
                    </div>
                </ModalFullScreen>}
            </div>
        </AnimatedPage>

    );
}

export default Home;